<script setup>
	import { defineProps, onMounted, onUnmounted } from "vue";
	import {usePreviousScreenStore } from '@/stores';
	const previousScreenStore = usePreviousScreenStore();

	const props = defineProps({
		currentScreen: { type: Object, default: null }
	})

	const toggleValueContent = (event) => {
		const values = document.querySelectorAll('.value')
		values.forEach(value => {
			value.classList.remove('show')
		})
		const value = event.target.closest('.value');
		value.classList.toggle('show')
	}

	onMounted(() => {
		previousScreenStore.setShowBackButton(true)
	})

	onUnmounted(() => {
		previousScreenStore.setShowBackButton(false)
	})

</script>

<template>
	<div class="dupaco-brand-screen">
		<div class="headline" v-html="currentScreen.headline"></div>
		<div class="contain">
			<div class="container">
				<div class="box">
					<div class="box-child first">
						<div class="overline">Our Mission</div>
						<div class="heading" v-html="currentScreen.mission"></div>
					</div>
					<div class="box-child">
						<div class="overline">Our Vision</div>
						<div class="heading" v-html="currentScreen.vision"></div>
					</div>
				</div>
				<div class="box">
					<div>
						<div class="overline">Cooperative Values</div>
						<div class="heading small" v-html="currentScreen.cooperative"></div>
					</div>
					<div class="values">
						<div class="values-contain">
							<div class="value" v-for="(value, index) in currentScreen.values" :key="index" @touchstart="toggleValueContent($event)">
								<div class="title">{{ value.value }} <svg id="Isolation_Mode" data-name="Isolation Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
								<line x1="6.5" y1=".5" x2="6.5" y2="12.5" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
								<line x1=".5" y1="6.5" x2="12.5" y2="6.5" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
								</svg></div>
								<div class="copy">
									<div class="hidden-copy" v-html="value.copy"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="scss">
	.dupaco-brand-screen {
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		.headline {
			font-family: var(--robotoCondensed);
			font-size: var(--3xl);
			font-weight: 300;
			strong {
				font-family: var(--robotoCondensed);
				font-weight: 400;
				color: var(--mango);
			}
		}
		.contain {
			position: relative;
			width: 100vw;

			&:after {
				position: absolute;
				content: "";
				inset: 0;
				background: linear-gradient(0deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.6) 100%);
			}
		}
		.container {
			display: flex;
			width: 85vw;
			margin: 0 auto;
			.box {
				position: relative;
				z-index: 1;
				width: 50%;
				text-align: left;
				padding: 3em; 
				&:first-child {
					border-right: 0.3em solid rgba(#000, 20%);
					.box-child.first {
						border-bottom: 0.3em solid rgba(#000, 20%);
						margin-bottom: 15%;
					}
				}
				.overline {
					font-family: var(--robotoCondensed);
					font-size: var(--lg);
					font-weight: 500;
					margin-bottom: 2.5%;
					color: var(--mango);
					letter-spacing: -0.02em;
				}
				.heading {
					font-family: var(--robotoCondensed);
					font-size: var(--3xl);
					line-height: 1.2em;
					font-weight: 300;
					letter-spacing: -0.02em;
					&.small {
						font-size: var(--2xl);
						p {
							margin-bottom: 10%;
						}
					}
					p {
						margin: 7.5% 0 15% 0;
					}
				}
			}
			.values {
				position: relative;
				.values-contain {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
				}
				.value {
					background: var(--mango);
					color: var(--white);
					padding: 1.5em 2em;
					margin-bottom: 2.5%;
					border-radius: 1em;
					&:last-child {
						margin-bottom: 0;
					}
					&.show {
						svg {
							transform: rotate(225deg);
						}
						.copy {
							height: auto;
							visibility: visible;
							.hidden-copy {
								opacity: 1;
							}
						}
					}
					.title {
						display: flex;
						justify-content: space-between;
						align-items: center;
						gap: 5%;
						font-family: var(--robotoCondensed);
						font-size: var(--md);
						font-weight: 600;
						svg {
							height: 1em;
							width: 1em;
							transition: 0.4s;
						}
					}
					.copy {
						height: 0;
						transition: 0.3s;
						visibility: hidden;
						overflow: hidden;
						.hidden-copy {
							font-family: var(--robotoCondensed);
							opacity: 0;
							transition: 0.3s;
							font-size: var(--sm);
							padding: 0.5em 0 0 0;
							p {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
</style>