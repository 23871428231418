<script setup>
	import { ref, computed, defineProps, defineEmits, onMounted } from "vue";
	import anime from "animejs"
	import QuestionText from "@/components/QuestionText"
	import { useFormatCurrency } from '@/composables/useFormatCurrency'
	import confetti from 'canvas-confetti';

	const { formatCurrency } = useFormatCurrency();

	const emit = defineEmits([	
		'updateAnswerSelected', 
		'updateAnsweredQuestionsCount', 
		'updateCompletedQuestionsCount',
		'updatePiggyBank',
		'updateTotalScore',
		'updateNextScreen'
	])
	
	const props = defineProps({
		count: { type: Number, default: null },
		question: { type: Object, default: null }
	});
	const buttonsDisabled = ref(false)
	const isAnswerSelected = ref(false)

	const updateAnswerSelected = (newValue) => { emit('updateAnswerSelected', newValue); }
	const updateCompletedQuestionsCount = (newValue) => { emit('updateCompletedQuestionsCount', newValue); }
	const updateAnsweredQuestionsCount = (newValue) => { emit('updateAnsweredQuestionsCount', newValue); }
	const updatePiggyBank = () => { emit('updatePiggyBank', true) }
	const updateTotalScore = (newValue) => { emit('updateTotalScore', newValue); };
	const updateNextScreen = () => { emit('updateNextScreen', true) }
	
	function fireConfetti() {
		const count = 100;
		const triangle = confetti.shapeFromPath({path: 'm7.05,10.69C4.09,14.77,1.75,19.1,0,23.58c9.31,2.17,30.12,7.03,30.17,7.04,1.04.24,2.18-.14,2.85-1.06.67-.92.68-2.12.13-3.04-.03-.04-11.08-18.34-16.02-26.52-3.72,3.05-7.12,6.61-10.08,10.69Z'})
		const defaults = {
			origin: { y: 0.5 },
			scalar: 4,
			shapes: [triangle],
			colors:['#ec1c24','#e87502','#fcb813','#fff100','#8cb53f','#00a551','#00adee','#0071bb','#2e3192','#90278e','#eb0088']
		};

		function fire(particleRatio, opts) {
			confetti(Object.assign({}, defaults, opts, {
				particleCount: Math.floor(count * particleRatio)
			}));
		}

		fire(0.25, {
			spread: 26,
			startVelocity: 55,
			scalar: 3
		});
		fire(0.2, {
			spread: 60,
			scalar: 4
		});
		fire(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8
		});
		fire(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2
		});
		fire(0.1, {
			spread: 120,
			startVelocity: 45,
			scalar: 2
		});
	}

	const selectAnswer = (answer, event) => {
		updateAnswerSelected(true)// Set this to true when an answer is selected
		const answerValue = answer.value * answer.multiplier
		isAnswerSelected.value = true
		updateAnsweredQuestionsCount(1) // Increment the count of answered questions

		// Disable further answers
		buttonsDisabled.value = true	

		const originalButton = event.currentTarget;
		const originalButtonStyles = window.getComputedStyle(originalButton)

		// Clone the button
		const clonedContainer = document.querySelector('.button')
		const clonedButton = originalButton.cloneNode(true);
		clonedButton.classList.add('clone')
		const rect = originalButton.getBoundingClientRect();
		document.querySelector('.cloned-answer .button').prepend(clonedButton);

		// Style the clone to overlay it exactly over the original
		clonedContainer.style.position = 'absolute';
		clonedContainer.style.left = `${rect.left}px`;
		clonedContainer.style.top = `${rect.top}px`;
		clonedButton.style.width = `${rect.width}px`;
		clonedContainer.style.height = `${rect.height}px`;
		clonedButton.style.backgroundColor = originalButtonStyles.backgroundColor;
		clonedContainer.style.transition = 'transform 0.3s ease-in-out, top 0.3s ease, left 0.3s ease, opacity .3s ease';
		clonedContainer.style.zIndex = '1000';

		// Animate the clone to the center
		const centerX = window.innerWidth / 2 - rect.left - rect.width / 2;
		const centerY = window.innerHeight / 2 - rect.top - rect.height / 2;
		const answerElement = (event.target.nodeName === 'button') ? event.target : event.target.closest('button')
		answerElement.style.visibility = 'hidden'

		
		requestAnimationFrame(() => {
			clonedButton.classList.add('clonedButtonClass');
			answerElement.style.visibility = 'hidden'
			// clonedContainer.style.transform = `translate3d(${centerX}px, ${centerY}px, 0) scale3d(1.5,1.5,1)`;
			clonedContainer.style.transform = `translate3d(${centerX}px, ${centerY}px, 0) scale3d(1.2,1.2,1)`;
			
			
			clonedContainer.addEventListener('transitionstart', function handleTransitionEnd(event) {
				if (event.propertyName === 'transform') { // Check for the transform transition
					anime({
						targets: '.score-display',
						opacity: [1,0],
						translateX: ['-50%', '-50%'],
						translateY: [0, '-25%'],
						easing: 'easeInQuad',
						duration: 250
					})

					anime({
						targets: '.question-content',
						opacity: [1,0],
						translateY: [0,-25],
						duration: 500,
						easing: 'easeInQuad'
					})

					anime({
						targets: '.progress > div',
						opacity: [0,1],
						translateY: ['-10px', 0],
						duration: 500,
						delay: anime.stagger(50),
						direction: 'reverse',
						easing: 'easeInQuad'
					})
				}
			});

			clonedContainer.addEventListener('transitionend', function handleTransitionEnd(event) {
				if (event.propertyName === 'transform') { // Check for the transform transition
					savings.value = answer.value * answer.multiplier
					fireConfetti()
					SavingsMessageEnter(savings.value, answerValue, event)
				}
			});
		});
	};

	onMounted(() => {
		anime({
			targets: '.piggy-bank',
			opacity: [0,1],
			translateX: ['-50%', '-50%'],
			translateY: ['65%','40%'],
			duration: 500,
			easing: 'easeOutQuad'
		})
		anime({
			targets: '.score-display',
			opacity: [0,1],
			translateX: ['-50%', '-50%'],
			translateY: ['25%', 0],
			easing: 'easeOutQuad',
			duration: 500
		})

		setTimeout(() => {
			anime({
				targets: '.progress > div',
				opacity: [0,1],
				translateY: ['10px', 0],
				easing: 'easeOutQuad',
				delay: anime.stagger(50),
				duration: 500
			})
		}, 750);	
		
	})

	const savings = ref(0)
	const savingsMessage = computed(() =>{
		return `That's a savings of <strong>${formatCurrency(savings.value)}</strong> per year!`
	})

	const SavingsMessageEnter = (answerValue) => {
		const savingsMessageElement = document.querySelector('.savings-message');
		savingsMessageElement.style.opacity = 1
		const tl = anime.timeline({
			complete: function() {
				updateCompletedQuestionsCount()
				updateNextScreen();
			}
		})
		
		tl.add({
			targets: '.cloned-answer',
			opacity: [1,0],
			translateY: [0,-25],
			duration: 500,
			easing: 'easeInQuad',
			delay: 3000,
			complete: () => {
				updatePiggyBank()
			}
		})
		
		.add({
			targets: '.answer-response p',
			opacity: [0,1],
			translateY: [25,0],
			duration: 500,
			easing: 'easeOutQuad',
			begin: () => {
				updateTotalScore(answerValue) // Update the total score
			}
		})
		.add({
			targets: '.score-display',
			opacity: [0,1],
			translateX: ['-50%', '-50%'],
			translateY: ['25%', 0],
			easing: 'easeOutQuad',
			duration: 500
		}, '-=500')
		
		.add({
			targets: '.answer-response p',
			opacity: [1,0],
			translateY: [0,-25],
			duration: 500,
			easing: 'easeInQuad'
		}, '+=3000')
		.add({
			targets: '.piggy-bank',
			opacity: [1,0],
			translateX: ['-50%', '-50%'],
			translateY: ['40%','65%'],
			duration: 500,
			easing: 'easeInQuad'
		}, '-=750')
		.add({
			targets: '.score-display',
			opacity: [1,0],
			translateX: ['-50%', '-50%'],
			translateY: [0,'-25%'],
			duration: 500,
			easing: 'easeInQuad'
		}, '-=500')
		
		
	};

	const showQuestionContent = ref(false)
	onMounted(() => {
		showQuestionContent.value = true
	})
	

</script>

<template>
	<div class="question-container">
		<Transition name="Question">
		<div v-if="showQuestionContent" class="question-content" :class="`qt-${props.question.subtype}`">
			<div class="question-count" :class="{'hide' : isAnswerSelected}">
				<span class="bg"></span>
				<span class="content">Question <span v-text="props.count"></span></span>
			</div>
			<QuestionText :text="props.question.question" />
			<div class="answers" :class="{'vertical' : props.question.display_answers_vertically }">
				<button 
					v-for="(answer, index) in props.question.answers" 
					:key="index" 
					:disabled="buttonsDisabled" 
					@touchstart="selectAnswer(answer, $event)">
						<div class="answer-label">{{ answer.answer }}</div>
						<div class="answer-period">{{ answer.time_period }}</div>
				</button>
			</div>
		</div>
		</Transition>
		<div class="cloned-answer"><div class="button"><div class="savings-message" v-html="savingsMessage"></div></div></div>
		<canvas id="confetti"></canvas>
		<div class="answer-response" v-if="isAnswerSelected" v-html="props.question.answer_response"></div>
		
	</div>
</template>

<style lang="scss">
	.answer-response {
		pointer-events: none;
		transition: .3s ease;
		font-size: var(--5xl);
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-family: var(--FormulaMedium);
		
		p {
			opacity: 0;
		}
	}
</style>

<style lang="scss" scoped>

	.Question-enter-active,
	.Question-leave-active {
		transition: opacity 2s ease;

		.question-count .content {
			transition: .20s ease 1s;
		}
		
		.question-count .bg {
			transition: .25s cubic-bezier(.43,.52,.03,.99) .85s;
		}
		.question-count {
			transition: .25s ease-out .55s;
		} 
		.question {
			transition: .5s ease .5s;
		}
		.answers {
			button { 
				transition: 1s ease;
				&:nth-child(1) { transition-delay: .15s; }
				&:nth-child(2) { transition-delay: .25s; }
				&:nth-child(3) { transition-delay: .40s; } 
				&:nth-child(4) { transition-delay: .60s; } 
			} 
		}
	}

	.Question-enter-from,
	.Question-leave-to {
		.question-count .content {
			transform: translate3d(0,50%,0);
			opacity: 0;
		}
		.question-count .bg {
			width: 4.3rem;
		}
		.question-count {
			opacity: 0;
			transform: translate3d(0,100%,0);
		}
		.question {
			opacity: 0;
			transform: translate3d(0,5%,0);
		}
		.answers {
			button {
				opacity: 0;
				transform: translate3d(0,5%,0);
			}
		}
	}
	.question-container {
		max-width: 90%;
		margin: 0 auto;
	}
	.question {
		font-family: var(--FormulaMedium);
		color: var(--black);
		// max-height: calc(1.4em * 3); /* Three times the line height */
		width: 80%;
		overflow: hidden;
	}

	.question-count {
		position: relative;
		font-family: var(--FormulaExtendedBold);
		font-size: var(--md);
		color: var(--white);
		text-transform: uppercase;
		display: inline-block;
		padding: .6em 1.5em .4em;
		margin-bottom: 2em;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 2em;
		
		&.hide {
			opacity: 0;
		}

		span { display: inline-block; }

		span.bg {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate3d(-50%, -50%, 0);
			height: 3em;
			max-height: 100%;
			width: 100%;
			background-color: var(--black);
			border-radius: 2em;
		}
		span.content {
			position: relative;
		}

		span.count {
			padding-left: .8em;
		}
	}

	.question-content {
		.answers {
			position: relative;
			display: grid;
			place-items: center;
			grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
			
			gap: 20px;
			max-width: 70%;
			margin: 0 auto;

			&.vertical {
				display: flex;
				flex-wrap: wrap;
				button {
					max-width: 100%;
					aspect-ratio: auto;
					border-radius: 16px;
					padding: 5%;
					display: grid;
					grid-template-columns: 60px 1fr;
					// place-items: start center;
					text-align: left;
					

					.answer-label {
						font-size: var(--lg);
					}

					&::before {
						font-size: var(--xl);
					}

					&:nth-child(1)::before {
						content: "A";
					}
					&:nth-child(2)::before {
						content: "B";
					}
					&:nth-child(3)::before {
						content: "C";
					}
					&:nth-child(4)::before {
						content: "D";
					}

				}
			}
		}

		
	} 

	.cloned-answer {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;

		button {
			transition: .5s opacity ease-in-out;

			&::before {
				display: none;
			}
		}

		.savings-message {
			font-family: var(--FormulaMedium);
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 100%);
			font-size: var(--2xl);
			padding-top: 1em;
			line-height: 1.2em;
			// width: 80vw;
			width: 60vw;
			opacity: 0;
			transition: .5s ease;
			p {
				margin: 0;
				font-family: var(--FormulaMedium);

				strong {
					font-family: var(--FormulaExtraBold);
				}
			}
		}
	}

	button {
		position: relative;
		border: none;
		outline: none;
		border-radius: 7.5%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		aspect-ratio: 1/1;
		color: var(--white);
		transition: transform 0.3s ease-in-out, position 0s 0.3s;
		font-family: var(--FormulaExtraBold);
		box-shadow: 0 10px 0  var(--gray200);
		
		&.clone {
			box-shadow: none;
		}

		&:hover {
			transform: scale(.9);
			box-shadow: 0 0 0  var(--gray200);
		}

		&:nth-child(1) { background-color: var(--red); }
		&:nth-child(2) { background-color: var(--skyblue); }
		&:nth-child(3) { background-color: var(--shamrock); }
		&:nth-child(4) { background-color: var(--warmpurple); }

		.answer-label {
			font-size: var(--4xl);
			font-family: var(--FormulaExtraBold);
		}
		.answer-period {
			text-transform: uppercase;
			font-size: var(--lg);
			font-family: var(--FormulaExtraBold);
			line-height: 1;
		}
	}
	
</style>

