// useDataFetcher.js

import { useHtmlDecoder } from "./useHtmlDecoder";

export function useDataFetcher(API_URL = 'https://dupaco.giganticdesign.com/api/') {
    const { decodeHtmlEntities } = useHtmlDecoder();

    // Recursive function to decode string properties in an object
    const decodeObjectProperties = (obj) => {
        for (let key in obj) {
            if (typeof obj[key] === 'string') {
                obj[key] = decodeHtmlEntities(obj[key]);
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                decodeObjectProperties(obj[key]);  // Recurse into objects
            }
        }
    };

    const fetchData = async (endpoint) => {
        // console.log(endpoint)
        try {
            const response = await fetch(`${API_URL}${endpoint}`);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            let textData = await response.text();

            try {
                var data = JSON.parse(textData);
            } catch (innerError) {

                textData = cleanMalformedJson(textData);
                data = JSON.parse(textData);
            }

            // Decode properties of the fetched data object
            decodeObjectProperties(data);

            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const cleanMalformedJson = (jsonStr) => {
        jsonStr = jsonStr.replace(/\s+/g, ' ').trim();
        jsonStr = jsonStr.replace(/,+/g, ',');
        jsonStr = jsonStr.replace(/,\s*([}\]])/g, '$1');
        return jsonStr;
    };

    return {
        fetchData,
        cleanMalformedJson
    };
}
