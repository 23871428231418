// composables/useHtmlDecoder.js

export function useHtmlDecoder() {
    const decodeHtmlEntities = (str) => {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = str;
      return textArea.value;
    };
  
    return {
      decodeHtmlEntities
    };
  }
  