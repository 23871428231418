<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <svg viewBox="0 0 197.68 197.68" class="squircle">
        <path d="M98.84 197.68c43.31 0 67.39 0 83.11-15.73 15.73-15.73 15.73-39.8 15.73-83.11s0-67.39-15.73-83.11C166.23 0 142.15 0 98.84 0S31.45 0 15.73 15.73C0 31.45 0 55.53 0 98.84s0 67.39 15.73 83.11c15.73 15.73 39.8 15.73 83.11 15.73Z" style="fill:#fff;stroke-width:0"/>
    </svg>
</template>

<style>
    
</style>

<script>
</script>