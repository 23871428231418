<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612.59 275.36">
		<g class="shine" :class="`fill-${props.shineColor}`">
			<path d="m441.27,64.86c-8.15-11.22-17.51-21.03-27.76-29.43-13.6,22.51-44.02,72.88-44.1,73.01-1.53,2.53-1.48,5.83.36,8.37,1.85,2.54,4.98,3.6,7.86,2.94.15-.04,57.44-13.41,83.06-19.39-4.82-12.34-11.26-24.27-19.41-35.49Z"/>
			<path d="m362.31,7.49c-13.19-4.29-26.53-6.72-39.75-7.49,2.23,26.21,7.22,84.84,7.23,84.99.25,2.94,2.23,5.59,5.21,6.56,2.99.97,6.14-.02,8.08-2.24.1-.11,38.59-44.61,55.8-64.51-11.15-7.15-23.37-13.02-36.57-17.3Z"/>
			<path d="m264.71,7.49c-13.19,4.29-25.41,10.16-36.57,17.31,17.21,19.89,55.71,64.4,55.8,64.5,1.93,2.24,5.09,3.21,8.07,2.24,2.99-.97,4.96-3.62,5.22-6.56.01-.15,5-58.78,7.23-84.99-13.23.77-26.57,3.21-39.76,7.49Z"/>
			<path d="m185.75,64.86c-8.15,11.23-14.59,23.16-19.41,35.5,25.62,5.98,82.91,19.35,83.06,19.38,2.88.67,6.01-.4,7.85-2.93,1.85-2.54,1.88-5.84.36-8.38-.08-.12-30.5-50.5-44.1-73-10.25,8.4-19.61,18.21-27.76,29.43Z"/>
		</g>
		<g class="text" :class="`fill-${props.textColor}`">
			<path d="m131.96,136.96l-18.93,70.81c-5.3,19.69-20.26,29.72-42.98,29.72H2.84c-1.7,0-2.84-1.14-2.84-2.84v-.95l33.51-124.95c.57-2.08,2.84-3.79,4.92-3.79h67.21c17.79,0,27.64,8.52,27.64,22.53,0,3.03-.38,6.06-1.32,9.47Zm-40.56,1.62h-28.94l-17.54,65.29h27.24c2.46,0,6.44-3.03,7.38-6.82l14.7-55.07c.19-.38.19-.76.19-1.14,0-1.51-.95-2.27-3.03-2.27Z"/>
			<path d="m207.84,233.71c-.57,2.08-2.65,3.79-4.73,3.79h-26.24c-1.7,0-2.84-1.14-2.84-2.84v-.95l3.22-11.93c-6.25,3.79-14.01,8.71-21.02,12.69-3.41,1.89-6.63,3.03-10.22,3.03h-13.14c-6.44,0-10.04-4.17-10.04-9.47,0-1.14,0-2.08.38-3.22l21.02-78.19c.57-2.08,2.65-3.79,4.73-3.79h26.23c1.71,0,2.84,1.14,2.84,2.84v.95l-14.81,55.57c-.19.38-.19.76-.19,1.13,0,.57.19.95.76.95s1.32-.38,2.46-.95l14.8-8.98c3.4-2.08,4.73-3.79,5.68-7.19l10.79-40.53c.57-2.08,2.65-3.79,4.73-3.79h26.23c1.7,0,2.84,1.14,2.84,2.84v.95l-23.48,87.09Z"/>
			<path d="m435.04,146.62l-23.48,87.09c-.57,2.08-2.65,3.79-4.73,3.79h-20.82c-1.7,0-2.84-1.14-2.84-2.84v-.95l2.65-9.85-15.34,9.28c-6.25,3.79-14.58,6.25-22.15,6.25-10.6,0-19.88-4.73-19.88-16.66,0-2.46.57-5.3,1.33-8.33l12.3-46.38c3.79-14.01,18.74-25.18,35.21-25.18h54.91c2.27,0,3.03,1.14,3.03,2.46,0,.38,0,.76-.19,1.33Zm-54.16,24.22c-3.79,0-6.44,2.65-7.19,5.49l-8.11,30.86c0,.38-.19.76-.19.95,0,.95.38,1.33,1.14,1.33.57,0,1.32-.38,2.08-.76l14.84-9c2.84-1.71,4.54-3.79,5.49-7.2l5.75-21.66h-13.8Z"/>
			<path d="m510.3,168.84c-.57,2.08-2.84,3.98-4.92,3.98h-22.23c-3.79,0-7,2.46-7.95,5.87l-6.91,25.81v.76c0,1.14.56,2.27,1.89,2.27h26.02c1.7,0,2.84,1.14,2.84,2.84,0,.38-.19.57-.19.95l-6.02,22.4c-.57,2.08-2.65,3.79-4.73,3.79h-34.08c-15.53,0-22.34-10.79-22.34-20.07,0-1.51.19-3.03.57-4.54l12.12-45.44c3.21-12.12,16.47-24.61,35.02-24.61h34.08c1.7,0,2.84,1.14,2.84,2.84v.95l-6,22.22Z"/>
			<path d="m611.65,168.96l-12.31,46.39c-3.6,13.44-16.85,22.15-32.94,22.15h-30.29c-14.01,0-23.48-7.38-23.48-19.31,0-2.08.38-4.36.95-6.82l12.3-46.38c3.6-13.44,16.85-22.15,32.95-22.15h30.29c14.01,0,23.48,7.38,23.48,19.31,0,2.08-.38,4.36-.94,6.82Zm-35.08,4.72c0-1.7-1.14-2.84-2.84-2.84h-11.33c-2.84,0-4.73,1.89-5.49,4.35l-8.2,30.48c0,.38-.13.57-.13.95,0,1.7,1.13,2.84,2.83,2.84h12.04c2.08,0,4.16-1.71,4.73-3.79l8.39-31.05v-.95Z"/>
			<path d="m327.96,165.93l-12.31,46.39c-4.35,16.66-19.31,25.18-35.21,25.18h-24.89l-9.08,34.08c-.57,2.08-2.84,3.79-4.92,3.79h-26.23c-1.71,0-2.84-1.14-2.84-2.84,0-.38.19-.57.19-.95l33.51-124.95c.57-2.08,2.65-3.79,4.73-3.79h26.23c1.71,0,2.84,1.14,2.84,2.84v.95l-1.55,5.92,8.82-5.35c7.19-4.36,15.34-6.25,21.01-6.25,13.63,0,21.02,6.06,21.02,16.85,0,2.46-.57,5.11-1.33,8.14Zm-36.99,4.95c-.57,0-1.14.38-1.89.76l-14.51,8.57c-3.6,2.27-4.54,3.22-5.3,6.25l-5.78,20.91c0,.19-.19.57-.19.76,0,.94.57,1.32,2.08,1.32h12.85c2.27,0,4.54-1.51,5.3-4.16l8.57-32.14c0-.38.19-.76.19-.95,0-.94-.57-1.32-1.32-1.32Z"/>
		</g>
	</svg>
</template>

<style scoped>
	svg g {	transition: .3s ease-in-out; }
</style>
  
<script setup>
	import { defineProps } from 'vue';
	
	const props = defineProps({
		shineColor: {
			type: String,
			default: 'mango' // Default Shine Color
		},
		textColor: {
			type: String,
			default: 'white' // Default Text Color
		}
	});
	
</script>
  