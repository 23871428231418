<script setup>
import { onMounted, computed, ref } from 'vue';
import { locationStore, useAnimationStore, useHomeStore } from '@/stores';
import DiscoveryHome from "@/views/homescreens/DiscoveryHome";
import StandardQuizHome from "@/views/homescreens/StandardQuizHome";
import SpeedRoundHome from "@/views/homescreens/SpeedRoundHome";
import HighLowHome from "@/views/homescreens/HighLowHome";
import PromoHome from "@/views/homescreens/PromoHome";
import SlideshowHome from "@/views/homescreens/SlideshowHome";
import SpeedRaceRoundHome from '@/views/homescreens/SpeedRaceRoundHome.vue';
import AccredidationHome from './homescreens/AccredidationHome.vue';
import NewEmployees from './homescreens/NewEmployees.vue';

const store = locationStore();
const homeStore = useHomeStore();
const animationStore = useAnimationStore(); // Use the animation store

onMounted(async () => {
	homeStore.setShowIcons(true)
	await store.init();
	store.currentHomeSlide = game.value.slug
});

const activeIndex = computed(() => homeStore.activeIndex)

const game = computed(() => {
	return store.data?.games[activeIndex.value];
});

const games = computed(() => {
	return store.data?.games;
})

const totalGames = computed(() => {
	return store.data?.games.length;
});


const handleNextScreen = () => {
	if (activeIndex.value + 1 < totalGames.value) {
		homeStore.setActiveIndex(activeIndex.value + 1)
		homeStore.setHomeSlug(game.value.slug)
	} else {
		animationStore.triggerAnimation(true); // Trigger the animation
		setTimeout(() => {
			homeStore.setActiveIndex(0);
			animationStore.triggerAnimation(false); // Reset the animation trigger
		}, 1300); // Adjust this delay to match the duration of your Lottie animation
	}
	store.currentHomeSlide = game.value.slug
};
</script>

<template>
	<div class="experiences">
		<Transition name="slide-fade" mode="out-in">
			<div v-if="game?.experience_type === 'discovery_quizzes'">
				<DiscoveryHome :game="game" @updateNextScreen="handleNextScreen"
					:isLast="(activeIndex + 1) === totalGames" :key="`game-${activeIndex}`" />
			</div>
			<div v-else-if="game?.experience_type === 'standard_quizzes'">
				<StandardQuizHome :game="game" @updateNextScreen="handleNextScreen"
					:isLast="(activeIndex + 1) === totalGames" :key="`game-${activeIndex}`" />
			</div>
			<div v-else-if="game?.experience_type === 'speedround'">
				<SpeedRoundHome :game="game" @updateNextScreen="handleNextScreen"
					:isLast="(activeIndex + 1) === totalGames" :key="`game-${activeIndex}`" />
			</div>
			<div v-else-if="game?.experience_type === 'speed_round_race_theme'">
				<SpeedRaceRoundHome :game="game" @updateNextScreen="handleNextScreen"
					:isLast="(activeIndex + 1) === totalGames" :key="`game-${activeIndex}`" />
			</div>
			<div v-else-if="game?.experience_type === 'high_low'">
				<HighLowHome :game="game" @updateNextScreen="handleNextScreen"
					:isLast="(activeIndex + 1) === totalGames" :key="`game-${activeIndex}`" />
			</div>
			<div v-else-if="game?.experience_type === 'slideshow'">
				<SlideshowHome :games="games" :game="game" @updateNextScreen="handleNextScreen"
					:isLast="(activeIndex + 1) === totalGames" :key="`game-${activeIndex}`" />
			</div>
			<div v-else-if="game?.experience_type === 'promos'">
				<PromoHome :game="game" @updateNextScreen="handleNextScreen" :isLast="(activeIndex + 1) === totalGames"
					:key="`promo-${activeIndex}`" />
			</div>
			<div v-else-if="game?.experience_type === 'accredidations'">
				<AccredidationHome :game="game" @updateNextScreen="handleNextScreen" :isLast="(activeIndex + 1) === totalGames"
					:key="`promo-${activeIndex}`" />
			</div>
			<div v-else-if="game?.experience_type === 'new_employees'">
				<NewEmployees :game="game" @updateNextScreen="handleNextScreen" :isLast="(activeIndex + 1) === totalGames"
					:key="`employee-${activeIndex}`" />
			</div>
		</Transition>
	</div>
</template>

<style lang="scss" scoped>
.intro-content {
	font-size: var(--6xl);
}
</style>