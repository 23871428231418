<script setup>
	import { defineProps } from "vue";

	const props = defineProps({
		screen: String,
		questions: Array,
		completed: Number
	});
	
</script>
<template>
	<div class="progress" v-show="props.screen === 'question'">
		<div v-for="(question, index) in props.questions" :key="index" :class="{'complete' : index <= props.completed}"></div>
	</div>
</template>

<style lang="scss" scoped>
.progress {
	position: fixed;
	top: 20%;
	left: 50%;
	transform: translateX(-50%);
	max-width: 90%;
	width: 100%;
	display: grid;
	gap: 8px;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	transition: .25s ease-in-out;
	div {
		height: 5px;
		border-radius: 8px;
		border: 1px solid var(--gray500);
		opacity: 0;
		transform: translateY(-10px);

		&.complete {
			background-color: var(--gray500);
		}
	}
}
</style>