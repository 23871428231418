<script setup>
import StoplightSVG from "@/components/speedracegame/StoplightSVG.vue";
import { onMounted, ref, computed, defineEmits } from "vue";
import anime from "animejs"

const emit = defineEmits(['updateNextScreen'])

// Reactive reference for the message
const message = ref('Engine Start');
const lightIndex = ref(1)
const currentIndex = ref(0);
const messages = ['Racer Ready!', 'Go!'];

const updateNextScreen = () => {
	emit('updateNextScreen', true)
}

onMounted(() => {
	anime({
		targets: '.countdown',
		opacity: [0, 1],
		duration: 1000,
		easing: 'easeOutInQuad',
		delay: 1750,
		complete: () => {
			const lightInterval = setInterval(() => {
				// Increment the index
				lightIndex.value += 1;
				if(lightIndex.value === 3) {
					clearInterval(lightInterval)
				}
			},1850)

			// Interval to update the message
			const intervalId = setInterval(() => {
				// Update the message to the next string in the array
				message.value = messages[currentIndex.value];

				// Increment the index
				currentIndex.value++;

				// If all messages have been displayed, clear the interval
				if (currentIndex.value >= messages.length) {
					clearInterval(intervalId);
					anime({
						targets: '.countdown',
						opacity: [1, 0],
						duration: 1000,
						easing: 'easeInOutQuad',
						delay: 1000,
						complete: () => {
							updateNextScreen()
						}
					})
				}
			}, 1750);
		}
	});
})


const overlineClass = computed(() => {
	return {
		'sm': message.value === 'Engine Start',
		'md': message.value === 'Racer ready!',
		'lg': message.value === 'Go!',
	};
});

</script>

<template>
	<div class="countdown-container">
		<StoplightSVG :changesCount="lightIndex" />
		<transition name="fadex" mode="out-in">
			<div class="overline" :class="overlineClass" :key="message" v-html="message"></div>
		</transition>
	</div>
</template>

<style lang="scss" scoped>
.countdown-container {
	color: var(--white);
	margin-bottom: 30%;

	.overline {
		position: fixed;
		left: 50%;
		translate: -50%;
		font-family: var(--sculpin);
		font-size: var(--lg);
		font-style: italic;
		line-height: 0.9;
		text-transform: uppercase;
		font-size: var(--7xl);

		&.md {
			font-size: var(--7xl);
		}

		&.lg {
			font-size: var(--10xl);
		}
	}
}
</style>