// composables/useFormatCurrency.js
export function useFormatCurrency() {
	
	function formatCurrency(value) {
		if( value === 0 ) return '$0,000'

		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0,  // No decimal part
			maximumFractionDigits: 0   // No decimal part
		}).format(value);
	}

	return { formatCurrency };
}