<script setup>
import { ref, computed, watch, defineProps, onMounted, defineEmits, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { locationStore } from '@/stores'
import AppIcon from '@/components/speedracegame/AppIcon'
import lottie from 'lottie-web'
import anime from "animejs"
import roadAnimationData from '../../assets/lottie/road.json'
import logoAnimationData from '../../assets/lottie/dupaco-speed.json'
import DupacoLogo from '@/components/DupacoLogo.vue'

const store = locationStore()
const router = useRouter()
const props = defineProps({
	game: { type: Object, default: null },
	isLast: { type: Boolean, default: false }
})
const dupacoTextColor = ref('white')
const dupacoShineColor = ref('mango')
const currentScreenIndex = ref(0)
const currentScreenKey = computed(() => `screen-${currentScreenIndex.value}`);
let lottieRoad = null
const repeat = ref(true)

const currentScreen = computed(() => {
	// Compute the current screen based on the current index
	if (props.game && props.game.home_screen && props.game.home_screen.length > currentScreenIndex.value) {
		return props.game.home_screen[currentScreenIndex.value];
	}
	return null;  // Return null if the index is out of bounds
});

const currentScreenType = computed(() => {
	// Compute a CSS class for the current screen type
	return currentScreen.value ? `screen-${currentScreen.value.type}` : 'default-screen-type';
});


// Watch for changes in the current screen index
watch(currentScreenIndex, () => {

	if (currentScreenIndex.value === 4) {
		setTimeout(() => {
			initLottieLogoCTA()
			anime({
				targets: '#logo .text',
				opacity: [0, 1],
				duration: 500,
				easing: 'easeInOutQuad',
				delay: 1000
			})
		}, 300);
	}
	// Set timer and adjust UI for intro/outro screens
	if (currentScreen.value && currentScreen.value.type !== 'home_cta') {
		setScreenTimer(currentScreen.value.duration);
	} else {
		setScreenTimer(10);
	}
});

// Timer for screen transitions
let screenTimer = null;

// Function to set a timer for screen transitions
const setScreenTimer = (duration) => {
	clearScreenTimer();
	screenTimer = setTimeout(() => {
		goToNextScreen();
	}, duration * 1000); // Convert duration to milliseconds
};

// Function to clear the screen transition timer
const clearScreenTimer = () => {
	if (screenTimer) {
		clearTimeout(screenTimer);
		screenTimer = null;
	}
};

const goToNextScreen = () => {
	if (currentScreenIndex.value < (props.game.home_screen.length - 1)) {
		currentScreenIndex.value++;
	} else {
		clearScreenTimer(); // Clear timer at the end of the screens
		// Animate Sceen Out
		if (!props.isLast) {
			setTimeout(() => {
				const tl = anime.timeline({
					easing: 'easeInQuad',
					complete: () => {
						updateNextScreen();
					}
				})
					.add({
						targets: '.dupaco-logo',
						translateX: ['-50%', '-50%'],
						translateY: [0, '-50%'],
						opacity: [1, 0],
						duration: 500,
					}, 100)
				tl.add({
					targets: '.screen-container',
					duration: 500,
					translateY: [0, '-25%'],
					opacity: [1, 0]
				}, 250)
					.add({
						targets: '.background',
						translateY: [0, 0],
						scale: [1, .9],
						borderRadius: [0, '50px'],
						duration: 500,
						easing: 'easeInOutQuad'
					}, 600)
					.add({
						targets: '.background',
						translateY: [0, '-20%'],
						opacity: [1, 0],
						duration: 500,
					}, 1500)
			}, 1000);
		} else {
			updateNextScreen();
		}
	}
};


const onEnter = () => {
	document.querySelector('.background').style.borderRadius = '50px'
	document.querySelector('.background').style.opacity = '0'
	document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'
	// document.querySelector('.start-content strong').style.opacity = '0'
	const tl = anime.timeline({
		easing: 'easeOutQuad',
		complete: () => {
			setScreenTimer(currentScreen.value.duration)
		}
	})
	tl.add({
		targets: '.background',
		translateY: ['20%', 0],
		opacity: [0, 1],
		duration: 500,
	})
		.add({
			targets: '.background',
			translateY: [0, 0],
			scale: [.9, 1],
			borderRadius: ['50px', 0],
			duration: 500,
			easing: 'easeInOutQuad'
		}, 1000)
		.add({
			targets: '.dupaco-logo',
			translateX: ['-50%', '-50%'],
			translateY: ['100%', 0],
			opacity: [0, 1],
			duration: 500,
		}, 1100)
		.add({
			targets: '.launch-quiz > div',
			duration: 500,
			translateY: ['40px', 0],
			opacity: [0, 1],
			delay: 1000
		}, 1150)
}

const initLottieLogo = () => {
	const lottieLogo = lottie.loadAnimation({
		container: document.getElementById('logo'),
		renderer: 'svg',
		loop: false,
		autoplay: true,
		animationData: logoAnimationData // Use the imported data
	});

	lottieLogo.setSpeed(1.3);
}

const initLottieLogoCTA = () => {
	const lottieLogo = lottie.loadAnimation({
		container: document.querySelector('.logo'),
		renderer: 'svg',
		loop: false,
		autoplay: true,
		animationData: logoAnimationData // Use the imported data
	});

	lottieLogo.setSpeed(1.3);
}

const emit = defineEmits([
	'updateNextScreen'
])

const updateNextScreen = () => { emit('updateNextScreen', true) }

const goToGame = (slug) => {
	const tl = anime.timeline({
		easing: 'easeInQuad',
		complete: () => {
			router.push({ path: `/${store.data.slug}/game/${slug}` })
		}
	})
	tl.add({
		targets: '.launch-quiz > div',
		scale: [1, .75],
		opacity: [1, 0],
		duration: 350
	})
		.add({
			targets: '.dupaco-logo',
			translateX: ['-50%', '-50%'],
			translateY: [0, '-50%'],
			opacity: [1, 0],
			duration: 500,
		}, 100)
		.add({
			targets: '.screen-container',
			duration: 500,
			translateY: [0, '-25%'],
			opacity: [1, 0]
		}, 450)
		.add({
			targets: '#road',
			opacity: [1, 0],
			duration: 500,
			easing: 'easeInQuad'
		})
		.add({
			targets: '.background',
			translateY: [0, 0],
			scale: [1, .9],
			borderRadius: [0, '50px'],
			duration: 500,
			easing: 'easeInOutQuad'
		}, 800)

}

const initLottie = () => {
	lottieRoad = lottie.loadAnimation({
		container: document.getElementById('road'),
		renderer: 'svg',
		loop: false,
		autoplay: false,
		animationData: roadAnimationData // Use the imported data

	});
	lottieRoad.goToAndStop(120, true)
	lottieRoad.setSpeed(4.5)

	lottieRoad.addEventListener('enterFrame', function (e) {
		if (e.currentTime >= 601 && repeat.value == true) {
			lottieRoad.goToAndPlay(380, true)
		}
	});
}

onMounted(() => {
	setTimeout(() => {
		initLottieLogo()
	}, 1000);
	onEnter()
	initLottie()
	setTimeout(() => {
		lottieRoad.goToAndPlay(120, true)
		anime({
			targets: '#logo .text',
			opacity: [0, 1],
			duration: 500,
			easing: 'easeInOutQuad'
		})
	}, 1800);
})

onUnmounted(() => {
	clearScreenTimer();
})

</script>

<template>
	<div class="background">
		<div id="road"></div>
	</div>
	<div class="dupaco-logo" :class="currentScreenType">
		<DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor" />
	</div>
	<div class="screens">
		<Transition name="fadex" mode="out-in">
			<div :key="currentScreenKey">
				<div class="screen-container" v-if="currentScreen?.type === 'intro'">
					<div class="intro-content" v-html="currentScreen?.content"></div>
				</div>
				<div class="screen-container" v-else-if="currentScreen?.type === 'start'">
					<AppIcon :label="props.game?.title" />
				</div>
				<div class="screen-container" v-else>
					<div class="cta-content">
						<div class="box" :class="currentScreen.type">
							<AppIcon :label="props.game?.title" :screen="currentScreenType" />
							<button @touchstart="goToGame(game.slug)" class="launch-quiz">LAUNCH QUIZ ▶</button>
						</div>
						<div v-if="currentScreen?.type === 'home_cta'" class="copy" v-html="currentScreen?.content"></div>
					</div>
				</div>
			</div>
		</Transition>
		<Transition name="fadeup" mode="out-in">
			<div class="launch-quiz always" v-if="currentScreen?.type !== 'home_cta'">
				<div>
					<button @touchstart="goToGame(game.slug)">LAUNCH QUIZ ▶</button>
					<p>Touch to get started</p>
				</div>
			</div>
		</Transition>

	</div>
</template>

<style lang="scss">
#road {
	position: fixed;
	inset: 0;
}
</style>

<style lang="scss" scoped>
.intro-content {
	font-family: var(--sculpin);
	text-transform: uppercase;
	font-style: italic;
	font-size: var(--4xl);
}

.dupaco-logo {
	position: absolute;
	left: 50%;
	top: 5%;
	transform: translateX(-50%);
	z-index: 1;
	width: 25%;
}

.launch-quiz {
	position: relative;
	z-index: 1000;

	button {
		background-color: var(--mango);
		font-size: var(--md);
		color: var(--white);
		font-family: var(--FormulaExtendedBold);
		padding: .75em 2em .5em;
		border: none;
		border-radius: .35em;
		transform-origin: 50% 100%;
		animation: pulse 4s infinite reverse cubic-bezier(.55, 0, .45, 1);

		@keyframes pulse {
			0% {
				transform: scale(1);
			}

			50% {
				transform: scale(1.3);
			}

			100% {
				transform: scale(1);
			}
		}
	}

	p {
		position: absolute;
		bottom: -1em;
		left: 50%;
		transform: translate(-50%, 100%);
		font-family: var(--FormulaExtendedBold);
		font-size: var(--sm);
		color: var(--white);
		text-transform: uppercase;
		margin: 0;
	}

	&.always {
		position: fixed;
		bottom: 33%;
		left: 50%;
		transform: translateX(-50%);
	}
}

button.launch-quiz {
	background-color: var(--mango);
	font-size: var(--md);
	color: var(--white);
	font-family: var(--FormulaExtendedBold);
	padding: .75em 2em .5em;
	border: none;
	border-radius: .35em;
}

.cta-content {
	color: var(--white);
	font-size: var(--2xl);
	line-height: 1.1;

	.box {
		position: relative;
		padding: 12vw 6vw 6vw;
		width: 60%;
		margin: 0 auto;
		border-radius: 20px;

		&.home_cta {
			background-image: linear-gradient(180deg, rgba(255, 255, 255, .05) 0%, rgba(255, 255, 255, .1) 100%);
			backdrop-filter: blur(3px);
			-webkit-backdrop-filter: blur(3px);
		}

		button {
			width: 100%;
			transform-origin: 50% 50%;
			animation: pulse-small 4s infinite reverse cubic-bezier(.55, 0, .45, 1);

			@keyframes pulse-small {
				0% {
					transform: scale(1);
				}

				50% {
					transform: scale(0.8);
				}

				100% {
					transform: scale(1);
				}
			}
		}
	}

	.copy {
		font-family: var(--sculpin);
		text-transform: uppercase;
		font-style: italic;
	}

}

.background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: .5s ease-in-out;
		background-color: var(--asphalt);
	}
}

.screens {
	position: fixed;
	top: 15%;
	bottom: 20%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	>div {
		width: 100%;
	}
}

.screen-container {
	width: 100%;
	max-width: 90%;
	margin: 0 auto;
}
</style>