import { ref } from 'vue'
import { useDataFetcher } from "@/composables/useDataFetcher"
import { defineStore } from 'pinia'

export const locationStore = defineStore('location', () => {
  const API_URL = 'https://dupaco.giganticdesign.com/api/location/'
  const { fetchData } = useDataFetcher(API_URL)
  const data = ref(null)
  const error = ref(null)
  const loading = ref(false)

  const init = async () => {
    loading.value = true
    try {      
      const location_segment = location.pathname.split('/')[1]
      const fetchedData = await fetchData(`${location_segment}`)
      data.value = fetchedData
    } catch (fetchError) {
      error.value = fetchError.message
    } finally {
      loading.value = false
    }
  }
  return {
    API_URL,
    data,
    error,
    loading,
    init
  }
})

export const useAnimationStore = defineStore('animation', {
  state: () => ({
    shouldTriggerAnimation: false,
  }),
  actions: {
    triggerAnimation(value) {
      this.shouldTriggerAnimation = value;
    }
  }
});

export const useHomeStore = defineStore('home', {
  state: () => ({
    activeIndex: 0,
    activeSlug: null,
    showIcons: true
  }),
  actions: {
    setHomeSlug(slug) {
      this.activeSlug = slug
    },
    setActiveIndex(index) {
      this.activeIndex = index;
    },
    setShowIcons(newval) {
      this.showIcons = newval
    }
  }
});

export const usePreviousScreenStore = defineStore('previousScreen', {
  state: () => ({
    // Initially false, we only want to show the back button on the story carousel detail page
    showBackButton: false,
    showPreviousScreen: false,
  }),
  actions: {
    setShowBackButton(newval) {
      this.showBackButton = newval
    },
    setShowPreviousScreen() {
      this.showPreviousScreen = true
    },
    resetShowPreviousScreen() {
      this.showPreviousScreen = false;
    },
  }
});


export const useHomeIconStoreForSlideshowExp = defineStore('slideshowHomeIcon', {
  state: () => ({
    // Initially false, we only want to show the back button on the story carousel detail page
    showHomeIcon: false,
  }),
  actions: {
    setShowHomeIcon(newval) {
      this.showHomeIcon = newval
    },
  }
});


export const useDisableTimeoutStore = defineStore('disableTimeout', {
  state: () => ({
    disableTimeout: false,
  }),
  actions: {
    setDisableTimout(newval) {
      this.disableTimeout = newval
    },
  }
});


