<script setup>
import { ref, computed, watch, onMounted, defineProps, nextTick } from "vue";
import DupacoLogo from "@/components/DupacoLogo";
import ColorStripe from "@/components/ColorStripe";
import AppIcon from '@/components/high-low/AppIcon'
import QuestionComponent from "@/components/high-low/QuestionComponent"
import CountDown from "@/components/CountDown"
import anime from "animejs"
import { useRouter } from 'vue-router'
import lottie from 'lottie-web'
import checkmarkData from '../../assets/lottie/high-low-check-mark.json';
import incorrectIconData from '../../assets/lottie/high-low-incorrect.json';

const route = useRouter()

const props = defineProps({
	game: { type: Object, default: null }
})

const logoState = ref('start'); 
const horizonState = ref('start'); 
const currentScreenIndex = ref(0); 
const dupacoTextColor = ref('white')
const dupacoShineColor = ref('mango')
const correctAnswer = ref(null)
const answeredQuestionsCount = ref(0);
const selectedButton = ref(null); 
const isAnswerSelected = ref(false); 
let lottieCheckmarkIcon = null
let lottieIncorrectIcon = null

const handleAnswerSelected = (newValue) => {
	isAnswerSelected.value = newValue
}

const handleAnsweredQuestionsCount = () => {
	answeredQuestionsCount.value++
}

const handleNextScreen = () => {
	goToNextScreen()
}

const currentScreen = computed(() => {
	// Compute the current screen based on the current index
	if (props.game && props.game.screens && props.game.screens.length > currentScreenIndex.value) {
		return props.game.screens[currentScreenIndex.value];
	}
	return null;  // Return null if the index is out of bounds
});

const handleUpdateTotalScoreAndAnswerMessage = async (answer) => {
	correctAnswer.value = answer.correct
	await nextTick()
	const response = document.querySelector('.high-low-response')
	response.style.opacity = 1;
	correctAnswer.value ? playLottieCheckmarkAnimation() : playLottieIncorrectAnimation();
}

const currentScreenType = computed(() => {
	// Compute a CSS class for the current screen type
	return currentScreen.value ? `screen-${currentScreen.value.type}` : 'default-screen-type';
});
const currentScreenKey = computed(() => `screen-${currentScreenIndex.value}`);

// Timer for screen transitions
let screenTimer = null;

// Function to set a timer for screen transitions
const setScreenTimer = (duration) => {
	clearScreenTimer();
	screenTimer = setTimeout(() => {
		// Adjust logo and go to next screen when timer expires
		if (currentScreen.value && currentScreen.value.type === 'start') {
			adjustLogoForScreen('intro')
		}
		goToNextScreen();
	}, duration * 1000); // Convert duration to milliseconds
};

// Function to clear the screen transition timer
const clearScreenTimer = () => {
	if (screenTimer) {
		clearTimeout(screenTimer);
		screenTimer = null;
	}
};

const GameEnter = () => {
	document.querySelector('.color-stripe').style.opacity = '0'
	document.querySelector('.bg-photo').style.opacity = '0'
	document.querySelector('.background').style.borderRadius = '50px'
	document.querySelector('.background').style.opacity = '1'
	document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'

	setTimeout(() => {
		const tl = anime.timeline({
			easing: 'easeOutQuad'
		})
		tl.add({
			targets: '.app-icon',
			opacity: [0, 1],
			duration: 100
		})
			.add({
				targets: '.app-icon > div',
				scale: [.25, 1],
				delay: anime.stagger(100),
				opacity: [0, 1],
				duration: 500
			})
			.add({
				targets: '.background',
				translateY: [0, 0],
				scale: [.9, 1],
				borderRadius: ['50px', 0],
				duration: 200,
				easing: 'easeInOutQuad'
			}, 750)
			.add({
				targets: '.bg-photo',
				opacity: [0, 0.5],
				duration: 250,
				easing: 'easeOutQuad',
			})
			.add({
				targets: '.color-stripe',
				duration: 250,
				translateX: ['-100%', 0],
				opacity: [0, 1]
			}, 1300)
			.add({
				targets: '.app-icon',
				translateX: ['-50%', '-50%'],
				translateY: ['-50%', '0'],
				top: ['50%', '2%'],
				scale: [1, .45]
			}, '+=2500')
			.add({
				targets: '.app-icon .app-label',
				translateY: ['0', '15%'],
				scale: [1, 1.3]
			}, '-=200')
			.add({
				targets: '.horizon-bar',
				translateY: ['-100%', '0'],
				complete: () => {
					goToNextScreen()
				}
			}, '-=1500')
	}, 500);

}

onMounted(() => {
	GameEnter()
})

watch(currentScreenType, () => {
	if(currentScreenType.value === 'screen-cta') {
		const tl = anime.timeline({
			easing: 'easeInQuad'
		})
		tl.add({
			targets: '.app-icon',
			opacity: 0,
			duration: 200
		}).add({
			targets: '.horizon-bar ',
			opacity: 0,
			duration: 200
		})
	}
})

// Watch for changes in the current screen index
watch(currentScreenIndex, () => {
	if (currentScreen.value && (currentScreen.value.type === 'intro' || currentScreen.value.type === 'outro')) {
		setScreenTimer(currentScreen.value.duration);
	}
	if (currentScreen.value.type === 'question') {
		const tl = anime.timeline({
			easing: 'easeInQuad'
		})
		document.querySelector('.question-bg-photo').style.display = 'block'
		tl.add({
			targets: '.question-bg-photo',
			opacity: [0, 1],
			duration: 1000,
		}).add({
			targets: '.question-bg-photo',
			opacity: [1, 0.3],
			filter: ['blur(0px)', 'blur(10px)'],
			duration: 750,
			delay: 250
		}, 1250)
	}
	if (currentScreen.value.type === 'countdown' || currentScreen.value.type === 'outro') {
		document.querySelector('.question-bg-photo').style.display = 'none'
	}
	if (currentScreen.value.type === 'cta') {
		document.querySelector('.question-bg-photo').style.display = 'none'
		dupacoShineColor.value = 'white'
		dupacoTextColor.value = 'white'

		setTimeout(() => {
			route.push({ name: 'home' })
		}, 30000);
	}
});

const goToNextScreen = () => {
	isAnswerSelected.value = false; // Reset for the next question
	// Reset the selected button
	if (selectedButton.value) {
		selectedButton.value.style.transform = '';
		selectedButton.value.style.position = '';
		selectedButton.value.style.zIndex = '';
		selectedButton.value = null;
	}
	if (currentScreenIndex.value < props.game.screens.length - 1) {
		currentScreenIndex.value++;
	} else {
		clearScreenTimer(); // Clear timer at the end of the screens
	}
};

function adjustLogoForScreen(screen) {
	if (screen === 'start') {
		logoState.value = 'start';
	} else {
		horizonState.value = 'show';
		logoState.value = 'top';
	}
}


const playLottieCheckmarkAnimation = () => {
	lottieCheckmarkIcon = lottie.loadAnimation({
		container: document.getElementById('correct-icon'),
		renderer: 'svg',
		loop: false,
		autoplay: true,
		animationData: checkmarkData
	});

	lottieCheckmarkIcon.play()
}

const playLottieIncorrectAnimation = () => {
	lottieIncorrectIcon = lottie.loadAnimation({
		container: document.getElementById('incorrect-icon'),
		renderer: 'svg',
		loop: false,
		autoplay: true,
		animationData: incorrectIconData
	});

	lottieIncorrectIcon.play()
}

</script>

<template>
	<div class="background" :class="currentScreenType">
		<img :src="currentScreen.background_image" class="question-bg-photo">
		<img src="@/assets/img/high-low-bg-texture.png" class="bg-photo">
		<img src="@/assets/img/horizon-bar.svg" class="horizon-bar" :class="horizonState">
	</div>

	<AppIcon :icon="props.game?.icon" :label="props.game?.title" :screen="currentScreenType" game="high-low" />

	<div class="screens">
		<Transition name="Question">
			<div :key="currentScreenKey" :class="currentScreenType" class="screen-container"
				v-if="currentScreen?.type === 'question'">
				<QuestionComponent :count="answeredQuestionsCount + 1" :question="currentScreen"
					:correctAnswer="correctAnswer"
					@updateAnswerSelected="handleAnswerSelected"
					@updateAnsweredQuestionsCount="handleAnsweredQuestionsCount"
					@updateTotalScoreAndAnswerMessage="handleUpdateTotalScoreAndAnswerMessage"
					@updateNextScreen="handleNextScreen"
					/>
			</div>
		</Transition>

		<Transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'intro'">
				<div class="intro-content" v-html="currentScreen?.content"></div>
			</div>
		</Transition>

		<Transition name="countdown" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'countdown'">
				<CountDown class="overline text-large" initialText="Let’s get<br/> started!"
					text="Next question coming up in.." @updateNextScreen="handleNextScreen"
					:answeredQuestionsCount="answeredQuestionsCount" />
			</div>
		</Transition>

		<Transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'outro'">
				<div class="outro-content" v-html="currentScreen?.content"></div>
			</div>
		</Transition>

		<Transition name="cta" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'cta'">
				<div class="cta-content">
					<div class="content" :class="game.experience_type" v-html="currentScreen?.content"></div>
					<div class="qrcode">
						<img :src="currentScreen?.qrcode" />
					</div>
				</div>
			</div>
		</Transition>
	</div>

	<div class="dupaco-logo" :class="currentScreenType">
		<DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor" />
	</div>

	<ColorStripe />
</template>

<style lang="scss">
.app-icon strong {
	font-family: var(--FormulaExtendedBold);
}
.cta-content {
	.content {
		&.high_low {
			p {
				font-size: var(--lg);
			}	
		}
	}
}
</style>

<style lang="scss" scoped>
.Question-enter-active,
.Question-leave-active {
	transition: opacity 1.5s ease 1.5s;
}

.Question-enter-from,
.Question-leave-to {
	opacity: 0;
	transform: translate3d(0, 10%, 0);
}

.screens {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}

.background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: .5s ease-in-out;
		background: linear-gradient(134deg, #00adee, #eb0088);
	}

	&.screen-question {
		&::before {
			background: rgba(black, 1);
		}
		.question-bg-photo {
			opacity: .5;
		}
	}
	&.screen-cta {
		&::before {
			background: var(--mango);
		}
	}

	.bg-photo {
		opacity: .5;
		object-fit: cover;
		height: 100%;
		width: 100%;
	}

	.question-bg-photo {
		opacity: 0;
		object-fit: cover;
		height: 100%;
		width: 100%;
		display: none;
	}

	.horizon-bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		opacity: .25;
		transform: translate3d(0, -100%, 0);
		&.show {
			transform: translate3d(0, -10%, 0)
		}
	}

	&.screen-question .horizon-bar {
		opacity: .05;
	}

	&.screen-cta .horizon-bar {
		opacity: .10;
	}
}

.app-icon {
	z-index: 20;
	position: fixed;
	top: 50%;
	left: 50%;
	transform-origin: center top;
	transform: translate3d(-50%, -50%, 0);
	opacity: 0;

	&.screen-start {
		transform: translate3d(-50%, -50%, 0);
	}

	&:not(.screen-start) {
		top: 2%;
		transform: translate3d(-50%, 0%, 0) scale3d(.52, .52, 1);

		.app-label {
			transform: translate3d(0, 50%, 0) scale3d(1.5, 1.5, 1);
		}
	}

	>div {
		opacity: 0;
	}

}

.dupaco-logo {
	position: fixed;
	bottom: 5%;
	left: 50%;
	transform: translateX(-50%);
	width: 50%;
	transition: .5s ease-in-out;

	svg {
		opacity: 1;
	}

	&.screen-intro {
		opacity: 0;
	}

	&.screen-start {
		opacity: 0;
	}

	&.screen-countdown {
		opacity: 0;
	}

	&.screen-question {
		opacity: 0;
	}

}

.screen-container {
	position: fixed;
	width: 100%;
	left: 0;
	top: 25%;
	bottom: 25%;
	display: flex;
	align-items: center;
	justify-content: center;

	&.screen-question {
		top: 18%;
	}
}

.question-content {
	max-width: 90%;
	margin: 0 auto;
}

.intro-content,
.outro-content,
.cta-content {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translate(-50%);
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 80%;
	margin: 0 auto;

	font-family: var(--FormulaMedium);
	color: var(--gray100);
	font-size: var(--3xl);
	line-height: 1.5em;
}

.cta-content {
	flex-direction: column;

	.content {
		margin-bottom: 1em;
	}

	.qrcode {
		width: 14.2rem;
		height: 14.2rem;
		aspect-ratio: 1/1;
		border-radius: 16%;
		background-color: var(--white);
		display: grid;
		place-items: center;

		img {
			width: 11rem;
		}
	}
}

</style>
