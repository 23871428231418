<script setup>
import { ref, computed, watch, onMounted, defineProps } from "vue";
import QuizProgress from '@/components/QuizProgress';
import DupacoLogo from "@/components/DupacoLogo";
import ColorStripe from "@/components/ColorStripe";
import AppIcon from '@/components/AppIcon'
import QuestionComponent from "@/components/QuestionComponent"
import CountDown from "@/components/CountDown"
import ScoreDisplay from "@/components/ScoreDisplay"
import anime from "animejs"
import { useRouter } from 'vue-router'

const route = useRouter()

// States for UI elements
const logoState = ref('start'); // State for controlling logo appearance
const horizonState = ref('start'); // State for controlling horizon bar appearance
const currentScreenIndex = ref(0); // Index for the current screen in the game
const dupacoTextColor = ref('white')
const dupacoShineColor = ref('mango')

// More reactive states and methods for game logic
const answeredQuestionsCount = ref(0);
const completedQuestionsCount = ref(0);
const totalScore = ref(0); // Total score in the game
const selectedButton = ref(null); // Currently selected answer button
const isAnswerSelected = ref(false); // Flag to indicate if an answer has been selected
// const countdown = ref(3);
const displayScore = ref(0);
const showScore = ref(false)

const handleAnswerSelected = (newValue) => {
	isAnswerSelected.value = newValue
}

const handleAnsweredQuestionsCount = () => {
	answeredQuestionsCount.value++
}

const handleCompletedQuestionsCount = () => {
	completedQuestionsCount.value++
}

const handleUpdatePiggyBank = () => {
	const newPiggyScale = calculatePiggyScale();
	const piggyBankElement = document.querySelector('.piggy-bank img'); // Adjust selector if necessary
	piggyBankElement.style.transform = `scale(${newPiggyScale})`;
}

const handleUpdateTotalScore = (scoreValue) => {
	totalScore.value += scoreValue;
	animateScore(totalScore.value)
}

const handleNextScreen = () => {
	goToNextScreen()
}

const props = defineProps({
	game: { type: Object, default: null }
})

const questions = computed(() => {
	return props.game?.screens.filter(question => { return question.type === 'question' })
})

const currentScreen = computed(() => {
	// Compute the current screen based on the current index
	if (props.game && props.game.screens && props.game.screens.length > currentScreenIndex.value) {
		return props.game.screens[currentScreenIndex.value];
	}
	return null;  // Return null if the index is out of bounds
});

const currentScreenType = computed(() => {
	// Compute a CSS class for the current screen type
	return currentScreen.value ? `screen-${currentScreen.value.type}` : 'default-screen-type';
});
const currentScreenKey = computed(() => `screen-${currentScreenIndex.value}`);

// Timer for screen transitions
let screenTimer = null;

// Function to set a timer for screen transitions
const setScreenTimer = (duration) => {
	clearScreenTimer();
	screenTimer = setTimeout(() => {
		// Adjust logo and go to next screen when timer expires
		if (currentScreen.value && currentScreen.value.type === 'start') {
			adjustLogoForScreen('intro')
		}
		goToNextScreen();
	}, duration * 1000); // Convert duration to milliseconds
};

// Function to clear the screen transition timer
const clearScreenTimer = () => {
	if (screenTimer) {
		clearTimeout(screenTimer);
		screenTimer = null;
	}
};

const GameEnter = () => {
	document.querySelector('.background video').style.opacity = '0'
	document.querySelector('.color-stripe').style.opacity = '0'
	document.querySelector('.background').style.borderRadius = '50px'
	document.querySelector('.background').style.opacity = '1'
	document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'

	setTimeout(() => {
		const tl = anime.timeline({
			easing: 'easeOutQuad'
		})
		tl.add({
			targets: '.app-icon',
			opacity: [0, 1],
			duration: 100
		})
			.add({
				targets: '.app-icon > div',
				scale: [.25, 1],
				delay: anime.stagger(100),
				opacity: [0, 1],
				duration: 500
			})
			.add({
				targets: '.background',
				translateY: [0, 0],
				scale: [.9, 1],
				borderRadius: ['50px', 0],
				duration: 200,
				easing: 'easeInOutQuad'
			}, 750)
			.add({
				targets: 'video',
				opacity: [0, .5],
				duration: 250,
				easing: 'easeOutQuad',
				complete: () => {
					document.querySelector('video').removeAttribute('style')
				}
			})
			// .add({
			// 	targets: '.dupaco-logo svg',
			// 	translateY: ['50px', 0],
			// 	opacity: [0, 1]
			// }, 1200)
			.add({
				targets: '.color-stripe',
				duration: 250,
				translateX: ['-100%', 0],
				opacity: [0, 1]
			}, 1300)
			.add({
				targets: '.app-icon',
				translateX: ['-50%', '-50%'],
				translateY: ['-50%', '0'],
				top: ['50%', '2%'],
				scale: [1, .5]
			}, '+=2500')
			.add({
				targets: '.app-icon .app-label',
				translateY: ['0', '15%'],
				scale: [1, 1.3]
			}, '-=200')
			.add({
				targets: '.horizon-bar',
				translateY: ['-100%', '0'],
				complete: () => {
					goToNextScreen()
				}
			}, '-=1500')
	}, 500);

}

onMounted(() => {
	GameEnter()
})

// Watch for changes in the current screen index
watch(currentScreenIndex, () => {
	// Set timer and adjust UI for intro/outro screens
	if (currentScreen.value && (currentScreen.value.type === 'intro' || currentScreen.value.type === 'outro')) {
		setScreenTimer(currentScreen.value.duration);
	}
	if (currentScreen.value.type == 'countdown') {
		// showCountdown()
	}
	if (currentScreen.value.type === 'question') {
		showScore.value = true
	}
	if (currentScreen.value.type === 'outro' || currentScreen.value.type === 'countdown') {
		showScore.value = false
	}
	if (currentScreen.value.type === 'cta') {
		dupacoShineColor.value = 'white'
		dupacoTextColor.value = 'white'

		setTimeout(() => {
			route.push({ name: 'home' })
		}, 30000);
	}
});

// const showCountdown = () => {
// 	countdown.value = 3; // Reset to 3 seconds for each question
// 	const countdownInterval = setInterval(() => {
// 		countdown.value -= 1;
// 		if (countdown.value === 0) {
// 			clearInterval(countdownInterval);
// 			goToNextScreen();
// 		}
// 	}, 1000); // Decrement every second
// }


const goToNextScreen = () => {
	isAnswerSelected.value = false; // Reset for the next question
	// Reset the selected button
	if (selectedButton.value) {
		selectedButton.value.style.transform = '';
		selectedButton.value.style.position = '';
		selectedButton.value.style.zIndex = '';
		selectedButton.value = null;
	}
	if (currentScreenIndex.value < props.game.screens.length - 1) {
		currentScreenIndex.value++;
	} else {
		clearScreenTimer(); // Clear timer at the end of the screens
	}
};

const animateScore = (newScore) => {
	let currentScore = displayScore.value;
	const increment = Math.ceil((newScore - currentScore) / 50); // Adjust the divisor for speed

	const interval = setInterval(() => {
		currentScore += increment;
		displayScore.value = currentScore;

		if (currentScore >= newScore) {
			displayScore.value = newScore; // Ensure it ends at the correct score
			clearInterval(interval);
		}
	}, 20); // Adjust the interval for speed
};

const totalQuestions = computed(() => {
	return props.game ? props.game.screens.filter(screen => screen.type === 'question').length : 0;
});

const calculatePiggyScale = () => {
	const maxScale = 2; // Maximum scale
	const scaleFactor = 1 + (maxScale - 1) * (answeredQuestionsCount.value / totalQuestions.value);
	return Math.min(scaleFactor, maxScale); // Ensure it does not exceed the maximum scale
};

function adjustLogoForScreen(screen) {
	if (screen === 'start') {
		logoState.value = 'start';
	} else {
		horizonState.value = 'show';
		logoState.value = 'top';
	}
}

function numberRangeArray(max) {
	return Array.from({ length: max }, (v, k) => k + 1);
}

</script>

<template>
	<div class="background" :class="currentScreenType">
		<video autoplay muted loop playsinline>
			<source src="../../assets/videos/finance-pattern.mp4" type="video/mp4">
		</video>
		<img src="@/assets/img/horizon-bar.svg" class="horizon-bar" :class="horizonState">
	</div>

	<AppIcon :icon="props.game?.icon" :label="props.game?.title" :screen="currentScreenType" />

	<Transition name="Progress">
		<QuizProgress :screen="currentScreen?.type" :questions="numberRangeArray(questions?.length)"
			:completed="completedQuestionsCount" />
	</Transition>

	<div class="screens">
		<Transition name="Question">
			<div :key="currentScreenKey" :class="currentScreenType" class="screen-container"
				v-if="currentScreen?.type === 'question'">
				<QuestionComponent :count="completedQuestionsCount + 1" :question="currentScreen"
					@updateAnswerSelected="handleAnswerSelected"
					@updateAnsweredQuestionsCount="handleAnsweredQuestionsCount"
					@updateCompletedQuestionsCount="handleCompletedQuestionsCount" @updateNextScreen="handleNextScreen"
					@updatePiggyBank="handleUpdatePiggyBank" @updateTotalScore="handleUpdateTotalScore" />
			</div>
		</Transition>

		<Transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'intro'">
				<div class="intro-content" v-html="currentScreen?.content"></div>
			</div>
		</Transition>

		<Transition name="countdown" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'countdown'">
				<CountDown class="overline text-large" initialText="Let’s take<br/> the quiz!"
					text="Next Question Coming Up!" @updateNextScreen="handleNextScreen"
					:answeredQuestionsCount="answeredQuestionsCount" />
			</div>
		</Transition>

		<Transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'outro'">
				<div class="outro-content" v-html="currentScreen?.content"></div>
			</div>
		</Transition>

		<Transition name="cta" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'cta'">
				<div class="cta-content">
					<div class="content" v-html="currentScreen?.content"></div>
					<div class="qrcode">
						<img :src="currentScreen?.qrcode" />
					</div>
				</div>

			</div>
		</Transition>

		<ScoreDisplay :score="displayScore" v-show="showScore" />

	</div>

	<div class="piggy-bank" :class="currentScreenType">
		<img src="@/assets/img/piggy-bank.svg" />
	</div>

	<div class="dupaco-logo" :class="currentScreenType">
		<DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor" />
	</div>

	<ColorStripe />
</template>

<style lang="scss">
.app-icon strong {
	font-family: var(--FormulaExtendedBold);
}
</style>

<style lang="scss" scoped>
.Question-enter-active,
.Question-leave-active {
	transition: opacity 1.5s ease;
}

.Question-enter-from,
.Question-leave-to {
	opacity: 0;
	transform: translate3d(0, 10%, 0);
}

.screens {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}

.background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: .5s ease-in-out;
		background-color: var(--sapphire);
	}

	&.screen-question {
		video {
			opacity: .2;
		}

		&::before {
			background-color: var(--white);
		}
	}

	&.screen-cta {
		&::before {
			background-color: var(--mango);
		}
	}

	.horizon-bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		opacity: .25;
		transform: translate3d(0, -100%, 0);

		// transition: .5s;
		&.show {
			transform: translate3d(0, -10%, 0)
		}
	}

	&.screen-question .horizon-bar {
		opacity: .05;
	}

	&.screen-cta .horizon-bar {
		opacity: .10;
	}
}

.app-icon {
	z-index: 20;
	position: fixed;
	top: 50%;
	left: 50%;
	transform-origin: center top;
	transform: translate3d(-50%, -50%, 0);
	width: 37%;
	opacity: 0;

	&.screen-start {
		transform: translate3d(-50%, -50%, 0);
	}

	&:not(.screen-start) {
		top: 2%;
		transform: translate3d(-50%, 0%, 0) scale3d(.52, .52, 1);

		.app-label {
			transform: translate3d(0, 50%, 0) scale3d(1.5, 1.5, 1);
		}
	}

	>div {
		opacity: 0;
	}

}

.dupaco-logo {
	position: fixed;
	bottom: 5%;
	left: 50%;
	transform: translateX(-50%);
	width: 50%;
	transition: .5s ease-in-out;

	svg {
		opacity: 0;
	}

	&.screen-question {
		opacity: 0;
	}

	&.screen-outro {
		opacity: 0;
	}

	&.screen-cta {
		opacity: 0;
	}
}

.screen-container {
	position: fixed;
	width: 100%;
	left: 0;
	top: 25%;
	bottom: 25%;
	display: flex;
	align-items: center;
	justify-content: center;

	&.screen-question {
		top: 18%;
	}
}

.question-content {
	max-width: 90%;
	margin: 0 auto;
}

.intro-content,
.outro-content,
.cta-content {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translate(-50%);
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 80%;
	margin: 0 auto;

	font-family: var(--FormulaMedium);
	color: var(--gray100);
	font-size: var(--3xl);
	line-height: 1.5em;
}

.cta-content {
	flex-direction: column;

	.content {
		margin-bottom: 1em;
	}

	.qrcode {
		width: 14.2rem;
		height: 14.2rem;
		aspect-ratio: 1/1;
		border-radius: 16%;
		background-color: var(--white);
		display: grid;
		place-items: center;

		img {
			width: 11rem;
		}
	}
}


.piggy-bank {
	opacity: 0;
	transition: 1s ease;
	pointer-events: none;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 40%);
	width: 36%;
	aspect-ratio: 290/285;

	img {
		transition: transform 1s ease-in-out;
	}

	&.screen-question {
		opacity: 1;
	}
}
</style>
