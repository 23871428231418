<script setup>
import { defineProps } from "vue";
import Squircle from "@/components/Squircle"

const props = defineProps({
    icon: { type: String, default: null },
    label: { type: String, default: null },
    screen: { type: String, default: null },
    game: {type: String, default: null}
});

</script>

<template>
    <div class="app-icon" :class="[props.game, props.screen]">
        <div class="logo">
            <Squircle />
            <img :src="props.icon" />
        </div>
        <div class="app-label" v-html="props.label"></div>
    </div>
</template>

<style lang="scss">

.app-icon {
    &.high-low {
        width: 100%;
        .app-label {
            margin-top: 2%;
            p {
                font-size: var(--xl);
                font-style: italic;
                margin-top: 0;
                text-align: center;
                letter-spacing: 0.02em;
                strong {
                    font-family: var(--FormulaCondensedExtraBold);
                    font-size: var(--4xl);
                    font-style: normal;
                    letter-spacing: 0;
                }
            }
        }
        &.screen-home_cta {
            .logo { 
                max-width: 175px;
            }
            .app-label {
                p {
                    font-size: var(--lg);
                    strong {
                        display: inline-block;
                        line-height: 1;
                        font-size: var(--3xl);
                        margin-bottom: 2.5%;
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.app-icon {
    &.screen-start {
        transform: scale(0.9);
    }
    .logo {
        max-width: 256px;
        margin: 0 auto;
        position: relative;

        .squircle {
            filter: drop-shadow(0 10px 5px rgba(0,0,0,.15));	
            aspect-ratio: 1/1;
            width: 100%;			
        }
        img {
            width: 80%;
            position: absolute;
            top: 49%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        
    }
    .app-label {
        font-family: var(--FormulaMedium);
        color: var(--white);
        font-size: var(--2xl); 
    }
    
}
</style>

