<template>
	<div class="countdown-container">
		<div class="overline text-large" v-if="props.answeredQuestionsCount === 0" v-html="props.initialText"></div>
		<div class="overline" v-else v-html="props.text"></div>
		<div class="countdown" v-text="countdown"></div>
	</div>
</template>

<style lang="scss" scoped>
	.countdown-container {
		color: var(--white);
		.overline {
			font-family: var(--FormulaMedium);
			font-size: var(--lg);
			margin-bottom: 1em;

			&.text-large {
				font-size: var(--5xl);
			}
		}
		.countdown {
			font-family: var(--FormulaMedium);
			font-size: var(--8xl);
		}
	}

	.countdown-display {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 2rem;
		color: #fff;
	}
</style>

<script setup>
import { defineProps, defineEmits, onUnmounted, ref, onMounted } from "vue";

	const emit = defineEmits(['updateNextScreen'])
	const props = defineProps({
		initialText: {
			type: String,
			default: 'Let’s take<br/> the quiz!'
		},
		text: {
			type: String,
			default: 'Next Question Coming Up!'
		},
		answeredQuestionsCount: {
			type: Number,
			default: 0
		}
	});

	const updateNextScreen = () => {
		emit('updateNextScreen', true)
	}

	const countdown = ref(3)

	const startCountdown = () => {
		countdown.value = 3; // Reset to 3 seconds for each question
		const countdownInterval = setInterval(() => {
			countdown.value -= 1;
			if (countdown.value === 0) {
				clearInterval(countdownInterval);
				updateNextScreen();
			}
		}, 1000); // Decrement every second
	}

	const clearCountdown = () => {
		countdown.value = 3;
	};

	onMounted(() => {
		setTimeout(() => {
			startCountdown()	
		}, 1000);
		
	})

	onUnmounted(() => {
		clearCountdown();
	});


</script>