<script setup>
import { defineProps } from "vue";

const props = defineProps({
    label: { type: String, default: null },
    screen: { type: String, default: null }
});
</script>

<template>
    <div id="logo" class="logo" :class="props.screen">
        <span class="text" v-html="props.label"></span>
    </div>
</template>

<style lang="scss" scoped>
#logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;

    &.screen-home_cta {
        position: relative;
        top: unset;
        transform: translate(-50%, -10%);

        .text {
            font-size: var(--sm);
            bottom: 13.2%;
        }
    }

    &.screen-cta {
        top: 2%;
        transform: translate(-2%, 0);
    }

    &.screen-cta {
        top: 2%;
        transform: translate(-2%, 0);
    }

    .text {
        display: block;
        position: absolute;
        bottom: 12%;
        left: 33%;
        z-index: 1;
        font-family: var(--sculpin);
        font-style: italic;
        text-transform: uppercase;
        color: var(--white);
        font-size: var(--lg);
        opacity: 0;

        max-width: 13rem;
        width: 100%;
    }


}
</style>

