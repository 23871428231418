<script setup>
	import { ref, computed, watch, onMounted, defineProps, nextTick } from "vue";
	import DupacoLogo from "@/components/DupacoLogo";
	import QuestionComponent from "@/components/standard-quiz/QuestionComponent"
	import CountDown from "@/components/CountDown"
	import ColorStripe from "@/components/ColorStripe";
	import AppIcon from '@/components/AppIcon'
	import anime from "animejs"
	import { useRouter } from "vue-router";
	import _ from 'lodash'
	import lottie from 'lottie-web'
	import stdQzBgAnimation from '../../assets/lottie/std-quiz-bg.json';
	const route = useRouter()

	const props = defineProps({
		game: { type: Object, default: null }
	})

	// States for UI elements
	const logoState = ref('start'); // State for controlling logo appearance
	const horizonState = ref('start'); // State for controlling horizon bar appearance
	const currentScreenIndex = ref(0); // Index for the current screen in the game
	const dupacoTextColor = ref('white')
	const dupacoShineColor = ref('mango')

	// More reactive states and methods for game logic
	const correctAnswer = ref(null)
	const answeredQuestionsCount = ref(0)
	const totalScore = ref(0) // Total score in the game
	const selectedButton = ref(null) // Currently selected answer button
	const isAnswerSelected = ref(false) // Flag to indicate if an answer has been selected
	const showScore = ref(false)
	const showAnswerResponse = ref(false)
	const showDupacoLogo = ref(false)
	const total_questions = _.filter(props.game.screens, ['type', 'question']).length
	const background_color = ref(props.game.background_color)
	
	const handleAnswerSelected = (newValue) => {
		isAnswerSelected.value = newValue
	}

	const handleAnsweredQuestionsCount = () => {
		answeredQuestionsCount.value++
	}

	const handleUpdateTotalScore = async (isCorrect, totalMilliseconds) => {
		if(isCorrect) {
			totalScore.value += 1
		}		
	}

	const handleNextScreen = () => {
		goToNextScreen()

		// Hide the answer for the next question
		correctAnswer.value = null
	}
	
	const currentScreen = computed(() => {
		// Compute the current screen based on the current index
		if (props.game && props.game.screens && props.game.screens.length > currentScreenIndex.value) {
			return props.game.screens[currentScreenIndex.value];
		}
		return null;  // Return null if the index is out of bounds
	});

	const currentScreenType = computed(() => {
		// Compute a CSS class for the current screen type
		return currentScreen.value ? `screen-${currentScreen.value.type}` : 'default-screen-type';
	});

	const currentScreenKey = computed(() => `screen-${currentScreenIndex.value}`);

	let screenTimer = null;

	const setScreenTimer = (duration) => {
		clearScreenTimer();
		screenTimer = setTimeout(() => {
			// Adjust logo and go to next screen when timer expires
			if(currentScreen.value && currentScreen.value.type === 'start') {
				adjustLogoForScreen('intro')
			}
			goToNextScreen();
		}, duration * 1000); // Convert duration to milliseconds
	};

	const clearScreenTimer = () => {
		if (screenTimer) {
			clearTimeout(screenTimer);
			screenTimer = null;
		}
	};

	function adjustLogoForScreen(screen) {
		if (screen === 'start') {
			logoState.value = 'start';
		} else {
			horizonState.value = 'show';
			logoState.value = 'top';
		}
	}

	const isQuestionType = computed(() => currentScreen?.value.type === 'question');

	const GameEnter = () => {
		document.querySelector('.background .video').style.opacity = '0'
		document.querySelector('.color-stripe').style.opacity = '0'
		document.querySelector('.background').style.borderRadius = '50px'
		document.querySelector('.background').style.opacity = '1'
		document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'
		setTimeout(() => {
			const tl = anime.timeline({
				easing: 'easeOutQuad'
			})
			tl.add({
				targets: '.app-icon',
				opacity: [0,1],
				duration: 100
			}) 
			.add({
				targets: '.app-icon > div',
				scale: [.25,1],			
				delay: anime.stagger(100),
				opacity: [0,1],
				duration: 500
			}) 
			tl.add({
				targets: '.background',
				translateY: [0,0],
				scale: [.9, 1],
				borderRadius: ['50px', 0],
				duration: 200,
				easing: 'easeInOutQuad' 
			}, 750)
			.add({
				targets: '.video',
				opacity: [0,.2],
				duration: 250,
				easing: 'easeOutQuad',
				complete: () => {
					document.querySelector('.video').removeAttribute('style')
				}
			})			
			.add({
				targets: '.dupaco-logo svg',
				translateY: ['50px', 0],
				opacity: [0,1]
			}, 1200)
			.add({
				targets: '.color-stripe',
				duration: 250,
				translateX: ['-100%', 0],
				opacity: [0,1]
			}, 1300)
			.add({
				targets: '.app-icon',
				translateX: ['-50%', '-50%'],
				translateY: ['-50%', '0'],
				top: ['50%', '2%'],
				scale: [1,.5]
			}, '+=2500')
			.add({
				targets: '.app-icon .app-label',
				translateY: ['0', '15%'],
				scale: [1,1.3]
			}, '-=200')
			.add({
				targets: '.horizon-bar',
				translateY: ['-100%', '0'],
				complete: () => {
					goToNextScreen()
				}
			}, '-=1500')
		}, 500);
	}

	onMounted(() => {
		GameEnter()
		lottie.loadAnimation({
			container: document.getElementById('bg-lottie'),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: stdQzBgAnimation // Use the imported data
		})
	})

	// Watch for changes in the current screen index
	watch(currentScreenIndex, () => {
		console.log(currentScreen.value.type, currentScreen.value.duration)
		// Set timer and adjust UI for intro/outro screens
		if (currentScreen.value && (currentScreen.value.type === 'intro' || currentScreen.value.type === 'outro')) {
			setScreenTimer(currentScreen.value.duration);
		}
		if(currentScreen.value.type == 'countdown') {
			dupacoTextColor.value = 'white'			
		}
		if(currentScreen.value.type === 'question') {
			showScore.value = true;
			dupacoTextColor.value = 'black'
			anime({
				targets: '.score-display',
				opacity: [0,1],
				duration: 300,
				easing: 'easeOutQuad'
			})
		} else {
			anime({
				targets: '.score-display',
				opacity: [1,0],
				duration: 300,
				easing: 'easeInQuad'
			})
		}
		if(currentScreen.value.type === 'outro') {
			showDupacoLogo.value = false
		}
		if(currentScreen.value.type === 'outro' || currentScreen.value.type === 'countdown') {
			showScore.value = false
		} 

		if(currentScreen.value.type === 'outro') {
			dupacoTextColor.value = 'white'
		}

		if(currentScreen.value.type === 'point_total') {
			showDupacoLogo.value = false
			showScore.value = false;
			setTimeout(() => {
				goToNextScreen();
			},  3000)
		}

		if(currentScreen.value.type === 'cta') {
			showDupacoLogo.value = true
			dupacoShineColor.value = 'white'
			dupacoTextColor.value = 'white'
			anime({
				targets: '.dupaco-logo',
				opacity: [0,1],
				easing: 'easeOutQuad',
				duration: 500,
				delay: 1000,
			})
			setTimeout(() => {
				route.push({name: 'home'})
			}, 30000)
		}
	});

	const goToNextScreen = () => {

		isAnswerSelected.value = false; // Reset for the next question
		// Reset the selected button
		if (selectedButton.value) {
			selectedButton.value.style.transform = '';
			selectedButton.value.style.position = '';
			selectedButton.value.style.zIndex = '';
			selectedButton.value = null;
		} if (currentScreenIndex.value < props.game.screens.length - 1) {
			currentScreenIndex.value++;
		} else {
			clearScreenTimer(); // Clear timer at the end of the screens
		}
	};
	

	const calculatePercentage =(value, total) => {
		if (total === 0) {
			throw new Error('Total cannot be zero.');
		}
		return Math.round((value / total) * 100) +'%';
	}

</script>

<template>
	<div class="background" :class="currentScreenType">
		<div id="bg-lottie" class="video"></div> 
		<img src="@/assets/img/horizon-bar.svg" class="horizon-bar" :class="horizonState">
	</div>

	<AppIcon :icon="props.game?.icon" :label="props.game?.title" :screen="currentScreenType" :game="'speed-game'"/>

	<div class="screens" :class="currentScreenType">
		<transition name="Question">
			<div :key="currentScreenKey" :class="currentScreenType" class="screen-container" v-if="currentScreen?.type === 'question'">
				<QuestionComponent 
					class="question-content"
					v-if="currentScreen?.type === 'question'"
					:count="answeredQuestionsCount + 1"
					:question="currentScreen"
					:totalScore="totalScore"
					:correctAnswer="correctAnswer"
					:showAnswerResponse="showAnswerResponse"
					@updateAnswerSelected="handleAnswerSelected"
					@updateAnsweredQuestionsCount="handleAnsweredQuestionsCount"
					@updateNextScreen="handleNextScreen"
					@updateTotalScore="handleUpdateTotalScore" />
			</div>
		</transition>

		<transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'intro'">
				<div class="intro-content" v-html="currentScreen?.content"></div>
			</div>
		</transition>

		<transition name="countdown" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'countdown'">
				<CountDown
					class="overline text-large"
					initialText="Let’s take<br/> the quiz!"
					text="Next Question Coming Up!"
					@updateNextScreen="handleNextScreen"
					:answeredQuestionsCount="answeredQuestionsCount" />					
			</div>
		</transition>

		<transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'outro'">
				<div class="outro-content" v-html="currentScreen?.content"></div>
			</div>
		</transition>

		<transition name="cta" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'cta'">
				<div class="cta-content">
					<div class="content" v-html="currentScreen?.content"></div>
					<div class="qrcode">
						<img :src="currentScreen?.qrcode" />
					</div>
				</div>
			</div>
		</transition>

		<transition name="fadeup" mode="out-in">
		<div v-if="currentScreen?.type === 'point_total'" class="point-total-screen">
			<p v-html="currentScreen?.content"></p>
			<div v-html="calculatePercentage(totalScore,total_questions)" class="percentage"></div>
			<div class="totals">{{ totalScore }} / {{ total_questions }}</div>
		</div>
		</transition>
	</div>

	<div class="dupaco-logo" :class="[{ small: isQuestionType }, currentScreenType]" v-show="showDupacoLogo">
		<DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor"/>
	</div>

	<ColorStripe />
	
</template>

<style lang="scss">
	.app-icon strong {
		font-family: var(--FormulaExtendedBold);
	}
</style>

<style lang="scss" scoped>
	.Question-enter-active,	.Question-leave-active {
		transition: opacity 1.5s ease;
	}

	.Question-enter-from, .Question-leave-to {
		opacity: 0;
		transform: translate3d(0, 10%, 0);
	}
	.screens {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
	}
	.background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		&::before {
			position: absolute;
			content: '';
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition: .5s ease-in-out;
			background-color: v-bind(background_color);
		}

		&.screen-question {
			&::before {
				background-color: var(--white);
			}
			.video {
				opacity: .25;
			}
		}

		&.screen-cta {
			&::before {
				background-color: var(--bluebird);
			}
		}

		.video {
			position: absolute;
			inset: 0;
			height: 100%;
			width: 100%;
			opacity: 0.2;
			background-size: cover;
			mix-blend-mode: multiply;
		}

		.horizon-bar {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			opacity: .35;
			transform: translate3d(0, -100%, 0);
			&.show {
				transform: translate3d(0, -10%, 0)
			}
		}
		&.screen-question .horizon-bar {
			opacity: .05;
		}

		&.screen-cta .horizon-bar {
			opacity: .10;
		}
	}
	
	.app-icon {
		z-index: 20;
		position: fixed;
		top: 50%;
		left: 50%;
		transform-origin: center top;
		transform: translate3d(-50%, -50%, 0);
		width: 50%;
		opacity: 0;
		
		&.screen-start {
			transform: translate3d(-50%, -50%, 0);
		}
		&:not(.screen-start) {
			top: 2%;
			transform: translate3d(-50%, 0%, 0) scale3d(.45,.45,1);
			width: 70%;
			.app-label {
				transform: translate3d(0, 50%, 0) scale3d(1.5,1.5,1);
			}
		}
		> div {
			opacity: 0;
		}

		&.screen-question {
			.app-label { color: var(--black); }
		}

		&.screen-question {
			transform: translate3d(-50%, 0%, 0) scale3d(.40,.40,1);
			.app-label { color: var(--black); }
		}

		.logo {
			max-width: 256px;
			margin: 0 auto;
			filter: drop-shadow(0 10px 5px rgba(0,0,0,.15));	
			.squircle {
				clip-path: path('m128.6,257.21c56.35,0,87.68,0,108.14-20.46,20.46-20.46,20.46-51.79,20.46-108.14s0-87.68-20.46-108.14C216.28,0,184.96,0,128.6,0S40.92,0,20.46,20.46C0,40.92,0,72.25,0,128.6s0,87.68,20.46,108.14c20.46,20.46,51.79,20.46,108.14,20.46Z');
				background-color: var(--white);
				aspect-ratio: 1/1;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					max-width: 80%;
				}
			}
			
		}
		.app-label {
			font-family: var(--FormulaSemiCondensedLight);
			color: var(--white);
			font-size: var(--2xl); 
			text-transform: uppercase;
		}
	}

	.dupaco-logo {
		position: fixed;
		bottom: 5%;
		left: 50%;
		transform: translateX(-50%);
		width: 50%;
		transition: .5s ease-in-out;
		svg {
			opacity: 0;
		}
		&.small {
			width: 35%;
		}
		&.screen-cta {
			svg {
				opacity: 1;
			}
		}
	}
	.screen-container {
		position: fixed;
		width: 100%;
		left: 0;
		top: 25%;
		bottom: 25%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.question-content {
		max-width: 90%;
		margin: 0 auto;
	}

	.intro-content, .outro-content, .cta-content {
		position: fixed;
		top: 0;
		left: 50%;
		transform: translate(-50%);
		bottom: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 80%;
		margin: 0 auto;

		font-family: var(--FormulaMedium);
		color: var(--gray100);
		font-size: var(--3xl);
		line-height: 1.5em;	
	}

	.cta-content {
		flex-direction: column;
		.content {
			margin-bottom: 1em;
		}

		.qrcode {
			width: 23%;
			aspect-ratio: 1/1;
			border-radius: 16%;
			background-color: var(--white);
			display: grid;
			place-items: center;
			img {
				width: 75%;
			}
		}
	}

	#spotlight {
		width: 300px;
		aspect-ratio: 1/1;
		border-radius: 50%;
		position: fixed;
		bottom: 10%;
		left: 50%;
		opacity: 0;
		transform: translate3d(-50%,0,0);
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		border: 0 solid var(--mango);

		.bg {
			position: absolute;
			inset: 0;
			background-color: var(--yellow);
			opacity: .2;
			transition: .25s ease-in;
		}

		.time {
			position: relative;
			font-size: var(--7xl);
			font-family: var(--FormulaCondensedExtraLight);
			color: var(--white);
			opacity: 0;
			transform: translate3d(0,-25%,0);
			transition: .5s ease-out;
		}
	}

	.point-total-screen {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;

		p {
			font-family: var(--FormulaExtraBold);
			font-size: var(--2xl);
			color: var(--white);
		}
		.percentage {
			font-family: var(--FormulaExtraBold);
			font-weight: var(--extrabold);
			font-size: var(--8xl);
			color: var(--white);
			line-height: 1;
		}
		.totals {
			font-size: var(--lg);
			font-family: var(--FormulaExtraBold);
			font-weight: var(--extrabold);
			color: var(--white);
			border-radius: 20px;
			background: var(--white);
			padding: 0.5em 1.5em 0.3em;
			color: var(--asphalt);
			line-height: 1;
		}
	}

	
</style>

