<script setup>

import { defineProps } from "vue";
import ScoreboardBackground from "./ScoreboardBackground.vue";

const props = defineProps({
	formattedTime: { type: String, default: '00:00' },
	currentScreenType: { type: String, default: 'screen-question' },
	currentQuestionCount: { type: String, default: '01' },
	formattedCorrectAnswerCount: { type: String, default: '00' },
	totalQuestionCount: { type: String, default: '00' }
})


</script>

<template>
	<div class="scoreboard" :class="props.currentScreenType">
		<ScoreboardBackground />
		<div class="questions">
			<span class="question-text" :class="props.currentScreenType"
				v-if="props.currentScreenType === 'screen-question'">Question</span>
			<span class="question-text" :class="props.currentScreenType" v-else>Score</span>
			<div class="question-counter" :class="props.currentScreenType">
				<span class="current" v-text="props.currentQuestionCount"></span><span class="pipe"></span> <span
					class="total" v-text="props.totalQuestionCount"></span>
			</div>
		</div>
		<div class="time">
			<span v-text="props.formattedTime"></span>
			<span class="light"></span>
		</div>
	</div>
</template>

<style lang="scss">
.scoreboard {
	position: fixed;
	top: 1%;
	left: calc(50% - 20vw);
	width: 40vw;
	transition: 1s;
	transition-delay: 2s;
	transform-origin: center center;

	&.screen-finish-line {
		top: 20%;
		transform: translateX(-50%);
		transform: scale(1.3);

		.time {
			.light {
				background: var(--red);
				animation: none;
			}
		}
	}

	.questions {
		position: absolute;
		top: 52.5%;
		width: 100%;
		font-size: 2rem;
		color: var(--white);
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 1;

		.question-text {
			font-family: var(--sculpin);
			text-transform: uppercase;
			padding: 0 0 0 1em;
			font-size: var(--md);
			transition: font-size 1s;
			transition-delay: 2s;

		}

		.question-counter {
			padding-right: 1.5em;
			font-family: var(--ptmono);

			span {
				font-size: var(--md);
				padding: 0 0.2em 0 0.1em;
				transition: font-size 1s;
				transition-delay: 2s;

				&.current {
					padding: 0 0.4em 0 0.2em;
				}

				&.pipe {
					position: relative;
					height: 100%;
					padding: 0;

					&:after {
						position: absolute;
						content: "";
						top: 0;
						left: 0.15rem;
						height: 100%;
						background: var(--white);
						width: 0.1em;
						transform: rotate(15deg);
					}
				}

				&.total {
					padding-left: 0;
				}
			}
		}
	}

	.time {
		position: absolute;
		top: 77.5%;
		right: 20%;
		display: flex;
		align-items: center;
		font-family: var(--ptmono);
		font-size: var(--xl);
		color: var(--white);
		transition: all 1s;
		transition-delay: 2s;

		.light {
			position: absolute;
			top: 40%;
			right: -25%;
			height: 0.4em;
			width: 0.4em;
			background: var(--limegreen);
			border-radius: 50%;
			animation: blinker 1.2s linear infinite;
		}

		@keyframes blinker {
			50% {
				opacity: 0.5;
			}
		}
	}
}
</style>

