<script setup>
import { ref, defineProps, defineEmits, onMounted, nextTick } from "vue";
import QuestionText from "@/components/QuestionText"
import anime from "animejs"

const emit = defineEmits([
	'updateAnswerSelected',
	'updateAnsweredQuestionsCount',
	'updateTotalScoreAndAnswerMessage',
	'updateNextScreen',
	'updateAnswerResponse',
])

const props = defineProps({
	count: { type: Number, default: 1 },
	question: { type: Object, default: null },
	correctAnswer: { type: Boolean, default: null },
});

const buttonsDisabled = ref(false)
const isAnswerSelected = ref(false)
const userAnswered = ref(null)
const userSelectedAnswer = ref(null)

const updateAnswerSelected = (newValue) => { emit('updateAnswerSelected', newValue); }
const updateTotalScoreAndAnswerMessage = (answer) => { emit('updateTotalScoreAndAnswerMessage', answer); };
const updateAnsweredQuestionsCount = (newValue) => { emit('updateAnsweredQuestionsCount', newValue); }
const updateNextScreen = () => { emit('updateNextScreen', true) }

const selectAnswer = (answer, event) => {

	userSelectedAnswer.value = event.target.dataset.text

	if (buttonsDisabled.value) {
		event.preventDefault()
		return
	}

	updateAnswerSelected(true)
	buttonsDisabled.value = true

	const originalButton = event.currentTarget;
	const originalButtonStyles = window.getComputedStyle(originalButton)

	// Clone the button
	const clonedContainer = document.querySelector('.button')
	const clonedButton = originalButton.cloneNode(true);
	const rect = originalButton.getBoundingClientRect();
	document.querySelector('.cloned-answer .button').prepend(clonedButton);

	// Style the clone to overlay it exactly over the original
	clonedContainer.style.position = 'absolute';
	clonedContainer.style.left = `${rect.left}px`;
	clonedContainer.style.top = `${rect.top}px`;
	clonedButton.style.width = `${rect.width}px`;
	clonedContainer.style.height = `${rect.height}px`;
	clonedButton.style.backgroundColor = originalButtonStyles.backgroundColor;
	clonedContainer.style.transition = 'transform 0.3s ease-in-out, top 0.3s ease, left 0.3s ease, opacity .3s ease';
	clonedContainer.style.zIndex = '1000';

	// Animate the clone to the center
	const centerX = window.innerWidth / 2 - rect.left - rect.width / 2;
	const centerY = window.innerHeight / 2 - rect.top - rect.height / 2;

	requestAnimationFrame(() => {
		document.querySelector('.answers').style.opacity = 0;
		document.querySelector('.question').style.opacity = 0;
		document.querySelector('.question-count').style.opacity = 0;
		document.querySelector('.high-low-answer').style.opacity = 0;

		clonedButton.classList.add('clonedButtonClass');
		clonedContainer.style.transform = `translate3d(${centerX}px, ${centerY + 100}px, 0) scale3d(1.3,1.3,1)`;

		const tl = anime.timeline({
		})

		tl.add({
			targets: '.cloned-answer-copy',
			opacity: [0, 1],
			translateX: ['-50%', '-50%'],
			top: ['40%', '30%'],
			duration: 300,
			easing: 'easeOutQuad',
		})

		clonedContainer.addEventListener('transitionend', function handleTransitionEnd(event) {
			if (event.propertyName === 'transform') {
				answerMessageEnter(answer)
			}
		});
	});
};

const answerMessageEnter = async (answer) => {
	const tl = anime.timeline({
		complete: () => {
			updateAnsweredQuestionsCount(1)
		}
	})
	tl.add({
		targets: '.cloned-answer',
		opacity: [1, 0],
		translateY: [0, -10],
		duration: 300,
		easing: 'easeInQuad',
		delay: 2500,
	}).add({
		targets: '.user-answered',
		opacity: [0, 1],
		translateY: ['-25%', '-50%'],
		translateX: ['-50%', '-50%'],
		duration: 700,
		easing: 'easeOutQuad',
		begin: () => {
			updateTotalScoreAndAnswerMessage(answer)
		},
	}).add({
		targets: '.user-answered',
		opacity: [1, 0],
		duration: 1000,
		easing: 'easeInQuad',
		delay: 3500,
		complete: () => {
			updateNextScreen();
		}
	})
};

onMounted(() => {
	document.querySelector('.user-answered').style.opacity = 0;
})

</script>

<template>
	<div class="question-container">
		<Transition name="Question">
			<div class="question-content">
				<div class="question-count" :class="{ 'hide': isAnswerSelected }">
					<span class="bg"></span>
					<span class="content">Question <span v-text="props.count" class="count"></span></span>
				</div>
				<QuestionText :text="question.question" />
				<p class="high-low-answer">{{ question.high_low_number }}</p>
				<div class="answers">
					<button :disabled="buttonsDisabled"
						@touchstart="selectAnswer(question.answers[0], $event)" data-text="higher">
						<img src="../../assets/img/high-arrow.svg" class="true-false-icon"
							alt="Higher"  data-text="higher">
					</button>
					<div class="choose-high-low">
						<img src="@/assets/img/dotted-border.svg" alt="">
					</div>
					<button :disabled="buttonsDisabled"
						@touchstart="selectAnswer(question.answers[1], $event)" data-text="lower">
						<img src="../../assets/img/low-arrow.svg" class="true-false-icon"
							alt="Lower" data-text="lower">
					</button>
				</div>
				<div class="user-answered" v-if="userAnswered !== false">
					<p v-if="correctAnswer == true" class="answer-overline">Ding! Ding! Ding!</p>
					<p v-if="correctAnswer == false" class="answer-overline">Good Try! </p>
					<div class="answer-contain" v-if="correctAnswer == true">
						<div id="correct-icon"></div>
						<div v-html="question.correct_answer_response" class="high-low-response"></div>
					</div>
					<div class="answer-contain" v-if="correctAnswer == false">
						<div id="incorrect-icon"></div>
						<div v-html="question.incorrect_answer_response" class="high-low-response"></div>
					</div>
				</div>
			</div>
		</Transition>
		<div class="cloned-answer">
			<div class="cloned-answer-copy">
				<p class="your-answer">You think it’s<br> {{ userSelectedAnswer }} than:</p>
				<div class="your-answer-number">{{ question.high_low_number }}</div>
			</div>
			<div class="button"></div>
		</div>
	</div>
</template>

<style lang="scss">
	.high-low-response {
		p {
			font-size: var(--lg);
			line-height: 1.6;
		}
	}
</style>

<style lang="scss" scoped>
	.Question-enter-active,
		.Question-leave-active {
			transition: opacity 2s ease 1s;

			.question-count .content {
				transition: .20s ease 1s;
			}
			
			.question-count .bg {
				transition: .25s cubic-bezier(.43,.52,.03,.99) .85s;
			}
			.question-count {
				transition: .25s ease-out .55s;
			} 
			.question {
				transition: .5s ease .5s;
			}
			.answers {
				button { 
					transition: 1s ease;
					&:nth-child(1) { transition-delay: .15s; }
					&:nth-child(2) { transition-delay: .25s; }
					&:nth-child(3) { transition-delay: .40s; } 
					&:nth-child(4) { transition-delay: .60s; } 
				} 
			}
		}

		.Question-enter-from,
		.Question-leave-to {
			.question-count .content {
				transform: translate3d(0,50%,0);
				opacity: 0;
			}
			.question-count .bg {
				width: 4.3rem;
			}
			.question-count {
				opacity: 0;
				transform: translate3d(0,100%,0);
			}
			.question {
				opacity: 0;
				transform: translate3d(0,5%,0);
			}
			.answers {
				button {
					opacity: 0;
					transform: translate3d(0,5%,0);
				}
			}
		}
		.question-container {
			width: 100%;
			max-width: 90%;
			margin: 0 auto;
		}
		.question {
			font-family: var(--FormulaMedium);
			color: var(--white);
			width: 80%;
			overflow: hidden;
			margin-bottom: 1em;
		}

		.question-count {
			position: relative;
			font-family: var(--FormulaExtendedBold);
			font-size: var(--md);
			color: var(--white);
			text-transform: uppercase;
			display: inline-block;
			padding: .6em 1.5em .4em;
			margin-bottom: 2.5em;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 2em;
			
			&.hide {
				opacity: 0;
			}

			span { display: inline-block; }

			span.bg {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate3d(-50%, -50%, 0);
				height: 2.6em;
				max-height: 100%;
				width: 100%;
				background: linear-gradient(75deg, rgba(#00adee, 1) 0%, rgba(#eb0088, 0.85) 70%);
				border-radius: 2em;
				border: 2px solid var(--white);
			}
			span.content {
				position: relative;
			}

			span.count {
				padding-left: .8em;
			}
		}

		.user-answered {
			position: fixed;
			top: 48%;
			left: 50%;
			width: 70vw;
			margin: 0 auto;
			transform: translate(-50%, -50%);
			pointer-events: none;
			.answer-overline {
				font-family: var(--FormulaMedium);
				font-size: var(--3xl);
				color: var(--white);
				margin: 0 0 2.5em 0;
			}
			.answer-contain {
				position: relative;
				background: var(--white);
				padding: 17.5% 5% 7.5% 5%;
				border-radius: 3rem;
				box-shadow: 0 10px 0  rgba(white, 0.4);
				#correct-icon, #incorrect-icon {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translate(-50%, -50%);
				}
				.high-low-response {
					font-family: var(--FormulaMedium);
					font-size: var(--2xl);
					color: var(--black);
					strong {
						display: block;
					}
				}
			}
		}

		.question-content {
			.high-low-answer {
				font-family: var(--FormulaExtraBold);
				font-size: var(--5xl);
				color: var(--white);
				margin: 0 0 0.5em 0;
				line-height: 1;
			}
			.answers {
				position: relative;
				display: flex;
				justify-content: center;
				width: 80%;
				margin: 0 auto;
				gap: 20px;
				button {
					position: relative;
					border: none;
					outline: none;
					border-radius: 7.5%;
					width: 100%;
					transition: transform 0.3s ease-in-out, position 0s 0.3s;
					font-family: var(--FormulaExtraBold);
					box-shadow: 0 10px 0  rgba(white, 0.4);
					background: var(--white);
					&.clone {
						box-shadow: red;
					}

					&:hover {
						transform: scale(.9);
						box-shadow: 0 0 0  var(--gray200);
					}

					img {
						height: 75%;
					}
				}

				.choose-high-low {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					img {
						height: 90%;
					}
				}
			}
		}

		.cloned-answer-copy {
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0%);
			opacity: 0;
			pointer-events: none;
			.your-answer {
				font-family: var(--FormulaMedium);
				font-size: var(--3xl);
				color: var(--white);
				text-align: center;
				margin: 0 0 7.5% 0;
			}

			// .your-answer-overline {
			// 	font-family: var(--FormulaExtendedBold);
			// 	font-size: var(--lg);
			// 	text-transform: uppercase;
			// 	color: var(--white);
			// 	margin: 0 0 5% 0;
			// }

			.your-answer-number {
				font-family: var(--FormulaExtendedBold);
				font-size: var(--5xl);
				text-transform: uppercase;
				color: var(--white);
				margin: 0;
			}
		}

		.cloned-answer {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			pointer-events: none;

			button {
				transition: .5s opacity ease-in-out;

				&::before {
					display: none;
				}
			}

			.savings-message {
				font-family: var(--FormulaMedium);
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 100%);
				font-size: var(--2xl);
				padding-top: 1em;
				line-height: 1.2em;
				width: 60vw;
				opacity: 0;
				transition: .5s ease;
				p {
					margin: 0;
					font-family: var(--FormulaMedium);

					strong {
						font-family: var(--FormulaExtraBold);
					}
				}
			}
		}


	.cloned-answer {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;

		button {
			transition: .5s opacity ease-in-out;

			&::before {
				display: none;
			}
		}
	}

	button {
		position: relative;
		border: none;
		outline: none;
		border-radius: 7.5%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: var(--white);
		transition: transform 0.3s ease-in-out, position 0s 0.3s;
		font-family: var(--FormulaExtraBold);
		box-shadow: 0 10px 0  rgba(#ededed, 0.4);
	}
	.cloned-answer {
		button {
			padding: 2em;
		}
	}

	.answer-response {
		pointer-events: none;
		transition: .3s ease;
		font-size: var(--5xl);
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-family: var(--FormulaMedium);
		
		p {
			opacity: 0;
		}
	}
</style>