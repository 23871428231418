<script setup>

import { defineProps, defineEmits, ref, computed, watch, onMounted, onUnmounted } from 'vue';
import anime from "animejs"
import ColorStripe from "@/components/ColorStripe"
import DupacoLogo from "@/components/DupacoLogo"

const props = defineProps({
    game: { type: Object, default: null },
    isLast: { type: Boolean, default: false }
})

const emit = defineEmits([
    'updateNextScreen'
])

const dupacoTextColor = ref('black')
const dupacoShineColor = ref('mango')
const showDupacoLogo = ref(true);
const currentScreenIndex = ref(0)

const currentScreen = computed(() => {
    // Compute the current screen based on the current index
    if (props.game && props.game.home_screen && props.game.home_screen.length > currentScreenIndex.value) {
        return props.game.home_screen[currentScreenIndex.value];
    }
    return null;  // Return null if the index is out of bounds
});

// Watch for changes in the current screen index
watch(currentScreenIndex, () => {
    // Set timer and adjust UI for intro/outro screens
    setScreenTimer(currentScreen.value.duration);

});
// Timer for screen transitions
let screenTimer = null;

// Function to set a timer for screen transitions
const setScreenTimer = (duration) => {
    clearScreenTimer();
    screenTimer = setTimeout(() => {
        goToNextScreen();
    }, duration * 1000); // Convert duration to milliseconds
};

// Function to clear the screen transition timer
const clearScreenTimer = () => {
    if (screenTimer) {
        clearTimeout(screenTimer);
        screenTimer = null;
    }
};

const goToNextScreen = () => {
    if (currentScreenIndex.value < (props.game.home_screen.length - 1)) {
        currentScreenIndex.value++;
    } else {
        clearScreenTimer(); // Clear timer at the end of the screens
        // Animate Sceen Out
        if (!props.isLast) {
            setTimeout(() => {
                const tl = anime.timeline({
                    easing: 'easeInQuad',
                    complete: () => {
                        updateNextScreen();
                    }
                })
                tl.add({
                        targets: '.dupaco-logo',
                        translateX: ['-50%', '-50%'],
                        translateY: [0, '100%'],
                        opacity: [1, 0],
                        duration: 500,
                    })
                    .add({
                        targets: '.accredidation-logo',
                        opacity: [1, 0],
                        translateY: ['-15%', '-25%'],
                        duration: 850,
                    })
                    .add({
                        targets: '.copy',
                        opacity: [1, 0],
                        translateY: ['-20%', '-25%'],
                        duration: 500,
                    })
                    .add({
                        targets: '.screen-container',
                        duration: 500,
                        translateY: [0, '-25%'],
                        opacity: [1, 0]
                    }, 250)
                    .add({
                        targets: '.background',
                        translateY: [0, 0],
                        scale: [1, .9],
                        borderRadius: [0, '50px'],
                        duration: 500,
                        easing: 'easeInOutQuad'
                    }, 600)
                    .add({
                        targets: '.background',
                        translateY: [0, '-20%'],
                        opacity: [1, 0],
                        duration: 500,
                    }, 1500)

            }, 1000);
        } else {
            updateNextScreen();
        }

    }
};

const onEnter = () => {
    document.querySelector('.background').style.borderRadius = '50px'
    document.querySelector('.background').style.background = 'white'
    document.querySelector('.background').style.opacity = '0'
    document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'
    const tl = anime.timeline({
        easing: 'easeOutQuad',
        complete: () => {
            setScreenTimer(currentScreen.value.duration)
        }
    })
    tl.add({
        targets: '.background',
        translateY: ['20%', 0],
        opacity: [0, 1],
        duration: 500,
    })
        .add({
            targets: '.background',
            translateY: [0, 0],
            scale: [.9, 1],
            borderRadius: ['50px', 0],
            duration: 500,
            easing: 'easeInOutQuad'
        }, 1000)
        .add({
            targets: '.accredidation-logo',
            opacity: [0, 1],
            duration: 850,
        }, 1100)
        .add({
            targets: '.accredidation-logo',
            translateY: ['50%', '-15%'],
            scale: [1.3, 1],
            delay: 1000
        })
        .add({
            targets: '.copy',
            translateY: ['5%', '-20%'],
            opacity: [0, 1],
            duration: 1000,
            delay: 500
        })
        .add({
            targets: '.dupaco-logo',
            translateX: ['-50%', '-50%'],
            translateY: ['100%', 0],
            opacity: [0, 1],
            duration: 500,
            delay: 250,
        }, 1100)
        .add({
            targets: '.color-stripe',
            duration: 500,
            translateX: ['-100%', 0],
            opacity: [0, 1]
        }, 1200)
}

onMounted(() => {
    onEnter()
})

const updateNextScreen = () => { emit('updateNextScreen', true) }

onUnmounted(() => {
    clearScreenTimer();
})

</script>

<template>
    <div class="accredidation">
        <div class="background">
            <figure>
                <img src="../../assets/img/slideshow-bg.svg" alt="">
            </figure>
        </div>
        <div class="copy-contain">
            <img :src="currentScreen?.graphic" class="accredidation-logo" alt="">
            <div class="copy">
                <div class="headline" v-html="currentScreen?.content"></div>
                <div class="small-copy" v-html="currentScreen?.small_copy"></div>
            </div>
        </div>
        <ColorStripe />
        <div class="dupaco-logo" v-show="showDupacoLogo">
            <DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.accredidation {
    .background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    figure {
        position: fixed;
        inset: 0;
        margin: 0;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            opacity: 0.03;
            transform: scale(1.2);
        }
    }

    .copy-contain {
        position: fixed;
        top: 42%;
        left: 50%;
        width: 65vw;
        transform: translate(-50%, -50%);
        text-align: left;
        font-family: var(--robotoCondensed);
        .accredidation-logo {
            width: 40vw;
            transform: scale(1.3);
            margin: 0 auto 10% auto;
            opacity: 0;
        }
        .copy {
            opacity: 0;
            .headline {
                font-size: var(--4xl);
                font-weight: 200;
            }
            .small-copy {
                font-size: var(--xl);
                color: #757575;
                line-height: 1.4em;
            }
        }
    }
    .dupaco-logo {
        position: fixed;
        left: 50%;
        bottom: 3%;
        width: 35%;
        transform: translateX(-50%);
    }
}
</style>

<style lang="scss">
    .accredidation {
        .headline {
            p {
                margin: 0;
                letter-spacing: -0.02em;
            }
            strong {
                font-weight: 400;
            }
        }
    }
</style>