<script setup>
import { ref, computed, watch, onMounted, defineProps, nextTick } from "vue";
import DupacoLogo from "@/components/DupacoLogo";
import QuestionComponent from "@/components/speedracegame/QuestionComponent"
import CountDown from "@/components/speedracegame/CountDown"
import AppIcon from '@/components/speedracegame/AppIcon'
import ScoreboardCard from "@/components/speedracegame/ScoreboardCard.vue";
import anime from "animejs"
import lottie from 'lottie-web'
import { useRouter } from "vue-router";
import roadAnimationData from '../../assets/lottie/road.json';
import logoAnimationData from '../../assets/lottie/dupaco-speed.json'
import FlagSVG from "@/components/speedracegame/FlagSVG.vue";

const route = useRouter()

const props = defineProps({
	game: { type: Object, default: null },
})

// States for UI elements
const logoState = ref('start'); // State for controlling logo appearance
const currentScreenIndex = ref(0); // Index for the current screen in the game
const dupacoTextColor = ref('white')
const dupacoShineColor = ref('mango')

// More reactive states and methods for game logic
const correctAnswer = ref(null)
const selectedButton = ref(null) // Currently selected answer button
const isAnswerSelected = ref(false) // Flag to indicate if an answer has been selected
const showScore = ref(false)
const showAnswerResponse = ref(false)
const showDupacoLogo = ref(false)
const showScoreboard = ref(false)
const showFlagSVG = ref(true)
const showAppIcon = ref(true)
const time = ref(0);
const formattedTime = ref('00:00');
let lottieRoad = null
const repeat = ref(true)
const currentQuestionCount = ref(1)
const totalQuestionCount = ref(0)
const correctAnswerCount = ref(0);
const formattedCorrectAnswerCount = ref('00')

const handleCurrentQuestionCount = (newValue) => {
	if (currentQuestionCount.value < totalQuestionCount.value) {
		currentQuestionCount.value += newValue
	}
}

const handleAnswerSelected = (newValue) => {
	isAnswerSelected.value = newValue
}

const handleUpdateTotalScoreAndAnswerMessage = async (answer) => {
	correctAnswer.value = answer.correct

	if (correctAnswer.value) {
		correctAnswerCount.value++

		formattedCorrectAnswerCount.value = padNumber(correctAnswerCount.value)
	}

	await nextTick()
	const response = document.querySelector('.response')
	response.style.opacity = 1;
}

const handleNextScreen = () => {
	goToNextScreen()

	// Hide the answer for the next question
	correctAnswer.value = null
}

const currentScreen = computed(() => {
	// Compute the current screen based on the current index
	if (props.game && props.game.screens && props.game.screens.length > currentScreenIndex.value) {
		return props.game.screens[currentScreenIndex.value];
	}
	return null;  // Return null if the index is out of bounds
});

const currentScreenType = computed(() => {
	// Compute a CSS class for the current screen type
	return currentScreen.value ? `screen-${currentScreen.value.type}` : 'default-screen-type';
});

const currentScreenKey = computed(() => `screen-${currentScreenIndex.value}`);

let screenTimer = null;

const setScreenTimer = (duration) => {
	clearScreenTimer();
	screenTimer = setTimeout(() => {
		// Adjust logo and go to next screen when timer expires
		if (currentScreen.value && currentScreen.value.type === 'start') {
			adjustLogoForScreen('intro')
		}
		goToNextScreen();
	}, duration * 1000); // Convert duration to milliseconds
};

const clearScreenTimer = () => {
	if (screenTimer) {
		clearTimeout(screenTimer);
		screenTimer = null;
	}
};

const adjustLogoForScreen = (screen) => {
	if (screen === 'start') {
		logoState.value = 'start';
	} else {
		logoState.value = 'top';
	}
}

const GameEnter = () => {
	// showDupacoLogo.value = false;
	document.querySelector('.background').style.borderRadius = '50px'
	document.querySelector('.background').style.opacity = '1'
	document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'
	const tl = anime.timeline({
		easing: 'easeOutQuad',
		complete: () => {
			goToNextScreen()
		}
	})

	tl.add({
		targets: '.flag-svg',
		opacity: [0, 0.05],
		duration: 500,
		easing: 'easeOutInQuad',
	})
		.add({
			targets: '.dupaco-logo svg',
			opacity: [0],
			translateY: ['50%', '0%'],
			easing: 'easeOutQuad',
			duration: 500,
			delay: 1000,
		}, '+=500')
		.add({
			targets: '#logo .text',
			opacity: [0, 1],
			duration: 500,
			easing: 'easeInOutQuad'
		}, '+=500')
		.add({
			targets: '#logo',
			translateX: ['-50%', '-50%'],
			translateY: ['-50%', '0'],
			top: ['50%', '0%'],
			scale: [1.3, .4],
			duration: 750,
		}, '+=500')
		.add({
			targets: '.background',
			translateY: [0, 0],
			scale: [.9, 1],
			borderRadius: ['50px', 0],
			duration: 200,
			easing: 'easeInOutQuad',
		}, 750)
}

const goToNextScreen = () => {

	isAnswerSelected.value = false; // Reset for the next question
	// Reset the selected button
	if (selectedButton.value) {
		selectedButton.value.style.transform = '';
		selectedButton.value.style.position = '';
		selectedButton.value.style.zIndex = '';
		selectedButton.value = null;
	} if (currentScreenIndex.value < props.game.screens.length - 1) {
		currentScreenIndex.value++;
	} else {
		clearScreenTimer(); // Clear timer at the end of the screens
	}
};

const formattedCount = ref('01');

const updateFormattedCount = () => {
	formattedCount.value = padNumber(currentQuestionCount.value);
};

const padNumber = (num) => {
	return num > 9 ? num.toString() : num.toString().padStart(2, '0');
};

let interval
const startTimer = () => {
	interval = setInterval(() => {
		time.value++;
		formatTime();
	}, 1000);
};

const stopTimer = () => {
	clearInterval(interval)
}

const formatTime = () => {
	const minutes = Math.floor(time.value / 60);
	const seconds = time.value % 60;
	formattedTime.value = `${padTimerNumber(minutes)}:${padTimerNumber(seconds)}s`;
};

const padTimerNumber = (num) => {
	return num.toString().padStart(2, '0');
};

const calcTotalQuestionCount = () => {
	const questions = props?.game?.screens?.filter(question => {
		return question.type === 'question'
	})

	// Format the number with leading zeros
	const formattedQuestionCount = questions.length.toLocaleString('en-US', {
		minimumIntegerDigits: 2,
		useGrouping: false
	});


	totalQuestionCount.value = formattedQuestionCount
}
const roadStart = ref(true)
const roadFinish = ref(true)
const initLottie = () => {
	lottieRoad = lottie.loadAnimation({
		container: document.getElementById('road'),
		renderer: 'svg',
		loop: false,
		autoplay: false,
		animationData: roadAnimationData // Use the imported data
	});
	lottieRoad.setSpeed(4.5)

	lottieRoad.addEventListener('enterFrame', function (e) {
		if (e.currentTime >= 120 && roadStart.value === true) {
			lottieRoad.pause()
			roadStart.value = false
		}
		if (e.currentTime >= 601 && repeat.value == true) {
			lottieRoad.goToAndPlay(380, true)
		}
		if (e.currentTime >= 842 && roadFinish.value === true) {
			lottieRoad.pause()
			lottieRoad.setSpeed(1)
			roadFinish.value = false
		}
	});
}

watch(currentQuestionCount, () => {
	updateFormattedCount()
})

const initLottieLogo = () => {
	const lottieLogo = lottie.loadAnimation({
		container: document.getElementById('logo'),
		renderer: 'svg',
		loop: false,
		autoplay: true,
		animationData: logoAnimationData // Use the imported data
	});

	lottieLogo.setSpeed(1.3);
}

watch(currentScreenType, () => {

	if (currentScreenType.value === 'screen-intro') {
		anime({
			targets: '#road',
			opacity: [1, 0],
			duration: 200,
			easing: 'easeInOutQuad',
		})
		anime({
			targets: '.background img',
			opacity: [0, 1],
			duration: 500,
			easing: 'easeOutInQuad',
		})
		showFlagSVG.value = false
	}

	if (currentScreenType.value === 'screen-countdown') {
		anime({
			targets: '.logo',
			opacity: [1, 0],
			translateY: ['-50%'],
			easing: 'easeInOutQuad',
			duration: 200,
		})
		anime({
			targets: '#road',
			opacity: [0, 1],
		})
		anime({
			targets: '.background img',
			opacity: [1, 0],
		})
		anime({
			targets: '.flag-svg',
			opacity: [1, 0],
		})
		// showAppIcon.value = false
		lottieRoad.setSpeed(1)
		lottieRoad.goToAndPlay(0)
	}

	if (currentScreenType.value === 'screen-question') {
		showDupacoLogo.value = false
		anime({
			targets: '.scoreboard',
			opacity: [1, 0]
		})
		showScoreboard.value = true;
		startTimer()
		lottieRoad.setSpeed(3)
		lottieRoad.play()
	}

	if (currentScreenType.value === 'screen-finish-line') {
		// Hide the dupco logo
		showDupacoLogo.value = false;
		// Pause the road animation at the finish line
		repeat.value = false
		// Stop the timer
		stopTimer()

		// Go to the outro screen
		setTimeout(() => {
			goToNextScreen();
		}, currentScreen.value.duration * 1000)
	}

	if (currentScreenType.value === 'screen-outro') {
		anime({
			targets: '.logo',
			opacity: [0, 1],
			translateY: ['-50%', '0%'],
			easing: 'easeOutInQuad',
			duration: 200,
		})
		showScoreboard.value = false;
		lottieRoad.play()
		anime({
			targets: '.scoreboard',
			opacity: [1, 0],
			easing: 'easeOutQuad',
		})
	}
})

watch(currentScreenIndex, () => {

	// Set timer and adjust UI for intro/outro screens
	if (currentScreen.value && (currentScreen.value.type === 'intro' || currentScreen.value.type === 'outro')) {
		setScreenTimer(currentScreen.value.duration);
	}

	if (currentScreen.value.type == 'countdown') {
		dupacoTextColor.value = 'white'
		anime({
			targets: '.app-icon',
			opacity: [1, 0],
			easing: 'easeInOutQuad',
			duration: 200,
		})
	}

	if (currentScreen.value.type === 'question') {
		showDupacoLogo.value = false;
		showScore.value = false;
	}

	if (currentScreen.value.type === 'cta') {
		setTimeout(() => {
			route.push({ name: 'home' })
		}, 30000)
	}
});

onMounted(() => {
	setTimeout(() => {
		initLottieLogo()
	}, 1000);
	calcTotalQuestionCount()
	initLottie()
	GameEnter()
	lottieRoad.goToAndStop(0)
})

</script>

<template>
	<div class="background" :class="currentScreenType">
		<img src="@/assets/img/race-track-start.webp">
		<div id="road"></div>
		<FlagSVG v-if="showFlagSVG" />
	</div>

	<div class="dupaco-logo" :class="currentScreenType">
		<DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor" />
	</div>

	<AppIcon v-if="showAppIcon" :label="props.game?.title" :screen="currentScreenType" />

	<ScoreboardCard v-if="showScoreboard" :formattedTime="formattedTime" :currentQuestionCount="formattedCount"
		:formattedCorrectAnswerCount="formattedCorrectAnswerCount" :totalQuestionCount="totalQuestionCount"
		:currentScreenType="currentScreenType" />

	<div class="screens" :class="currentScreenType">
		<transition name="Question">
			<div :key="currentScreenKey" :class="currentScreenType" class="screen-container"
				v-if="currentScreen?.type === 'question'">
				<QuestionComponent class=" question-content" v-if="currentScreen?.type === 'question'"
					:count="formattedCount" :formattedCount="formattedCount" :question="currentScreen"
					:correctAnswer="correctAnswer" :showAnswerResponse="showAnswerResponse"
					@updateAnswerSelected="handleAnswerSelected" @updateNextScreen="handleNextScreen"
					@updateTotalScoreAndAnswerMessage="handleUpdateTotalScoreAndAnswerMessage"
					@updateCurrentQuestionCount="handleCurrentQuestionCount" />
			</div>
		</transition>

		<transition name="fadex" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'intro'">
				<div class="intro-content" v-html="currentScreen?.content"></div>
			</div>
		</transition>

		<transition name="countdown" mode="out-in">
			<div :key="currentScreenKey" class="screen-container countdown" v-if="currentScreen?.type === 'countdown'">
				<CountDown @updateNextScreen="handleNextScreen" />
			</div>
		</transition>

		<transition name="fadex" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'outro'">
				<div class="outro-content" v-html="currentScreen?.content"></div>
			</div>
		</transition>

		<transition name="fadex" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'cta'">
				<div class="cta-content">
					<div class="content" v-html="currentScreen?.content"></div>
					<div class="qrcode">
						<img :src="currentScreen?.qrcode" />
					</div>
				</div>
			</div>
		</transition>

	</div>
</template>

<style lang="scss" scoped>
#logo {
	position: fixed;
	left: 50%;
	width: 100vw;
}

#road {
	position: fixed;
	inset: 0;
	opacity: 0;
}

.Question-enter-active,
.Question-leave-active {
	transition: opacity 1.5s ease;
}

.Question-enter-from,
.Question-leave-to {
	opacity: 0;
	transform: translate3d(0, 10%, 0);
}

.screens {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}

.background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&::before {
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: .5s ease-in-out;
		background-color: var(--asphalt);
	}

	img {
		position: fixed;
		inset: 0;
		opacity: 0;
	}

	&.screen-start {
		img {
			opacity: 0;
		}

		#road {
			opacity: 1;
			transform: scale(1.5);
		}
	}

	#road {
		transform: scale(1.5);
		transition: 0.3s;
	}

	&.screen-intro {
		#road {
			opacity: 0;
		}
	}

	&.screen-countdown {
		#road {
			opacity: 1;
			transition: none;
			transform: scale(1);
		}
	}

	&.screen-question {
		#road {
			transform: scale(1);
		}
	}

	&.screen-finish-line {
		#road {
			opacity: 1;
			transition: none;
			transform: scale(1);
		}
	}

	&.screen-outro {
		#road {
			opacity: 1;
			transition: none;
			transform: scale(1);
		}
	}

	&.screen-cta {
		#road {
			opacity: 1;
			transition: none;
			transform: scale(1);
		}
	}

	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}

	#road {
		opacity: 0;
	}

	&.screen-question {
		&::before {
			background-color: var(--asphalt);
		}

		#road {
			opacity: 1;
		}
	}

	&.screen-cta {
		&::before {
			background-color: var(--asphalt);
		}
	}
}

.app-icon {
	z-index: 20;
	position: fixed;
	top: calc(50% - 25vw);
	left: calc(50% - 25vw);
	transform-origin: center top;
	transform: translate(-50%, -50%);
	width: 50vw;
	opacity: 0;

	&.screen-start {
		transform: translate3d(-50%, -50%, 0);
	}

	>div {
		opacity: 0;
	}

	&.screen-question {
		.app-label {
			color: var(--black);
		}
	}

	&.screen-question {
		transform: translate3d(-50%, 0%, 0) scale3d(.40, .40, 1);
	}

	.logo {
		max-width: 256px;
		margin: 0 auto;
		filter: drop-shadow(0 10px 5px rgba(0, 0, 0, .15));

	}
}

.dupaco-logo {
	position: fixed;
	bottom: 5%;
	left: 50%;
	transform: translateX(-50%);
	width: 50%;
	transition: .5s ease-in-out;

	svg {
		opacity: 0;
	}

	&.small {
		width: 35%;
	}

	&.screen-cta {
		svg {
			opacity: 1;
		}
	}
}

.screen-container {
	position: fixed;
	width: 100%;
	left: 0;
	top: 25%;
	bottom: 25%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&.countdown {
		top: 0;
		bottom: 43%;
	}

	&.screen-question {
		display: block;
	}
}

.question-content {
	max-width: 90%;
	margin: 0 auto;
}

.intro-content p {
	font-family: var(--sculpin);
}

.intro-content,
.outro-content,
.cta-content {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translate(-50%);
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 80%;
	margin: 0 auto;

	font-family: var(--sculpin);
	font-style: italic;
	font-size: var(--3xl);
	text-transform: uppercase;
	color: var(--gray100);
	line-height: 1.5em;
}

.intro-content {
	font-size: var(--3xl);
}


.intro-content {
	font-family: var(--sculpin);
	font-style: italic;
	text-transform: uppercase;
	line-height: 1.2em;
}

.outro-content {
	transition: 0.3s ease-in-out;
	transition-delay: 1s;
	line-height: 1.1em;
}

.cta-content {
	flex-direction: column;

	.content {
		margin-bottom: 1em;
		line-height: 1.1em;
	}

	.qrcode {
		width: 23%;
		aspect-ratio: 1/1;
		border-radius: 16%;
		background-color: var(--white);
		display: grid;
		place-items: center;

		img {
			width: 75%;
		}
	}
}

.point-total-screen {
	p {
		font-family: var(--FormulaExtraBold);
		font-size: var(--2xl);
		color: var(--white);
	}

	span {
		font-family: var(--FormulaExtraBold);
		font-weight: var(--extrabold);
		font-size: var(--8xl);
		color: var(--white);
	}
}

#burgler {
	position: fixed;
	bottom: -10px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
}
</style>

