<script setup>

import { defineProps, defineEmits, ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router'
import { locationStore } from '@/stores'
import { useHomeIconStoreForSlideshowExp } from '@/stores';
import anime from "animejs"
import ColorStripe from "@/components/ColorStripe"
import AppIcon from '@/components/AppIcon';


const store = locationStore()
const router = useRouter()

const homeIconStoreForSlideshowExp = useHomeIconStoreForSlideshowExp();


const props = defineProps({
    game: { type: Object, default: null },
    games: { type: Array, default: null },
    isLast: { type: Boolean, default: false }
})

const homeScreenContent = ref(props.game.home_screen[0].content);

const emit = defineEmits([
    'updateNextScreen'
])

const slideShowObject = ref(props.games.find(item => item.slideshow === true));
const levels = slideShowObject.value.levels
const logos = ref(null); // Ref to store the NodeList of logos
const currentIndex = ref(0); // Variable to keep track of the current index
const isTransitioning = ref(false); // Flag to track transition state

const currentScreenIndex = ref(0)

const currentScreen = computed(() => {
    // Compute the current screen based on the current index
    if (props.game && props.game.home_screen && props.game.home_screen.length > currentScreenIndex.value) {
        return props.game.home_screen[currentScreenIndex.value];
    }
    return null;  // Return null if the index is out of bounds
});

// Watch for changes in the current screen index
watch(currentScreenIndex, () => {
    // Set timer and adjust UI for intro/outro screens
    setScreenTimer(currentScreen.value.duration);
});

// Timer for screen transitions
let screenTimer = null;

// Function to set a timer for screen transitions
const setScreenTimer = (duration) => {
    clearScreenTimer();
    screenTimer = setTimeout(() => {
        goToNextScreen();
    }, duration * 1000); // Convert duration to milliseconds
};

// Function to clear the screen transition timer
const clearScreenTimer = () => {
    if (screenTimer) {
        clearTimeout(screenTimer);
        screenTimer = null;
    }
};

const goToNextScreen = () => {
    if (currentScreenIndex.value < (props.game.home_screen.length - 1)) {
        currentScreenIndex.value++;
    } else {
        clearScreenTimer(); // Clear timer at the end of the screens
        // Animate Sceen Out
        if (!props.isLast) {
            setTimeout(() => {
                const tl = anime.timeline({
                    easing: 'easeInQuad',
                    complete: () => {
                        updateNextScreen();
                    }
                })
                tl.add({
                    targets: '.screen-container',
                    duration: 500,
                    translateY: [0, '-25%'],
                    opacity: [1, 0]
                }, 250)
                .add({
                    targets: '.explore-brand',
                    duration: 500,
                    translateY: ['-50%', '-55%'],
                    translateX: ['-50%', '-50%'],
                    opacity: [1, 0]
                }, 300)
                .add({
                    targets: '.background',
                    translateY: [0, 0],
                    scale: [1, .9],
                    borderRadius: [0, '50px'],
                    duration: 500,
                    easing: 'easeInOutQuad'
                }, 1000)
                .add({
                    targets: '.background',
                    translateY: [0, '-20%'],
                    opacity: [1, 0],
                    duration: 500,
                }, 1500)

            }, 1000);
        } else {
            updateNextScreen();
        }

    }
};

const onEnter = () => {
    document.querySelector('.background').style.borderRadius = '50px'
    document.querySelector('.background').style.background = 'white'
    document.querySelector('.background').style.opacity = '0'
    document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'
    const tl = anime.timeline({
        easing: 'easeOutQuad',
        complete: () => {
            setScreenTimer(currentScreen.value.duration)
        }
    })
    tl.add({
        targets: '.background',
        translateY: ['20%', 0],
        opacity: [0, 1],
        duration: 500,
    })
        .add({
            targets: '.background',
            translateY: [0, 0],
            scale: [.9, 1],
            borderRadius: ['50px', 0],
            duration: 500,
            easing: 'easeInOutQuad'
        }, 1000)
        .add({
            targets: '.color-stripe',
            duration: 500,
            translateX: ['-100%', 0],
            opacity: [0, 1]
        }, 1300)
        .add({
            targets: '.main-logo',
            translateX: ['0%', '0%'],
            translateY: ['20%', 0],
            opacity: [0, 1],
            duration: 750,
        }, 1100)
        .add({
            targets: '.homescreen-content',
            translateY: ['20%', 0],
            opacity: [0, 1],
            duration: 750,
        }, 1400)
        .add({
            targets: '.interactions button',
            translateY: ['15%', 0],
            opacity: [0, 1],
            duration: 750,
            delay: anime.stagger(250)
        }, 1500)
}

const updateNextScreen = () => { emit('updateNextScreen', true) }

const goToGame = (slug) => {
    isTransitioning.value = true; // Set transitioning flag to true
    homeIconStoreForSlideshowExp.setShowHomeIcon(false)
	const tl = anime.timeline({
		easing: 'easeInQuad',
		complete: () => {
            isTransitioning.value = false; // Set transitioning flag to false
			router.push({ path: `/${store.data.slug}/game/${slug}` })
		}
	})
	tl.add({
			targets: '.color-stripe',
			duration: 250,
			translateX: [0, '100%'],
			opacity: [1, 0]
		})
		.add({
			targets: '.main-logo',
            translateX: ['0%', '0%'],
            translateY: [0, '-20%'],
            opacity: [1, 0],
            duration: 750,
		}, 100)
        .add({
            targets: '.homescreen-content',
            translateY: [0, '-20%'],
            opacity: [1, 0],
            duration: 750,
        }, 200)
        .add({
            targets: '.interactions button',
            translateY: ['0', '-20%'],
            opacity: [1, 0],
            duration: 750,
        }, 220)
		.add({
			targets: '.screen-container',
			duration: 500,
			translateY: [0, '-25%'],
			opacity: [1, 0]
		}, 250)
		.add({
			targets: '.background',
			translateY: [0, 0],
			scale: [1, .9],
			borderRadius: [0, '50px'],
			duration: 500,
			easing: 'easeInOutQuad'
		}, 600)
}

// Function to toggle highlight class on logos
function toggleHighlight() {
    // Remove highlight from the previous logo
    if (currentIndex.value > 0) {
    logos.value[currentIndex.value - 1].classList.remove('highlight');
    } else {
    logos.value[logos.value.length - 1].classList.remove('highlight'); // Remove highlight from the last logo when cycling back to the first
    }

    // Add highlight to the current logo
    logos.value[currentIndex.value].classList.add('highlight');

    // Move to the next logo
    currentIndex.value = (currentIndex.value + 1) % logos.value.length;
}

onMounted(() => {

    // When the component mounts, show the home button
    homeIconStoreForSlideshowExp.setShowHomeIcon(true)

    onEnter()

    logos.value = Array.from(document.querySelectorAll('.logo')); // Get all logos as an array

    // Add highlight class to the first logo initially
    logos.value[currentIndex.value].classList.add('highlight');

    // Set interval to toggle highlight every 2 seconds
    setInterval(toggleHighlight, 2000);
})

onUnmounted(() => {
    clearScreenTimer();
})

</script>

<template>
   <div class="background">
		<img src="../../assets//img/slideshow-bg.svg" class="slideshow-bg" alt="">
	</div>
    <div class="explore-brand">
        <img class="main-logo" src="../../assets//img/dupaco-credit-union.svg" alt="">
        <div v-html="homeScreenContent" class="homescreen-content"></div>
        <div class="interactions">
            <button v-for="level in levels" :key="level" @touchstart="!isTransitioning && goToGame(level.slug)" :disabled="isTransitioning">
                <AppIcon :icon="level.icon" screen="slideshow-home" />
                <span v-html="level.title"></span>
            </button>
        </div>
	</div>
    <ColorStripe />
</template>

<style lang="scss">
.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .slideshow-bg {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: scale(1.2);
        opacity: 0.03;
    }
}
</style>


<style scoped lang="scss">
.explore-brand {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    .main-logo {   
        display: block;
        width: 70%;
        transform: translate(-50%, 100%);
        margin: 0 auto 3.5vh auto;
    }
    .homescreen-content {
        font-family: var(--robotoCondensed);
        font-weight: 300;
        font-size: var(--xl);
        margin-bottom: 3vh;
        letter-spacing: -0.02em;
        line-height: 1.4em;
    }
    .interactions {
        display: flex;
        justify-content: center;
        gap: 5vw;
        button {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            border: 0;
            background: transparent;
            img {
                height: 10vw;
                width: 10vw;
                margin-bottom: 1vh;
                box-shadow: 0 0 50px rgba(0,0,0,0.2);
            }
            span {
                font-size: var(--md);
                margin-top: 1em;
            }
        }
    }
}
</style>

<style lang="scss">
.interactions {
    button {
        .logo {
            &.highlight {
                svg {
                    path {
                        fill: var(--mango) !important;
                    }
                }
            }
            img {
                width: auto !important;
                inset: 4px !important;
                transform: none !important;
            }
            svg {
                path {
                    transition: fill 0.3s ease-in-out;
                }
            }
        }
    }
}
</style>