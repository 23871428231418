<script setup>

import { defineProps, defineEmits, ref, computed, watch, onMounted, onUnmounted } from 'vue';
import anime from "animejs"
import ColorStripe from "@/components/ColorStripe"
import DupacoLogo from "@/components/DupacoLogo"
import AppIcon from '@/components/AppIcon';

const props = defineProps({
    game: { type: Object, default: null },
    isLast: { type: Boolean, default: false }
});

const employees = ref(props.game.home_screen[0].employees)


const emit = defineEmits([
    'updateNextScreen'
])

const dupacoTextColor = ref('black')
const dupacoShineColor = ref('mango')
const showDupacoLogo = ref(true);
const currentScreenIndex = ref(0)
let screenTimer = null;
const horizonState = ref('start');

const currentScreen = computed(() => {
    // Compute the current screen based on the current index
    if (props.game && props.game.home_screen && props.game.home_screen.length > currentScreenIndex.value) {
        return props.game.home_screen[currentScreenIndex.value];
    }
    return null;  // Return null if the index is out of bounds
});

const setScreenTimer = (duration) => {
    clearScreenTimer();
    screenTimer = setTimeout(() => {
        goToNextScreen();
    }, duration * 1000); // Convert duration to milliseconds
};

const employeesClass = computed(() => {
  return {
    'large-list': employees.value.length > 6,
  };
});


const clearScreenTimer = () => {
    if (screenTimer) {
        clearTimeout(screenTimer);
        screenTimer = null;
    }
};

const goToNextScreen = () => {
    if (currentScreenIndex.value < (props.game.home_screen.length - 1)) {
        currentScreenIndex.value++;
    } else {
        clearScreenTimer(); // Clear timer at the end of the screens
        // Animate Sceen Out
        if (!props.isLast) {
            setTimeout(() => {
                const tl = anime.timeline({
                    easing: 'easeInQuad',
                    complete: () => {
                        updateNextScreen();
                    }
                })
                tl.add({
                    targets: '.app-icon',
                    opacity: [1, 0],
                    duration: 500,
                })
                .add({
                        targets: '.dupaco-logo',
                        translateX: ['-50%', '-50%'],
                        translateY: [0, '100%'],
                        opacity: [1, 0],
                        duration: 350,
                    }, 100)
                    // .add({
                    //     targets: '.app-icon',
                    //     opacity: [1, 0],
                    //     duration: 500,
                    // }, '-=350')
                    .add({
                        targets: '.copy',
                        opacity: [1, 0],
                        duration: 500,
                    }, 200)
					.add({
						targets: '.employees .employee',
						opacity: [1, 0],
						duration: 500,
					}, 250)
                    .add({
                        targets: '.screen-container',
                        duration: 500,
                        translateY: [0, '-25%'],
                        opacity: [1, 0]
                    }, 300)
                    .add({
                        targets: '.background',
                        translateY: [0, 0],
                        scale: [1, .9],
                        borderRadius: [0, '50px'],
                        duration: 500,
                        easing: 'easeInOutQuad',
                    }, 350)
                    .add({
                        targets: '.background',
                        translateY: [0, '-20%'],
                        opacity: [1, 0],
                        duration: 500,
                    }, 1000)

            }, 1000);
        } else {
            updateNextScreen();
        }

    }
};

const onEnter = () => {
    document.querySelector('.background').style.borderRadius = '50px'
    document.querySelector('.background').style.background = 'white'
    document.querySelector('.background').style.opacity = '0'
    document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'
    const tl = anime.timeline({
        easing: 'easeOutQuad',
        complete: () => {
            setScreenTimer(currentScreen.value.duration)
        }
    })
    tl.add({
        targets: '.background',
        translateY: ['20%', 0],
        opacity: [0, 1],
        duration: 500,
    })
        .add({
            targets: '.background',
            translateY: [0, 0],
            scale: [.9, 1],
            borderRadius: ['50px', 0],
            duration: 500,
            easing: 'easeInOutQuad'
        }, 1000)
        .add({
            targets: '.app-icon',
            opacity: [0, 1],
            duration: 850,
        }, 1100)
        .add({
            targets: '.app-icon',
            translateY: ['50%', '-10%'],
            translateX: ['-50%', '-50%'],
			top: 0,
            scale: [1, 0.5],
            delay: 1000,
            width: '37%'
        }, 1000)
		.add({
            targets: '.horizon-bar',
            translateY: ['-100%', '-10%'],
            duration: 500,
            easing: 'easeInOutQuad'
        }, 1500)
        .add({
            targets: '.copy',
            translateY: ['5%', 0],
            opacity: [0, 1],
            duration: 1000,
        })
        .add({
            targets: '.dupaco-logo',
            translateX: ['-50%', '-50%'],
            translateY: ['100%', 0],
            opacity: [0, 1],
            duration: 500,
            delay: 250,
        }, 1100)
        .add({
            targets: '.color-stripe',
            duration: 500,
            translateX: ['-100%', 0],
            opacity: [0, 1],
        }, 1200)
		.add({
            targets: '.employee-copy',
            translateY: [0, '-5%'],
            opacity: [1, 0],
            duration: 500,
			delay: 2500,
        })
		.add({
            targets: '.copy',
            translateY: [0, '-25%'],
            opacity: [1, 1],
            duration: 1000,
        })
		.add({
            targets: '.employees .employee',
            translateY: ['5%', 0],
            opacity: [0, 1],
            duration: 500,
			delay: anime.stagger(250)
        })
}

const updateNextScreen = () => { emit('updateNextScreen', true) }

watch(currentScreenIndex, () => {
    console.log(currentScreen.value.duration)
    setScreenTimer(currentScreen.value.duration);
});

onMounted(() => {
    onEnter()
})

onUnmounted(() => {
    clearScreenTimer();
})

</script>

<template>
    <div class="new-employees">
        <div class="background">
            <figure>
                <img src="../../assets/img/slideshow-bg.svg" alt="">
            </figure>
			<img src="@/assets/img/horizon-bar.svg" class="horizon-bar" :class="horizonState">
        </div>
		<AppIcon :icon="props.game?.icon" screen="slideshow-home" />
        <div class="copy-contain">
            <div class="copy">
				<div class="welcome">
					<p class="small">Welcome to the</p>
					<p class="large">Dupaco Crew</p>
				</div>
				<div class="employee-copy">
					<div class="headline" v-html="currentScreen?.content"></div>
					<div class="small-copy" v-html="currentScreen?.small_copy"></div>
				</div>
            </div>
			<div :class="['employees', employeesClass]">
				<div v-for="(employee, index) in employees" v-html="employee" :key="index" class="employee"></div>
			</div>
        </div>
        <div class="dupaco-logo" v-show="showDupacoLogo">
            <DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor" />
        </div>
    </div>
	<ColorStripe />
</template>

<style lang="scss" scoped>
.new-employees {
	.horizon-bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		opacity: .05;
		transform: translate3d(0, -100%, 0);
	}
	.app-icon {
		position: fixed;
		left: 50%;
		top: 10%;
        width: 37%;
		transform: translateX(-50%);
	}
    .background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    figure {
        position: fixed;
        inset: 0;
        margin: 0;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            opacity: 0.03;
            transform: scale(1.2);
        }
    }

    .copy-contain {
        position: fixed;
        top: 42%;
        left: 50%;
        width: 90vw;
        transform: translate(-50%, -45%);
        text-align: left;
        font-family: var(--robotoCondensed);
        .accredidation-logo {
            width: 40vw;
            transform: scale(1.3);
            margin: 0 auto 10% auto;
            opacity: 0;
        }
        .copy {
			opacity: 0;
			color: var(--black);
			text-align: center;
			.employee-copy {
				.small-copy {
					font-family: var(--robotoCondensed);
					font-size: var(--2xl);
					line-height: 1.4em;
					font-weight: 300;
					strong {
						font-family: var(--robotoCondensed);
						font-weight: 300 !important;
					}
				}
			}
        }
		.employees {
			position: fixed;
			top: 40%;
			left: 50%;
			transform: translate(-50%, 0);
			width: 50vw;
			margin: 0 auto;
            &.large-list {
                columns: 2;
                width: 80vw;
            }
			.employee {
				font-family: var(--robotoCondensed);
				font-size: var(--2xl);
				background: var(--white);
				border: 2px solid var(--mango);
				margin-bottom: 5%;
				padding: 0.5em;
				border-radius: 0.4em;
				text-align: center;
				box-shadow: 0 0.1em 0.3em rgba(#e87502, 0.4);
			}
		}
    }
    .dupaco-logo {
        position: fixed;
        left: 50%;
        bottom: 3%;
        width: 35%;
        transform: translateX(-50%);
    }
}
</style>

<style lang="scss">
.new-employees	{
	.small-copy {
		font-family: var(--robotoCondensed);
		font-size: var(--2xl);
		line-height: 1.4em;
		font-weight: 300;
		strong {
			font-family: var(--robotoCondensed);
			font-weight: 400 !important;
		}
	}

	.welcome {
		p.small {
			font-family: var(--newsgothic);
			font-size: var(--4xl);
			letter-spacing: -0.03em;
			margin: 0;
			text-transform: uppercase;
		}
		p.large {
			font-family: var(--newsgothic);
			color: var(--mango);
			font-size: var(--9xl);
			margin: 0;
		}
	}
}
</style>