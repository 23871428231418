<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { locationStore, useHomeStore, useDisableTimeoutStore } from '@/stores';

import DiscoveryGame from '@/views/experiences/DiscoveryGame'
import StandardQuizGame from '@/views/experiences/StandardQuizGame'
import HighLowGame from '@/views/experiences/HighLowGame';
import SpeedGame from '@/views/experiences/SpeedGame'
import SpeedRaceGame from '@/views/experiences/SpeedRaceGame'
import MemberStories from "./experiences/MemberStories.vue";
import VoicesBuilding from "./experiences/VoicesBuilding.vue";
import OurBrand from "./experiences/OurBrand.vue";

// Initialize reactive state and computed properties
const store = locationStore(); // Initialize the store for location data
const homeStore = useHomeStore(); // Initialize the store for location data
const disableTimeout = useDisableTimeoutStore();
const route = useRoute(); // Access the route information
const router = useRouter(); // Access the router methods
const gameSlug = ref(route.params.slug); // Reactive reference to the game slug from the route parameters
const disableTimeoutForScreen = ref(false);

const levels = ref(null);

const disableTimeoutValue = computed(() => disableTimeout.disableTimeout);

store.init(); // Initialize the store
let gameInit = ref(true)
let timeoutId
const resetTimeout = () => {
	// const timer = 1 * 60 * 1000 // 1 minute
	const timer = 1 * 60 * 100000 // 1 minute

	if (timeoutId) {
		clearTimeout(timeoutId)
	}
	timeoutId = setTimeout(() => {
		if(disableTimeoutValue.value === false) {
			router.push({ name: 'home' })
		}
	}, timer)
	gameInit.value = false
}

const handleUserInteraction = () => {
	resetTimeout()
}

const handleDisableTimeoutForScreen = (value) => {
	disableTimeoutForScreen.value = value
}


onMounted(() => {
	homeStore.setShowIcons(false)
	// Initialize the timeout when the component is mounted
	resetTimeout()

	// Add event listeners for user interactions
	document.addEventListener('touchstart', () => {
		handleUserInteraction()
	})
	document.addEventListener('touchmove', () => {
		handleUserInteraction()
	})

	document.querySelectorAll('.nav .game').forEach(button => {
		button.classList.add('hide')
		button.style.display = 'none'
	})
})

onUnmounted(() => {
	// Clean up when the component is destroyed
	clearTimeout(timeoutId)
	document.addEventListener('touchstart', () => handleUserInteraction)
	document.addEventListener('touchmove', () => handleUserInteraction)
})

const slideshow = computed(() => {
	// Compute the current game based on the slug
	const results = store.data?.games.filter(item => item.slideshow === true);

	return results
});


if(slideshow.value[0]) {
	levels.value = slideshow.value[0]?.levels
}


// Computed properties for reactive data processing
const game = computed(() => {
	// Compute the current game based on the slug
	return store.data?.games.find(game => game.slug === gameSlug.value);
});

const level = computed(() => {
  // Compute the current game based on the slug
  if (levels.value?.length != null) {
    return levels.value.find(level => level.slug === gameSlug.value);
  }
  return null; // Or any other default value you prefer
});

watch(disableTimeoutValue, () => {
	if(disableTimeoutValue.value === false) {
		resetTimeout()
	}
})

watch(() => route.params.slug, (newSlug) => {
	gameSlug.value = newSlug;
});


// Watch for route parameter changes and update gameSlug
watch(() => route.params.slug, (newSlug) => {
	gameSlug.value = newSlug;
});

</script>

<template>
	<div>
		<div v-if="game?.experience_type === 'discovery_quizzes'">
			<DiscoveryGame :game="game" />
		</div>
		<div v-if="game?.experience_type === 'standard_quizzes'">
			<StandardQuizGame :game="game" />
		</div>
		<div v-if="game?.experience_type === 'speedround'">
			<SpeedGame :game="game" />
		</div>
		<div v-if="game?.experience_type === 'speed_round_race_theme'">
			<SpeedRaceGame :game="game" />
		</div>
		<div v-if="game?.experience_type === 'high_low'">
			<HighLowGame :game="game" />
		</div>

		<!-- Slideshows -->
		<div v-if="level?.experience_type === 'slideshow'">
			<div v-if="gameSlug === 'our-brand'">
				<OurBrand :game="level" />
			</div>
			<div v-if="gameSlug == 'member-stories' || gameSlug == 'dupaco-crew-stories'">
				<MemberStories :game="level" />
			</div>
			<div v-if="gameSlug == 'voices-building'">
				<VoicesBuilding :game="level" @handleDisableTimeoutForScreen="handleDisableTimeoutForScreen"/>
			</div>
		</div>
	</div>
</template>

<style>
.countdown-enter-active {
	transition: all 1s ease-out .5s;
}

.countdown-leave-active {
	transition: all .5s ease-in;
}

.countdown-enter-from {
	transform: translate3d(0px, 50px, 0);
	opacity: 0;
}

.countdown-leave-to {
	transform: translate3d(0px, -50px, 0);
	opacity: 0;
}

.cta-enter-active {
	transition: all 1s ease-out 1s;
}

.cta-leave-active {
	transition: all .75s ease-in;
}

.cta-enter-from {
	transform: translate3d(0px, 50px, 0);
	opacity: 0;
}

.cta-leave-to {
	transform: translate3d(0px, -50px, 0);
	opacity: 0;
}
</style>


<style lang="scss" scoped></style>
