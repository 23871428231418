	<template>
	<div class="question" v-html="props.text"></div>
</template>

<style scoped>
	svg g {	transition: .3s ease-in-out; }
	.question { 
		font-size: var(--2xl);
		line-height: 1.4;
		margin: 0 auto 3rem;
	}
</style>
  
<script setup>
	import { onMounted, nextTick, defineProps } from 'vue';
	
	const props = defineProps({
		text: {
			type: String,
			default: ''
		}
	});

    function adjustFontSize(el) {
		nextTick(() => { // Ensure DOM updates are completed
			const element = document.querySelector(el);
			if (!element) return;

			let fontSize = parseInt(window.getComputedStyle(element).fontSize);

			while (element.scrollHeight > element.clientHeight && fontSize > 26) {
				fontSize--;
				// element.style.fontSize = fontSize + "px";
				
			}
		});
	}

	onMounted(() => {
		adjustFontSize('.question');
	});

	// watch(() => currentScreen.value, (newScreen) => {
	// 	if (newScreen && newScreen.type === 'question') {
	// 		adjustFontSize('.question');
	// 	}
	// }, { immediate: true });
	
</script>
  