<script setup>

import { defineProps, defineEmits, ref, computed, watch, onMounted, onUnmounted } from 'vue';
import anime from "animejs"

const props = defineProps({
    game: { type: Object, default: null },
    isLast: { type: Boolean, default: false }
})

const emit = defineEmits([
    'updateNextScreen'
])

const currentScreenIndex = ref(0)

const currentScreen = computed(() => {
    // Compute the current screen based on the current index
    if (props.game && props.game.home_screen && props.game.home_screen.length > currentScreenIndex.value) {
        return props.game.home_screen[currentScreenIndex.value];
    }
    return null;  // Return null if the index is out of bounds
});


// Watch for changes in the current screen index
watch(currentScreenIndex, () => {
    // Set timer and adjust UI for intro/outro screens
    setScreenTimer(currentScreen.value.duration);

});
// Timer for screen transitions
let screenTimer = null;

// Function to set a timer for screen transitions
const setScreenTimer = (duration) => {
    clearScreenTimer();
    screenTimer = setTimeout(() => {
        goToNextScreen();
    }, duration * 1000); // Convert duration to milliseconds
};

// Function to clear the screen transition timer
const clearScreenTimer = () => {
    if (screenTimer) {
        clearTimeout(screenTimer);
        screenTimer = null;
    }
};

const goToNextScreen = () => {
    if (currentScreenIndex.value < (props.game.home_screen.length - 1)) {
        currentScreenIndex.value++;
    } else {
        clearScreenTimer(); // Clear timer at the end of the screens
        // Animate Sceen Out
        if (!props.isLast) {
            setTimeout(() => {
                const tl = anime.timeline({
                    easing: 'easeInQuad',
                    complete: () => {
                        updateNextScreen();
                    }
                })
                tl.add({
                    targets: '.screen-container',
                    duration: 500,
                    translateY: [0, '-25%'],
                    opacity: [1, 0]
                }, 250)
                    .add({
                        targets: '.background',
                        translateY: [0, 0],
                        scale: [1, .9],
                        borderRadius: [0, '50px'],
                        duration: 500,
                        easing: 'easeInOutQuad'
                    }, 600)
                    .add({
                        targets: '.background',
                        translateY: [0, '-20%'],
                        opacity: [1, 0],
                        duration: 500,
                    }, 1500)

            }, 1000);
        } else {
            updateNextScreen();
        }

    }
};

const onEnter = () => {

    document.querySelector('.background').style.borderRadius = '50px'
    document.querySelector('.background').style.background = 'white'
    document.querySelector('.background').style.opacity = '0'
    document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'
    const tl = anime.timeline({
        easing: 'easeOutQuad',
        complete: () => {
            setScreenTimer(currentScreen.value.duration)
        }
    })
    tl.add({
        targets: '.background',
        translateY: ['20%', 0],
        opacity: [0, 1],
        duration: 500,
    })
        .add({
            targets: '.background',
            translateY: [0, 0],
            scale: [.9, 1],
            borderRadius: ['50px', 0],
            duration: 500,
            easing: 'easeInOutQuad'
        }, 1000)
        .add({
            targets: '.dupaco-logo',
            translateX: ['-50%', '-50%'],
            translateY: ['100%', 0],
            opacity: [0, 1],
            duration: 500,
        }, 1100)
        .add({
            targets: '.start-content i:first-child',
            duration: 500,
            translateY: ['85%', 0],
        }, 1150)
        .add({
            targets: '.start-content i:last-child',
            duration: 500,
            translateY: ['-85%', 0],
            complete: () => {
                anime({
                    targets: '.start-content strong',
                    duration: 500,
                    easing: 'easeOutQuad',
                    opacity: [0, 1],
                })
            }
        }, 1150)
        .add({
            targets: '.launch-quiz button',
            duration: 500,
            translateY: ['80px', 0],
            opacity: [0, 1]
        }, 1150)
        .add({
            targets: '.color-stripe',
            duration: 500,
            translateX: ['-100%', 0],
            opacity: [0, 1]
        }, 1200)


}

onMounted(() => {
    onEnter()
})

const updateNextScreen = () => { emit('updateNextScreen', true) }

onUnmounted(() => {
    clearScreenTimer();
})


</script>

<template>
    <div class="background">
        <figure>
            <img :src="props.game.home_screen[0].graphic" alt="">
        </figure>
    </div>
</template>

<style lang="scss">
.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

figure {
    position: fixed;
    inset: 0;
    margin: 0;

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}
</style>