<script setup>
	import { defineProps, defineEmits } from "vue";

	const props = defineProps({
		currentScreen: { type: Object, default: null },
		textColor: { type: String, default: null }
	})

	const emit = defineEmits([
		'goToScreen',
	])

	const goToScreen = (newValue) => { emit('goToScreen', newValue); }

</script>

<template>
	<div class="copy-contain">
		<div class="copy" v-html="currentScreen.content"></div>
		<div class="ctas" v-if="currentScreen.ctas === '1'">
			<button @touchstart="goToScreen('brand-screen')">The Dupaco Brand</button>
			<button @touchstart="goToScreen('logo-evolution')">Story Behind Our Logo</button>
		</div>
	</div>
</template>


<style lang="scss" scoped>
	.copy-contain {
		position: fixed;
		top: 50%;
		left: 50%;
		width: 80vw;
		transform: translate(-50%, -50%);
		.dupaco-logo {
			width: 45vw;
			margin: 0 auto 5% auto;
		}
		.copy {
			font-family: var(--robotoCondensed);
			font-size: var(--3xl);
			color: var(--white);
		}
		.ctas {
			display: flex;
			justify-content: center;
			gap: 2.5%;
			button {
				font-family: var(--newsgothic);
				font-size: var(--lg);
				color: var(--white);
				border: 0;
				border-radius: 0.5em;
				padding: 0.5em 1em;
				background: var(--mango);
				text-transform: uppercase;
			}
		}
	}

</style>