<script setup>
	import { ref, defineEmits, defineProps, watch } from "vue";
	import { Swiper, SwiperSlide } from 'swiper/vue';
	import { EffectCards, Autoplay } from 'swiper/modules';
	import 'swiper/css';
	import 'swiper/css/effect-cards';

	const modules = [EffectCards, Autoplay];
	const props = defineProps({
		currentScreenContent: { type: Object, default: null },
		currentScreenType: { type: String, default: null },
		slug: { type: String, default: null },
	});

	console.log("slug: ", props.slug)
	const emit = defineEmits(['goToStory']);
	const swiperRef = ref(null);
	const slides = ref(props.currentScreenContent.slides)

	// Create a Set to store unique categories
	const uniqueCategories = new Set();
	const uniqueNames = new Set();

	// Loop through each object in the array
	slides.value.forEach(item => {
		if (item.category) {
			uniqueCategories.add(item.category);
		}
	});

	// Loop through each object in the array
	slides.value.forEach(item => {
		if (item.title) {
			uniqueNames.add(item.title);
		}
	});

	const uniqueCategoriesArray = Array.from(uniqueCategories);
	const uniqueNamesArray = Array.from(uniqueNames);

	// Methods
	const goToStory = (newValue) => {
		emit('goToStory', newValue);
	}

	const handleReachEnd = (swiper) => {
      swiper.autoplay.stop();
    }

	const goToCategorySlide = (category) => {
		const slideIndex = slides.value.findIndex(slide => slide.category === category);
		if (swiperRef.value && slideIndex !== -1) {
			swiperRef.value.slideTo(slideIndex);
		}
	};

	const goToNameSlide = (name) => {
		const slideIndex = slides.value.findIndex(slide => slide.title === name);
		if (swiperRef.value && slideIndex !== -1) {
			swiperRef.value.slideTo(slideIndex);
		}
	};

	const activeIndex = ref(0);
	watch(swiperRef, (newSwiperRef) => {
		if (newSwiperRef) {
			newSwiperRef.on('slideChange', () => {
				activeIndex.value = newSwiperRef.activeIndex;
			});
		}
	});
</script>

<template>
	<div class="member-carousel-contain">
		<div class="member-carousel">
			<swiper
				class="mySwiper"
				:allowTouchMove="false"
				:autoplay="{ delay: 2000 }"
				:effect="'cards'"
				:loop="false"
				:modules="modules"
				:speed="1000"
				@reachEnd="handleReachEnd"
				@swiper="swiperRef = $event"
			>
				<swiper-slide v-for="(slide, index) in slides" :key="index">
					<button v-if="slide.title" class="img-contain" @touchstart="goToStory(slide)">
						<img :src="slide.image" alt="">
						<div class="copy" v-if="slide.title">
							<div v-html="slide.category" class="overline"></div>
							<p v-html="slide.title" class="title"></p>
							<div class="link"><span class="text">Read their story</span> <span class="arrow">→</span></div>
						</div>
					</button>
					<div v-else class="img-contain">
						<img :src="slide.image" alt="">
					</div>
					<div class="content" v-if="currentScreenType === 'carousel-screen'" v-html="slide.copy"></div>
				</swiper-slide>
			</swiper>
			<div v-if="slug === 'dupaco-crew-stories'">
				<div v-if="uniqueNamesArray.length" class="categories">
					<button v-for="(name, index) in uniqueNamesArray" :key="index" class="category" :class="{ 'active': index === activeIndex }" v-html="name" @touchstart="goToNameSlide(name)"></button>
				</div>
			</div>
			<div v-else>
				<div v-if="uniqueCategoriesArray.length" class="categories">
					<button v-for="(category, index) in uniqueCategoriesArray" :key="index"
					:class="{ 'active': index === activeIndex }" class="category" v-html="category" @touchstart="goToCategorySlide(category)"></button>
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="scss">
.member-carousel-contain {
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	width: 80vw;
	.swiper-slide {
		overflow: visible;
		aspect-ratio: 16 / 9;
		.swiper-slide-shadow {
			background: none !important;
		}
		.img-contain {
			border-radius: 0.6em;
			padding: 1em;
			background: var(--white);
			box-shadow: 0 5px 40px 20px rgba(#e87502, 0.15);
			border: 0;
			height: 100%;
			width: 100%;
			img {
				display: flex;
				height: 100%;
				width: 100%;
				object-fit: cover;
				max-width: none;
			}
			&:has(.copy) {
				&:after {
					position: absolute;
					content: "";
					inset: 1em;
					background: linear-gradient(25deg, rgba(#e87502, 1) 0%, rgba(#e87502, 0) 70%);
				}
			}
			.copy {
				position: absolute;
				z-index: 1;
				bottom: 0;
				left: 0;
				padding: 0 7.5% 7.5% 7.5%;
				text-align: left;
				color: var(--white);
				.overline {
					font-family: var(--newsgothic);
					font-size: var(--xl);
					text-transform: uppercase;
				}
				.title {
					font-family: var(--robotoCondensed);
					font-size: var(--4xl);
					font-weight: 600;
					margin: 0.2em 0 0.4em 0;
					line-height: 1;
				}
				.link {
					font-family: var(--robotoCondensed);
					font-weight: 600;
					font-size: var(--md);
					background: none;
					border: 0;
					color: var(--white);
					text-transform: uppercase;
					.text {
						text-decoration: underline;
						text-decoration-thickness: 0.1em;
						text-underline-offset: 0.3em;
					}
				}
			}
		}
		&.swiper-slide-active {
			p {
				opacity: 1;
			}
		}
		p {
			font-family: var(--robotoCondensed);
			font-weight: 300;
			transition: 0.3s;
			margin: 2vh 0 0 0;
			opacity: 0;
			font-size: var(--5xl);
			letter-spacing: -0.03em;
			strong {
				font-weight: 400;
			}
		}
	}
	.categories {
		display: flex;
		justify-content: center;
		gap: 2.5%;
		margin-top: 3vh;
		button {
			font-family: var(--newsgothic);
			font-size: var(--md);
			border: 3px solid var(--mango);
			background: var(--white);
			padding: 0.9em 2em 0.8em 2em;
			border-radius: 0.6em;
			text-transform: uppercase;
			&.active {
				background: var(--mango);
				color: var(--white);
			}
		}
	}
}
</style>