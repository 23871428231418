<script setup>
import { ref, defineProps, watch, onMounted, onUnmounted  } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/thumbs';
import {usePreviousScreenStore } from '@/stores';

const previousScreenStore = usePreviousScreenStore();
const props = defineProps({ currentScreen: { type: Object, default: null } });
const modules = [Autoplay, Thumbs];
const mainSwiperRef = ref(null);
const thumbsSwiperRef = ref(null);
const logoMeaningsSwiperRef = ref(null);
const evolutions = ref(props.currentScreen.logo_evolution);
const logoMeanings = ref(props.currentScreen.logo_meaning);
const activeIndex = ref(0);

// Methods
const handleReachEnd = (swiper) => {
  swiper.autoplay.stop();
};

const onMainSwiperInit = (swiperInstance) => {
  mainSwiperRef.value = swiperInstance;
};

const onLogoMeaningsSwiperInit = (swiperInstance) => {
  logoMeaningsSwiperRef.value = swiperInstance;
};

const onSlideChange = (swiper) => {
  activeIndex.value = swiper.realIndex;
};

const goToSlide = (index) => {
  if (logoMeaningsSwiperRef.value) {
    logoMeaningsSwiperRef.value.slideToLoop(index, 1000);
  }
};

watch(thumbsSwiperRef, (newVal) => {
  if (newVal && mainSwiperRef.value) {
    mainSwiperRef.value.thumbs.swiper = newVal;
    mainSwiperRef.value.thumbs.init();
    mainSwiperRef.value.thumbs.update();
  }
});

onMounted(() => {
		previousScreenStore.setShowBackButton(true)
	})

onUnmounted(() => {
  previousScreenStore.setShowBackButton(false)
})


</script>

<template>
  <div class="logo-evolution">
    <div class="headline" v-html="currentScreen.headline"></div>
    <div class="contain">
      <div class="container">
        <div class="box">
          <div class="heading">Dupaco Logo Evolution</div>
          <swiper
            class="mySwiper"
            :allowTouchMove="false"
            :autoplay="{ delay: 5000 }"
            :loop="true"
            :modules="modules"
            :thumbs="{ swiper: thumbsSwiperRef }"
            :speed="1000"
            @reachEnd="handleReachEnd"
            @swiper="onMainSwiperInit"
          >
            <swiper-slide v-for="(slide, index) in evolutions" :key="index">
              <img :src="slide.logo" alt="">
              <div v-html="slide.copy" class="title"></div>
            </swiper-slide>
          </swiper>
          <swiper
            class="thumbs-swiper"
            :loop="true"
            :modules="[Thumbs]"
            :spaceBetween="10"
            :slidesPerView="evolutions.length"
            :speed="1000"
            @swiper="thumbsSwiperRef = $event"
          >
            <swiper-slide v-for="(slide, index) in evolutions" :key="index">
              <img :src="slide.logo" alt="">
            </swiper-slide>
          </swiper>
        </div>
        <div class="box">
          <div class="heading">Dupaco Logo Meaning</div>
          <swiper
            class="mySwiper"
            :allowTouchMove="false"
            :autoplay="{ delay: 7000 }"
            :loop="true"
            :modules="modules"
            :speed="1000"
            @reachEnd="handleReachEnd"
			@swiper="onLogoMeaningsSwiperInit"
            @slideChange="onSlideChange"
          >
            <swiper-slide v-for="(slide, index) in logoMeanings" :key="index">
              <img :src="slide.logo" alt="">
              <div v-html="slide.name" class="title"></div>
              <div v-html="slide.copy" class="copy"></div>
            </swiper-slide>
			<div class="evolutions">
				<button v-for="(slide, index) in logoMeanings" :key="index" v-html="slide.name" :class="{ active: index === activeIndex }" @touchstart="goToSlide(index)"></button>
			</div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.logo-evolution {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  .headline {
    font-family: var(--robotoCondensed);
    font-size: var(--3xl);
    font-weight: 300;
    strong {
      font-family: var(--robotoCondensed);
      font-weight: 400;
      color: var(--mango);
    }
  }
  .contain {
    position: relative;
    width: 100vw;

    &:after {
      position: absolute;
      content: "";
      inset: 0;
      background: linear-gradient(0deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.6) 100%);
    }
  }
  .container {
    display: flex;
    width: 90vw;
    margin: 0 auto;
    .box {
      position: relative;
      z-index: 1;
      width: 50%;
      text-align: left;
      padding: 3em 2.5em;
      box-sizing: border-box;
      &:first-child {
        border-right: 0.3em solid rgba(#000, 20%);
      }
      .heading {
        font-family: var(--robotoCondensed);
        font-size: var(--lg);
        font-weight: 500;
        margin-bottom: 2.5%;
        color: var(--mango);
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 15%;
      }
      .mySwiper {
        display: flex;
        flex-direction: column;
        width: 100%;
        .swiper-slide {
          display: flex;
          flex-direction: column;
          text-align: center;
          width: 100% !important;
          img {
            object-fit: contain;
            height: 10vh;
            width: 80%;
			      margin: 0 auto;
            margin-bottom: 2vh;
          }
          .title {
            font-family: var(--robotoCondensed);
            font-size: var(--lg);
            font-weight: 500;
            margin-bottom: 10%;
            line-height: 1;
          }
          .copy {
            font-family: var(--robotoCondensed);
            font-size: var(--md);
            color: #757575;
            line-height: 1.5em;
            margin-bottom: 2vh;
          }
        }
		.evolutions {
			// position: fixed;
			// bottom: 0;
			// right: -10%;
			display: flex;
      justify-content: center;
			gap: 1%;
			button {
				font-family: var(--newsgothic);
        font-size: var(--sm);
				background-color: var(--white);
				border: 2px solid var(--mango);
				color: var(--black);
				padding: 0.9em 1em 0.8em 1em;
				border-radius: 0.5em;
				line-height: 1;
				text-transform: uppercase;
				transition: 0.3s;
				&.active {
					background-color: var(--mango);
					color: var(--white);
				}
			}
		  }
      }
      .thumbs-swiper {
        .swiper-wrapper {
          flex-wrap: wrap;
          transform: translate3d(0px, 0px, 0px) !important;
        }
        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: calc(33.33% - 12px) !important;
          height: 5vh;
          &.swiper-slide-thumb-active {
			      box-shadow: inset 0 0 0 2px var(--mango);
            border-radius: 1em;
          }
          img {
            object-fit: contain;
            height: 100%;
            width: 100%;
            padding: 1em;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
</style>
