<script setup>
import { ref, computed, defineProps, onMounted, onUnmounted, watch, defineEmits } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Thumbs } from 'swiper/modules';
import { usePreviousScreenStore } from '@/stores';
import { useDisableTimeoutStore } from '@/stores';
import 'swiper/css';

const modules = [Autoplay, Thumbs];
const previousScreenStore = usePreviousScreenStore();
const disableTimeout = useDisableTimeoutStore();

const props = defineProps({
  currentScreen: { type: Object, default: null },
  handleLastGallerySlide: Function,
});

const emit = defineEmits(['handleDisableTimeoutForScreen'])

// Ref to the Swiper instances
const mainSwiperRef = ref(null);
const slideLength = ref(props.currentScreen.gallery.length - 1);
const thumbsSwiperRef = ref(null);
const thumbsSwiperRef2 = ref(null);
const activeIndex = ref(0);
const autoplayDelay = ref(8000);

// Use a computed property to ensure reactivity
const gallery = computed(() => props.currentScreen?.gallery ?? []);


const handleReachEnd = (swiper) => {
  if (swiper.autoplay && swiper.autoplay.stop) {
    swiper.autoplay.stop();
  }
};

// Handle Swiper initialization
const onMainSwiperInit = (swiperInstance) => {
  mainSwiperRef.value = swiperInstance;
  mainSwiperRef.value.on('slideChange', () => {
    activeIndex.value = swiperInstance.activeIndex;
  });
};

const onThumbsSwiperInit = (swiperInstance) => {
  thumbsSwiperRef2.value = swiperInstance;
};

watch(activeIndex, () => {
  if(activeIndex.value === slideLength.value) {
    disableTimeout.setDisableTimout(false)
    thumbsSwiperRef2.value.autoplay.stop()
  } else {
    mainSwiperRef.value.autoplay.start()
    thumbsSwiperRef2.value.autoplay.start()
  }
})

onMounted(() => {
  disableTimeout.setDisableTimout(true)
  previousScreenStore.setShowBackButton(true)
  if (thumbsSwiperRef2.value) {
    thumbsSwiperRef2.value.on('click', (swiper, event) => {
      const clickedIndex = swiper.clickedIndex;
      if (clickedIndex !== undefined) {
        swiper.slideTo(clickedIndex, 1000, true);
        thumbsSwiperRef2.value.autoplay.start()
        mainSwiperRef.value.autoplay.start()
      }
    });
  }
});

onUnmounted(() => {
  disableTimeout.setDisableTimout(false)
  previousScreenStore.setShowBackButton(false)
})

</script>

<template>
  <swiper
    class="thumbs-swiper"
    :allowTouchMove="false"
    :autoplay="{ delay: 8000 }"
    :centeredSlides="true"
    :slideToClickedSlide="true"
    :loop="true"
    :modules="modules"
    :slidesPerView="4"
    :spaceBetween="0"
    :speed="1000"
    @reachEnd="handleReachEnd"
    @swiper="onThumbsSwiperInit"
  >
    <swiper-slide v-for="(slide, index) in gallery" :key="index">
      <img :src="slide.image" alt="">
    </swiper-slide>
  </swiper>

  <swiper
    v-if="thumbsSwiperRef2"
    class="voices-swiper"
    ref="mainSwiperRef"
    :allowTouchMove="false"
    :autoplay="{ delay: 8000 }"
    :loop="false"
    :modules="modules"
    :slidesPerView="1"
    :speed="1000"
    :thumbs="{ swiper: thumbsSwiperRef2 }"
    @reachEnd="handleReachEnd"
    @swiper="onMainSwiperInit"
  >
    <swiper-slide v-for="slide in gallery" :key="slide">
      <img :src="slide.image" alt="">
      <div class="copy">
        <div class="title" v-html="slide.title"></div>
        <div class="content" v-html="slide.content"></div>
      </div>
    </swiper-slide>
  </swiper>
</template>



<style lang="scss" scoped>
  .voices-swiper {
    .swiper-slide {
      position: relative;
      &:after {
        position: absolute;
        content: "";
        inset: 0;
        background: var(--black);
        opacity: 0;
        transition: opacity 0.8s;
        transition-delay: 1.5s;
      }   
      &.swiper-slide-active {
        &:after {
          opacity: 0.65;
        }
        .copy {
          opacity: 1;
          transform: translate(-50%, -45%);
        }
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .copy {
        position: absolute;
        z-index: 1;
        top: 45%;
        left: 50%;
        min-height: 30vh;
        width: 70vw;
        margin: 0 auto;
        transform: translate(-50%, -45%);
        text-align: left;
        color: var(--white);
        opacity: 0;
        transition: opacity 0.8s;
        transition-delay: 1.5s;
        .title {
          font-family: var(--newsgothic);
          font-size: var(--6xl);
          margin-bottom: 1vh;
          line-height: 0.9em;
          p {
            margin-bottom: 5%;
          }
        }
        .content {
          font-size: var(--lg);
        }
      }
    }
  }

  .thumbs-swiper {
    position: fixed;
    z-index: 2;
    bottom: 28%;
    left: 0;
    height: 10%;
    width: 100vw;
    margin: 0 auto;
    overflow: visible;
    display: flex;
    justify-content: center; // Center the swiper slides
    .swiper-slide {
      position: relative;
      left: -5vw;
      border: 4px solid var(--mango);
      transition: transform 0.4s;
      transform: scale(0.75);
      margin: 0 !important;
      &:after {
        position: absolute;
        content: "";
        inset: 0;
        background: var(--black);
      }
      &.swiper-slide-active {
        transform: scale(1.1);
        img {
          opacity: 1;
        }
      }
      img {
        position: relative;
        z-index: 1;
        object-fit: cover;
        height: 100%;
        width: 100%;
        opacity: 0.8;
        transition: opacity 0.4s;
      }
    }
  }
</style>

<style lang="scss">
  .voices-swiper {
    p {
      font-family: var(--robotoCondensed);
      font-weight: 500;
      line-height: 1.5em;
      margin: 0;
    }
  }
</style>
