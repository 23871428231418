<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter, RouterView } from 'vue-router';
import { locationStore, useAnimationStore, useHomeStore, usePreviousScreenStore } from '@/stores';
import lottie from 'lottie-web';
import screenTransitionAnimationData from '@/assets/lottie/screen-transition-alt.json';
import { useHomeIconStoreForSlideshowExp } from '@/stores';

const store = locationStore();
const homeStore = useHomeStore();
const previousScreenStore = usePreviousScreenStore();
const homeIconStoreForSlideshowExp = useHomeIconStoreForSlideshowExp();
const router = useRouter();
store.homescreen = ref(true);
const loc = window.location.pathname.split('/')[1]
const games = ref([])
const showIcons = ref(true)
const animationStore = useAnimationStore();
const isAnimating = ref(false); // Declare isAnimating only once
const animationInstance = ref();

// Show the home icon in the nav for the slideshow experience
const showHomeIconForSlideshowHome = computed(() => homeIconStoreForSlideshowExp.showHomeIcon);

const triggerAnimation = () => {
	isAnimating.value = true;
	animationInstance.value.goToAndPlay(0, true);

	setTimeout(() => {
		isAnimating.value = false;
		animationStore.triggerAnimation(false);
	}, 2000); // Adjust this duration as needed
};

// if user enters a game and finishes the game
// Once the screen returns to the home page, show the app navigation icons
watch(() => router.currentRoute.value,
	(newValue) => {
	const pathLength = newValue.path.split("/").length
	if(pathLength == 2) {
		showIcons.value = true
	}
	}
);

watch(() => animationStore.shouldTriggerAnimation, (newValue) => {
	if (newValue) {
		triggerAnimation();
	}
});

onMounted(() => {
	// Initialize Lottie animation instance
	animationInstance.value = lottie.loadAnimation({
		container: document.getElementById('screenTransition'),
		renderer: 'svg',
		loop: false,
		autoplay: false, // Animation will not play immediately
		animationData: screenTransitionAnimationData,
	});

	(async () => {
		await store.init(); // Make sure the store is initialized
		location.value = store.data.slug
		const experiences = store.data.games.filter(game => game.home_screen.length > 1)
		experiences.forEach(game => {
			games.value.push(game)
		}) // Now this should log the fetched data, not null
	})();

	// If the reboot value from the settings channel is toggled on, reboot the screen
	setInterval(() => {

		fetch(`https://dupaco.giganticdesign.com/api/location/reboot/${loc}`)
			.then(response => response.text()) // Use text() instead of json() for debugging
			.then(text => {
				// Once you've confirmed the response is indeed JSON, parse it manually:
				const data = JSON.parse(text);
				const reboot = data.reboot
				if (reboot) {
					window.location = `https://dupaco.giganticdesign.com/api/location/reboot-form/${loc}`
				}
			})
			.catch(error => {
				console.error('Error:', error);
			});

	}, 10000)
});

const goToHomeGame = (gameSlug) => {
	var index = store.data.games.findIndex(game => game.slug === gameSlug)
  	homeStore.setActiveIndex(index); // Set the active index in the store
	homeStore.setHomeSlug(gameSlug)
}

const goHome = () => {
	showIcons.value = true
	router.push(`/${location.value}`)
}

const goToSlideshowHome = () => {
	homeStore.setActiveIndex(0); // Set the active index in the store
}

const rebootApp = () => {
	const location = window.location.pathname.split('/')[1]
	window.location = `https://dupaco.giganticdesign.com/api/location/reboot-form/${location}`
}

</script>

<template>
	<div class="app-container">
		<RouterView />
		<div id="screenTransition"></div>
		<button class="reset-btn" @click="rebootApp"></button>
		<div class="version">v3.0hl</div>
	</div>
	<div class="nav">
		<div v-if="homeStore.showIcons && !showHomeIconForSlideshowHome">
			<button @click="goToHomeGame(game.slug)" v-for="game in games" :key="game.slug" :class="{'active':homeStore.activeSlug === game.slug}">
				<img :src="game.icon">
			</button>
		</div>
		<div v-else-if="showHomeIconForSlideshowHome === false">
			<button v-if="previousScreenStore.showBackButton" @click="previousScreenStore.setShowPreviousScreen()">
				<img src="./assets/img/back-icon.svg" class="back-arrow" alt="Go back a screen">
			</button>
			<button @click="goHome()">
				<img src="./assets/img/close-icon.svg" class="close-icon" alt="Back to Home">
			</button>
		</div>
		<div v-if="showHomeIconForSlideshowHome">
			<button @click="goToSlideshowHome()">
				<img src="./assets/img/home-icon-2.svg" class="home-icon">
			</button>
		</div>
	</div>
</template>

<style lang="scss">
@import '@/assets/css/variables.css';
@import '@/assets/css/fonts.css';

html,
body {
	background-color: var(--black);
	overflow: hidden;
	margin: 0;
	padding: 0;
	font-size: var(--xs)
}

.nav {
	position: fixed;
	z-index: 26;
	top: 50%;
	left: 1.5%;
	padding: 1.5%;
	transform: translateY(-50%);
	background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
	backdrop-filter: blur(10px);
	border-radius: 1em;
	display: grid;
	grid-template-columns: 1fr;
	gap: 1em;
	width: 4rem;

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1em;
		width: 100%;

		button {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
			cursor: pointer;
			border: 0;
			background: var(--white);
			border-radius: 1em;
			padding: 0.3em;
			aspect-ratio: 1/1;
			width: 100%;
			border: 0 solid var(--orange);
			transition: .5s ease-in-out;
			--shadow-color: 0deg 0% 63%;
			box-shadow: 0px 0.4px 0.5px hsl(var(--shadow-color) / 0.36),
						0px 1.4px 1.6px -0.8px hsl(var(--shadow-color) / 0.36),
						0.1px 3.5px 3.9px -1.7px hsl(var(--shadow-color) / 0.36),
						0.3px 8.6px 9.7px -2.5px hsl(var(--shadow-color) / 0.36);

			&.active {
				border: 2px solid var(--orange);
				transform: scale(1.2);
			}

			img {
				height: 100%;
				width: 100%;
				object-fit: contain;
				&.close-icon {
					height: 85%;
					width: 85%;
				}
				&.back-arrow {
					position: relative;
					top: 0.08em;
				}
				&.home-icon {
					position: relative;
					height: 85%;
					width: 85%;
				}
			}
		}
	}
}


#confetti {
	position: absolute;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	/* In case the inner content overflows */
	background-color: var(--black);
	/* Just for visualization */
}

img {
	display: block;
}

.app-container {
	position: relative;
	/* Set the height to be the minimum of 100% of the viewport height or 16/9 of the viewport width */
	height: min(100vh, calc(100vw * 16 / 9));

	/* Set the width based on the height to maintain a 9/16 aspect ratio */
	width: calc(min(100vh, calc(100vw * 16 / 9)) * 9 / 16);

	background-color: var(--black);
	/* Just for visualization */

	.reset-btn {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1000;
		height: 10vh;
		width: 20vw;
		background: transparent;
		border: 0;
	}

	.version {
		position: fixed;
		bottom: 1.5%;
		right: 1.5%;
		z-index: 1000;
		line-height: 1;
		background: transparent;
		font-family: var(--FormulaExtendedBold);
		font-size: var(--xs);
		color: var(--black);
	}
}

.background {
	&.screen-question {
		video {
			opacity: .2;
		}
	}

	video {
		position: absolute;
		inset: 0;
		height: 100%;
		width: 100%;
		opacity: 0.5;
		background-size: cover;
		mix-blend-mode: multiply;
	}
}

.intro-content {
	color: var(--white);
	font-size: var(--5xl);
	line-height: 1.2;
	font-family: var(--FormulaMedium);
	padding-bottom: 1em;
}

.fadeup-enter-active {
	transition: all .75s ease-out .25s;
}

.fadeup-leave-active {
	transition: all .75s ease-in;
}

.fadeup-enter-from {
	transform: translate3d(0px, 50px, 0);
	opacity: 0;
}

.fadeup-leave-to {
	transform: translate3d(0px, -50px, 0);
	opacity: 0;
}

// Delay
.fadeupdelay-enter-active {
	transition: all .75s ease-out .5s;
}

.fadeupdelay-leave-active {
	transition: all .75s ease-in;
}

.fadeupdelay-enter-from {
	transform: translate3d(0px, 50px, 0);
	opacity: 0;
}

.fadeupdelay-leave-to {
	transform: translate3d(0px, -50px, 0);
	opacity: 0;
}

// Countdown
.countdown-enter-active {
	transition: all .10s ease-out;
}

.countdown-leave-active {
	transition: all .10s ease-in;
}

.countdown-enter-from {
	transform: translate3d(0px, 50px, 0);
	opacity: 0;
}

.countdown-leave-to {
	transform: translate3d(0px, -50px, 0);
	opacity: 0;
}

// Heavy animated speed game animations
.fadeupintro-enter-active {
	transition: all .75s ease-out;
}

.fadeupintro-leave-active {
	transition: all .75s ease-out;
}

.fadeupintro-enter-from {
	transform: translate3d(0px, 25px, 0);
	opacity: 0;
}

.fadeupintro-leave-to {
	transform: translate3d(0px, -25px, 0);
	opacity: 0;
}

// Home screen
.fadex-enter-active {
	transition: all .20s ease-out .20s;
}

.fadex-leave-active {
	transition: all .20s ease-in;
}

.fadex-enter-from {
	transform: translate3d(-50px, 0, 0);
	opacity: 0;
}

.fadex-leave-to {
	transform: translate3d(50px, 0, 0);
	opacity: 0;
}

.wheel-enter-active {
	transition: all .75s ease-out .25s;
}

.wheel-leave-active {
	transition: all .75s ease-in;
}

.wheel-enter-from {
	opacity: 0;
	transform: scaleY(0.9) rotateX(-40deg) translateY(50%);
}

.wheel-leave-to {
	opacity: 0;
	transform: scaleY(0.9) rotateX(40deg) translateY(-50%);
}

.user-answered p {
	margin: 2% 0;
}

#screenTransition {
	position: fixed;
	inset: 0;
	z-index: 25;
	pointer-events: none;
}
</style>
