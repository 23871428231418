<script setup>
import { ref, defineProps, defineEmits, onMounted } from "vue";
import QuestionText from "@/components/QuestionText"
import anime from "animejs"

const emit = defineEmits([
	'updateAnswerSelected',
	'updateTotalScoreAndAnswerMessage',
	'updateNextScreen',
	'updateAnswerResponse',
	'updateCurrentQuestionCount'

])

const props = defineProps({
	count: { type: String, default: '01' },
	formattedCount: { type: String, default: '01' },
	question: { type: Object, default: null },
	correctAnswer: { type: Boolean, default: null },
	showAnswerResponse: { type: Boolean, default: false },
});

const buttonsDisabled = ref(false)
const isAnswerSelected = ref(false)
const userAnswered = ref(null)

const updateAnswerSelected = (newValue) => { emit('updateAnswerSelected', newValue); }
const updateTotalScoreAndAnswerMessage = (answer) => { emit('updateTotalScoreAndAnswerMessage', answer); };
const updateNextScreen = () => { emit('updateNextScreen', true) }
const updateCurrentQuestionCount = (newValue) => { emit('updateCurrentQuestionCount', newValue); }

const selectAnswer = (answer, event) => {

	if (buttonsDisabled.value) {
		event.preventDefault()
		return
	}

	updateAnswerSelected(true)

	// Disable further answers
	buttonsDisabled.value = true

	const originalButton = event.currentTarget;
	const originalButtonStyles = window.getComputedStyle(originalButton)

	// Clone the button
	const clonedContainer = document.querySelector('.button')
	const clonedButton = originalButton.cloneNode(true);
	const rect = originalButton.getBoundingClientRect();
	document.querySelector('.cloned-answer .button').prepend(clonedButton);

	// Style the clone to overlay it exactly over the original
	clonedContainer.style.position = 'absolute';
	clonedContainer.style.left = `${rect.left}px`;
	clonedContainer.style.top = `${rect.top}px`;
	clonedButton.style.width = `${rect.width}px`;
	clonedContainer.style.height = `${rect.height}px`;
	clonedButton.style.backgroundColor = originalButtonStyles.backgroundColor;
	clonedContainer.style.transition = 'transform 0.3s ease-in-out, top 0.3s ease, left 0.3s ease, opacity .3s ease';
	clonedContainer.style.zIndex = '1000';

	// Animate the clone to the center
	const centerX = window.innerWidth / 2 - rect.left - rect.width / 2;
	const centerY = window.innerHeight / 2 - rect.top - rect.height / 2;

	requestAnimationFrame(() => {
		document.querySelector('.answers').style.opacity = 0;
		document.querySelector('.question').style.opacity = 0;
		document.querySelector('.question-count').style.opacity = 0;

		clonedButton.classList.add('clonedButtonClass');
		clonedContainer.style.transform = `translate3d(${centerX}px, ${centerY}px, 0) scale3d(1.5,1.5,1)`;

		clonedContainer.addEventListener('transitionend', function handleTransitionEnd(event) {
			if (event.propertyName === 'transform') {
				answerMessageEnter(answer)
			}
		});
	});
};


const answerMessageEnter = (answer) => {
	const tl = anime.timeline({
		complete: () => {
			updateNextScreen()
			updateCurrentQuestionCount(1)
		}
	})
	tl.add({
		targets: '.cloned-answer',
		opacity: [1, 0],
		translateY: [0, -10],
		duration: 300,
		easing: 'easeInQuad',
		delay: 1000,
	}).add({
		targets: '.user-answered',
		opacity: [0, 1],
		translateY: ['-25%', '-50%'],
		translateX: ['-50%', '-50%'],
		duration: 700,
		easing: 'easeOutQuad',
		begin: () => {
			updateTotalScoreAndAnswerMessage(answer)
		},
	}).add({
		targets: '.user-answered',
		opacity: [1, 0],
		duration: 1000,
		easing: 'easeInQuad',
		delay: 1000,
	})
};

onMounted(() => {
	document.querySelector('.user-answered').style.opacity = 0;
})

</script>

<template>
	<div class="question-container">
		<Transition name="Question">
			<div class="question-content">
				<div class="question-count" :class="{ 'hide': isAnswerSelected }">
					<span class="bg"></span>
					<span class="content">Question <span v-text="props.formattedCount" class="count"></span></span>
				</div>
				<QuestionText :text="question.question" />
				<div class="answers">
					<button v-for="(answer, index) in question.answers" :key="index" :disabled="buttonsDisabled"
						@touchstart="selectAnswer(answer, $event)">
						<img v-if="index == 0" src="../../assets/img/correct-answer.svg" class="true-false-icon"
							alt="Correct answer icon">
						<img v-else src="../../assets/img/incorrect-answer.svg" class="true-false-icon"
							alt="Incorrect answer icon">
						<div class="answer-label">{{ answer.answer }}</div>
						<div class="answer-period">{{ answer.time_period }}</div>
					</button>
				</div>
				<div class="no-answer" v-if="userAnswered == false">
					<p class="time-ran-out">Time ran out</p>
				</div>
				<div class="user-answered" v-else>
					<div v-if="correctAnswer == true" v-html="question.answer_response" class="response"></div>
					<div v-if="correctAnswer == false" v-html="question.incorrect_answer_response" class="response small">
					</div>
				</div>
			</div>
		</Transition>
		<div class="cloned-answer">
			<div class="button"></div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.Question-enter-active,
.Question-leave-active {
	transition: opacity 2s ease;

	.question-count .content {
		transition: .20s ease 1s;
	}

	.question-count .bg {
		transition: .25s cubic-bezier(.43, .52, .03, .99) .85s;
	}

	.question-count {
		transition: .25s ease-out .55s;
	}

	.question {
		transition: .5s ease .5s;
	}

	.answers {
		button {
			transition: 1s ease;

			&:nth-child(1) {
				transition-delay: .15s;
			}

			&:nth-child(2) {
				transition-delay: .25s;
			}

			&:nth-child(3) {
				transition-delay: .40s;
			}

			&:nth-child(4) {
				transition-delay: .60s;
			}
		}
	}
}

.Question-enter-from,
.Question-leave-to {
	.question-count .content {
		transform: translate3d(0, 50%, 0);
		opacity: 0;
	}

	.question-count .bg {
		width: 4.3rem;
	}

	.question-count {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	.question {
		opacity: 0;
		transform: translate3d(0, 5%, 0);
	}

	.answers {
		button {
			opacity: 0;
			transform: translate3d(0, 5%, 0);
		}
	}
}

.question-container {
	position: fixed;
	top: 15%;
	max-width: 90%;
	margin: 0 auto;
}

.question-count {
	position: relative;
	font-family: var(--sculpin);
	font-size: var(--lg);
	border-radius: 2em;
	color: var(--solidYellow);
	text-transform: uppercase;
	display: inline-block;
	padding: .6em 2em .5em;
	margin-bottom: 2em;

	&.hide {
		opacity: 0;
	}

	span {
		display: inline-block;
	}

	span.bg {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		height: 3em;
		max-height: 100%;
		width: 100%;
		background-color: var(--asphalt);
		border-radius: 2em;
		border: 1px solid var(--white);
	}

	span.content {
		position: relative;
	}

	span.count {
		position: relative;
		font-family: var(--ptmono);
		padding-left: .8em;

		&:before {
			position: absolute;
			content: "";
			top: 50%;
			left: 0.2em;
			transform: translateY(-50%);
			height: 50%;
			width: 0.1em;
			background: var(--white);
		}
	}
}

.question {
	font-family: var(--sculpin);
	font-style: italic;
	text-transform: uppercase;
	color: var(--white);
	line-height: 1.1;
	width: 100%;
	max-width: 75%;
	margin: 0 auto 1.5em auto;
	overflow: hidden;
}

.question-content {
	position: fixed;
	top: 32%;
}

.question-content .answers {
	position: relative;
	display: grid;
	place-items: center;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	gap: 2%;
	max-width: 70%;
	margin: 0 auto
}

.user-answered {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	.response {
		font-family: var(--sculpin);
		font-size: var(--5xl);
		font-style: italic;
		color: var(--white);
		text-transform: uppercase;

		// incorrect answer
		&.small {
			font-size: var(--2xl);
		}
	}
}


.cloned-answer {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;

	button {
		transition: .5s opacity ease-in-out;

		&::before {
			display: none;
		}
	}
}

button {
	position: relative;
	border: none;
	outline: none;
	border-radius: 7.5%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	aspect-ratio: 1/1;
	color: var(--white);
	transition: transform 0.3s ease-in-out, position 0s 0.3s;
	font-family: var(--FormulaExtraBold);

	.true-false-icon {
		height: 3.8em;
		width: 3.8em;
		margin-bottom: 2em;
	}

	.answer-label {
		font-size: var(--4xl);
		font-family: var(--FormulaExtraBold);
		color: var(--black);
		text-transform: capitalize;
	}
}
</style>