<script setup>
import { ref, computed, watch, defineProps, onMounted, defineEmits, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { locationStore } from '@/stores'
import AppIcon from '@/components/high-low/AppIcon'
import anime from "animejs"
import DupacoLogo from '@/components/DupacoLogo.vue'

const store = locationStore()
const router = useRouter()
const props = defineProps({
	game: { type: Object, default: null },
	isLast: { type: Boolean, default: false }
})

const dupacoTextColor = ref('white')
const dupacoShineColor = ref('mango')
const currentScreenIndex = ref(0)
const currentScreenKey = computed(() => `screen-${currentScreenIndex.value}`);

const currentScreen = computed(() => {
	// Compute the current screen based on the current index
	if (props.game && props.game.home_screen && props.game.home_screen.length > currentScreenIndex.value) {
		return props.game.home_screen[currentScreenIndex.value];
	}
	return null;  // Return null if the index is out of bounds
});

const currentScreenType = computed(() => {
	// Compute a CSS class for the current screen type
	return currentScreen.value ? `screen-${currentScreen.value.type}` : 'default-screen-type';
});

// Watch for changes in the current screen index
watch(currentScreenIndex, () => {
	// Set timer and adjust UI for intro/outro screens
	if (currentScreen.value && currentScreen.value.type !== 'home_cta') {
		setScreenTimer(currentScreen.value.duration);
	} else {
		setScreenTimer(10);
	}
	if(currentScreenIndex.value === 2) {
		const tl = anime.timeline({
			easing: 'easeOutQuad',
		})
		tl.add({
			targets: '.high-low-svg',
			duration: 500,
			translateX: ['-50%', '-50%'],
			opacity: [0, 1],
			delay: 1500
		})
		tl.add({
			targets: '.high-svg',
			duration: 400,
			translateY: ['100%', '0%'],
		})
		tl.add({
			targets: '.low-svg',
			duration: 400,
			translateY: ['-100%', '0%'],
		}, '-=400')
		tl.add({
			targets: '.high-svg',
			duration: 400,
			easing: 'easeInQuad',
			translateY: ['0%', '-100%'],
			delay: 1500
		})
		tl.add({
			targets: '.low-svg',
			duration: 400,
			easing: 'easeInQuad',
			translateY: ['0%', '100%'],
		}, '-=400')
	}
});

// Timer for screen transitions
let screenTimer = null;

// Function to set a timer for screen transitions
const setScreenTimer = (duration) => {
	clearScreenTimer();
	screenTimer = setTimeout(() => {
		goToNextScreen();
	}, duration * 1000); // Convert duration to milliseconds
};

// Function to clear the screen transition timer
const clearScreenTimer = () => {
	if (screenTimer) {
		clearTimeout(screenTimer);
		screenTimer = null;
	}
};

const goToNextScreen = () => {
	if (currentScreenIndex.value < (props.game.home_screen.length - 1)) {
		currentScreenIndex.value++;
	} else {
		clearScreenTimer(); // Clear timer at the end of the screens
		// Animate Sceen Out
		if (!props.isLast) {
			setTimeout(() => {
				const tl = anime.timeline({
					easing: 'easeInQuad',
					complete: () => {
						updateNextScreen();
					}
				})
					.add({
						targets: '.dupaco-logo',
						translateX: ['-50%', '-50%'],
						translateY: [0, '-50%'],
						opacity: [1, 0],
						duration: 500,
					}, 100)
				tl.add({
					targets: '.screen-container',
					duration: 500,
					translateY: [0, '-25%'],
					opacity: [1, 0]
				}, 250)
					.add({
						targets: '.background',
						translateY: [0, 0],
						scale: [1, .9],
						borderRadius: [0, '50px'],
						duration: 500,
						easing: 'easeInOutQuad'
					}, 600)
					.add({
						targets: '.background',
						translateY: [0, '-20%'],
						opacity: [1, 0],
						duration: 500,
					}, 1500)
			}, 1000);
		} else {
			updateNextScreen();
		}
	}
};

const onEnter = () => {
	document.querySelector('.background').style.borderRadius = '50px';
	document.querySelector('.background').style.transform = 'scale(.9) translateY(0)';
	const tl = anime.timeline({
		easing: 'easeOutQuad',
		complete: () => {
			setScreenTimer(currentScreen.value.duration)
		}
	})
	tl.add({
		targets: '.background',
		translateY: ['20%', 0],
		opacity: [0, 1],
		duration: 500,
	})
		.add({
			targets: '.background',
			translateY: [0, 0],
			scale: [.9, 1],
			borderRadius: ['50px', 0],
			duration: 500,
			easing: 'easeInOutQuad'
		}, 1000)
		.add({
			targets: '.dupaco-logo',
			translateX: ['-50%', '-50%'],
			translateY: ['100%', 0],
			opacity: [0, 1],
			duration: 500,
		}, 1100)
		.add({
			targets: '.launch-quiz div:not(.high-low-svg)',
			duration: 500,
			translateY: ['40px', '20px'],
			opacity: [0, 1],
			delay: 1000
		}, 1150)
}

const emit = defineEmits([
	'updateNextScreen'
])

const updateNextScreen = () => { emit('updateNextScreen', true) }

const goToGame = (slug) => {
	const tl = anime.timeline({
		easing: 'easeInQuad',
		complete: () => {
			router.push({ path: `/${store.data.slug}/game/${slug}` })
		}
	})
	tl.add({
		targets: '.launch-quiz > div',
		scale: [1, .75],
		opacity: [1, 0],
		duration: 350
	})
		.add({
			targets: '.dupaco-logo',
			translateX: ['-50%', '-50%'],
			translateY: [0, '-50%'],
			opacity: [1, 0],
			duration: 500,
		}, 100)
		.add({
			targets: '.screen-container',
			duration: 500,
			translateY: [0, '-25%'],
			opacity: [1, 0]
		}, 450)
		.add({
			targets: '.background',
			translateY: [0, 0],
			scale: [1, .9],
			borderRadius: [0, '50px'],
			duration: 500,
			easing: 'easeInOutQuad'
		}, 800)

}

onMounted(() => {
	onEnter()
})

onUnmounted(() => {
	clearScreenTimer();
})

</script>

<template>
	<div class="background">
		<img src="../../assets/img/high-low-bg-texture.png" class="bg-texture" alt="">
	</div>
	<div class="dupaco-logo" :class="currentScreenType">
		<DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor" />
	</div>
	<div class="screens">
		<Transition name="fadex" mode="out-in">
			<div :key="currentScreenKey">
				<div class="screen-container" v-if="currentScreen?.type === 'start'">
					<AppIcon :icon="props.game?.icon" :label="props.game?.title" :screen="currentScreenType" game="high-low" />
				</div>
				<div class="screen-container" v-else-if="currentScreen?.type === 'intro'">
					<div class="intro-content" v-html="currentScreen?.content"></div>
				</div>
				<div class="screen-container" v-else>
					<div class="cta-content">
						<div class="box" :class="currentScreen.type">
							<AppIcon :icon="props.game?.icon" :label="props.game?.title" :screen="currentScreenType" game="high-low" />
							<button @touchstart="goToGame(game.slug)" class="launch-quiz">LAUNCH GAME ▶</button>
						</div>
						<div v-if="currentScreen?.type === 'home_cta'" class="copy" v-html="currentScreen?.content"></div>
					</div>
				</div>
			</div>
		</Transition>
		<Transition name="fadeup" mode="out-in">
			<div class="launch-quiz always" v-if="currentScreen?.type !== 'home_cta'">
				<div>
					<button @touchstart="goToGame(game.slug)">LAUNCH GAME ▶</button>
					<p>Touch to get started</p>
				</div>
			</div>
		</Transition>
		<div class="high-low-svg" :class="currentScreen.type">
			<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 521.97 543.82">
				<g class="high-svg">
					<path d="M56.15,152.35v168.04c0,23.28,18.87,42.16,42.16,42.16h150.25s0-210.2,0-210.2h56.14S152.34,0,152.34,0L0,152.35h56.15Z" fill="#4da9e7"/>
					<g>
					<path d="M149.81,140.98v-7.47h-47.12v-25h114.92v25h-47.12v7.47h47.12v24.99h-114.92v-24.99h47.12Z" fill="#fff"/>
					<path d="M196.07,198.44h-93.37v-24.13h93.37v24.13ZM219.05,186.38c0,9.77-3.73,13.65-9.48,13.65s-9.19-3.88-9.19-13.65,3.73-13.65,9.19-13.65c5.75,0,9.48,3.88,9.48,13.65Z" fill="#fff"/>
					<path d="M113.33,238.09c-6.03-2.87-12.07-7.33-12.07-16.81,0-10.34,7.47-15.37,22.98-15.37h52.86c10.63,0,20.4,3.16,20.4,15.51,0,8.04-3.73,12.07-8.76,16.66h7.33v24.13h-91.79c-22.41,0-30.31-3.59-30.31-24.42v-26.72h20.83v20.11c0,4.02.43,6.9,9.34,6.9h9.19ZM121.66,234.35c0,3.02,2.01,3.74,5.89,3.74h43.81c3.88,0,5.89-.72,5.89-3.74s-2.15-3.73-6.03-3.73h-43.52c-3.88,0-6.03.72-6.03,3.73Z" fill="#fff"/>
					<path d="M185.58,294.26c5.17,2.15,11.92,7.33,11.92,16.38,0,13.36-11.64,15.08-25.57,15.08h-69.24v-23.99h67.51c5.17,0,7.18-.58,7.18-3.74s-2.01-3.73-7.18-3.73h-67.51v-24.13h114.92v24.13h-32.03Z" fill="#fff"/>
					</g>
				</g>
				<g class="low-svg">
					<path d="M465.83,391.47v-168.04c0-23.28-18.87-42.16-42.16-42.16h-150.25s0,210.2,0,210.2h-56.14s152.35,152.35,152.35,152.35l152.34-152.35h-56.15Z" fill="#d72e85"/>
					<g>
					<path d="M306.82,438.98v-25h94.09v-21.55h20.83v46.54h-114.92Z" fill="#fff"/>
					<path d="M389.7,389.13h-29.3c-24.13,0-33.47-10.2-33.47-28.44s9.34-28.44,33.47-28.44h29.3c24.13,0,33.47,10.2,33.47,28.44s-9.34,28.44-33.47,28.44ZM403.06,360.69c0-3.16-2.01-3.73-7.18-3.73h-41.66c-5.17,0-7.18.57-7.18,3.73s2.01,3.73,7.18,3.73h41.66c5.17,0,7.18-.57,7.18-3.73Z" fill="#fff"/>
					<path d="M328.36,304.81l66.65-4.45-66.65-5.46v-21.69l66.8-5.6-66.8-4.45v-23.84l93.37,11.63v28.59l-66.36,4.6,66.36,5.89v27.15l-93.37,11.63v-23.99Z" fill="#fff"/>
					</g>
				</g>
			</svg>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.background {
	&:before {
		background: linear-gradient(134deg, #00adee, #eb0088);
	}
	.bg-texture {
		object-fit: cover;
		height: 100%;
		width: 100%;
		opacity: 0.8;
	}
}

.intro-content {
	font-family: var(--FormulaMedium);
	font-size: var(--5xl);
}

.dupaco-logo {
	position: absolute;
	left: 50%;
	top: 5%;
	transform: translateX(-50%);
	z-index: 1;
	width: 25%;
}

.high-low-svg {
	position: fixed;
	top: 25%;
	left: 50%;
	transform: translateX(-50%);
	width: 45vw !important;
	opacity: 0;
	pointer-events: none;
}

.launch-quiz {
	position: relative;
	z-index: 1000;

	button {
		background-color: var(--mango);
		font-size: var(--md);
		color: var(--white);
		font-family: var(--FormulaExtendedBold);
		padding: .75em 2em .5em;
		border: none;
		border-radius: .35em;
		transform-origin: 50% 100%;
		animation: pulse 4s infinite reverse cubic-bezier(.55, 0, .45, 1);

		@keyframes pulse {
			0% {
				transform: scale(1);
			}

			50% {
				transform: scale(1.3);
			}

			100% {
				transform: scale(1);
			}
		}
	}

	p {
		position: absolute;
		bottom: -1em;
		left: 50%;
		transform: translate(-50%, 100%);
		font-family: var(--FormulaExtendedBold);
		font-size: var(--sm);
		color: var(--white);
		text-transform: uppercase;
		margin: 0;
	}

	&.always {
		position: fixed;
		bottom: 40%;
		left: 50%;
		transform: translateX(-50%);
	}
}

button.launch-quiz {
	background-color: var(--mango);
	font-size: var(--md);
	color: var(--white);
	font-family: var(--FormulaExtendedBold);
	padding: .75em 2em .5em;
	border: none;
	border-radius: .35em;
}

.cta-content {
	color: var(--white);
	font-size: var(--2xl);
	line-height: 1.1;

	.box {
		position: relative;
		padding: 12vw 6vw 6vw;
		width: 60%;
		margin: 0 auto;
		border-radius: 20px;

		&.home_cta {
			background-image: linear-gradient(180deg, rgba(255, 255, 255, .05) 0%, rgba(255, 255, 255, .1) 100%);
			backdrop-filter: blur(3px);
			-webkit-backdrop-filter: blur(3px);
		}

		button {
			width: 100%;
			transform-origin: 50% 50%;
			animation: pulse-small 4s infinite reverse cubic-bezier(.55, 0, .45, 1);

			@keyframes pulse-small {
				0% {
					transform: scale(1);
				}

				50% {
					transform: scale(0.8);
				}

				100% {
					transform: scale(1);
				}
			}
		}
	}

	.copy {
		font-family: var(--FormulaMedium);
	}

}

.background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: .5s ease-in-out;
		background-color: var(--asphalt);
	}
}

.screens {
	position: fixed;
	top: 5%;
	bottom: 20%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	>div {
		width: 100%;
	}
}

.screen-container {
	width: 100%;
	max-width: 90%;
	margin: 0 auto;
}
</style>

<style lang="scss">
.start-content.high-low {
	p {
		font-size: var(--md);
		font-style: italic;
		text-align: center;
		letter-spacing: 0.02em;
		line-height: 1;
		margin: 0;
		br {
			line-height: 1;
		}
		strong {
			top: 0.5em;
			font-family: var(--FormulaCondensedExtraBold);
			font-size: var(--4xl);
			line-height: 1;
			font-style: normal;
			letter-spacing: 0;
			text-transform: initial;
			margin: 0;
		}
	}
}
</style>