<template>
    <div class="score-display">
        <div class="score-label">Your Projected Yearly Savings</div>
        <div class="score-value">{{ formatCurrency(props.score) }}</div>
    </div>
</template>

<style lang="scss" scoped>
    .score-display {
		pointer-events: none;
		position: fixed;
		bottom: 17%;
		left: 50%;
		transform: translateX(-50%);
		font-family: var(--FormulaExtraBold);
		width: 90%;

		.score-label {
			font-size: var(--lg);
			text-transform: uppercase;
		}

		.score-value {
			font-size: var(--6xl);
			margin-top: 0.15em;
		}
	}
</style>

<script setup>
    import { defineProps } from "vue"
    import { useFormatCurrency } from '@/composables/useFormatCurrency'
    const { formatCurrency } = useFormatCurrency();

    const props = defineProps({
        score: {
            type: Number,
            default: 0
        }
    })

</script>