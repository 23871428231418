<script setup>
	import { defineProps, onMounted, onUnmounted } from "vue";
	import {usePreviousScreenStore } from '@/stores';
	import AppIcon from '@/components/AppIcon';

	const props = defineProps({
		currentSlide: { type: Object, default: null },
		slug: { type: String, default: null },
		appIcon: { type: String, default: null },
	})

	const previousScreenStore = usePreviousScreenStore();

	onMounted(() => {
		previousScreenStore.setShowBackButton(true)
	})

	onUnmounted(() => {
		previousScreenStore.setShowBackButton(false)
	})

</script>

<template>
	<div>

		<div class="img-contain">
			<img :src="currentSlide.image" class="story-image" alt="">
			<div class="img-copy">
				<div v-html="currentSlide.title" class="title"></div>
			</div>
		</div>
		<div class="copy-contain">
			<div class="icon">
				<AppIcon :icon="props.appIcon" class="no-transition" />
			</div>
			<span v-if="slug !== 'dupaco-crew-stories'" class="pill small">Member Stories</span>
			<span v-else class="pill">Crew Stories</span>
			<div v-html="currentSlide.headline" class="headline"></div>
			<div v-html="currentSlide.copy" class="rte-copy"></div>
			<div class="qr-code" v-if="currentSlide.qrCode">
				<p v-html="currentSlide.qrCopy"></p>
				<div class="code">
					<img :src="currentSlide.qrCode" alt="">
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="scss">
	.story-detail-screen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		.img-contain {
			position: relative;
			text-align: right;
			height: 30vh;
			width: 100vw;
			clip-path: polygon( 0% 0%,0% 100%,0.176% 100%,0.176% 100%,4.972% 98.024%,9.816% 96.244%,14.706% 94.663%,19.641% 93.283%,24.619% 92.108%,29.637% 91.141%,34.695% 90.383%,39.791% 89.839%,44.922% 89.51%,50.088% 89.4%,50.088% 89.4%,55.253% 89.51%,60.385% 89.839%,65.48% 90.383%,70.538% 91.141%,75.557% 92.108%,80.535% 93.283%,85.47% 94.663%,90.36% 96.244%,95.204% 98.024%,100% 100%,100% 0%,0% 0% );
			&:after {
				position: absolute;
				content: "";
				inset: 0;
				background: linear-gradient(-25deg, rgba(#e87502, 1) 15%, rgba(#e87502, 0) 45%);
				clip-path: polygon( 0% 0%,0% 100%,0.176% 100%,0.176% 100%,4.972% 98.024%,9.816% 96.244%,14.706% 94.663%,19.641% 93.283%,24.619% 92.108%,29.637% 91.141%,34.695% 90.383%,39.791% 89.839%,44.922% 89.51%,50.088% 89.4%,50.088% 89.4%,55.253% 89.51%,60.385% 89.839%,65.48% 90.383%,70.538% 91.141%,75.557% 92.108%,80.535% 93.283%,85.47% 94.663%,90.36% 96.244%,95.204% 98.024%,100% 100%,100% 0%,0% 0% );
			}
			.story-image {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
			.img-copy {
				position: absolute;
				z-index: 1;
				right: 7%;
				bottom: 20%;
				.label-contain {
					display: flex;
					justify-content: flex-end;
					text-transform: uppercase;
					color: var(--white);
					font-family: var(--newsgothic);
					font-size: var(--xl);
					align-items: center;
					span {
						margin: 0 0.5em;
					}
				}
				.title {
					font-family: var(--newsgothic);
					color: var(--white);
					font-size: var(--5xl);
				}
			}
		}
		.copy-contain {
			position: relative;
			z-index: 13;
			width: 70vw;
			margin: -6vh auto 0 auto;
			text-align: left;
			.icon {
				max-width: 130px;
				text-align: center;
				.app-icon {
					position: static;
					margin-bottom: 1em;
					&.no-transition {
						transform: none !important;
						opacity: 1 !important;
					}
					.logo {
						margin: 0;
					}
				}
			}
			.pill {
				display: inline-block;
				font-family: var(--robotoCondensed);
				font-weight: 600;
				background: var(--mango);
				border-radius: 2em;
				font-size: var(--md);
				letter-spacing: -0.03em;
				color: var(--white);
				padding: 0.3em 0.5em;
				width: 110px;
				text-align: center;
				&.small {
					font-size: var(--sm);
				}
			}
			p {
				margin: 0.5em 0 0.3em 0;		
			}
			.headline {
				font-family: var(--newsgothic);
				font-size: var(--6xl);
				line-height: 1;
				letter-spacing: -0.03em;
				strong {
					font-weight: normal;
				}
			}
			.rte-copy {
				p {
					font-family: var(--robotoCondensed);
					font-weight: 400;
					font-size: var(--xl);
					color: #757575;
					line-height: 1.5em;
					letter-spacing: -0.02em;
					strong {
						font-family: var(--newsgothic);
						font-weight: normal;
						font-size: var(--xl);
						color: var(--black);
					}
				}
			}
			.qr-code {
				display: flex;
				align-items: center;
				gap: 5em;
				margin-top: 10%;
				p {
					font-family: var(--newsgothic);
					text-transform: uppercase;
					letter-spacing: -0.03em;
					font-size: var(--xl);
					line-height: 1em;
				}
				.code {
					width: 12vw;
					height: 12vw;
					border: 0.4em solid var(--black);
					padding: 1em;
					border-radius: 1em;
					img {
						height: 100%;
						width: 100%;
					}
				}
			}
		}
	}

</style>