// composables/useFormatPoints.js
export function useFormatPointsSpeedGame() {

	function formatPoints(value) {
		if (value === 0) return '0'

		return new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 0,  // No decimal part
			maximumFractionDigits: 0   // No decimal part
		}).format(value);
	}

	return { formatPoints };
}