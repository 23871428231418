<template>
    <div class="color-stripe">
        <div class="container">
            <img src="@/assets/img/color-stripe.svg" />
            <img src="@/assets/img/color-stripe.svg" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.color-stripe {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    overflow: hidden;
    .container {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        animation: 15s infinite stripe linear;
    }
    img {
        display: block;
        
        min-width: 100%;
    }
    
    @keyframes stripe {
        from {
            transform: translate3d(0,0,0);
        }
        to {
            transform: translate3d(100%,0,0);
        }
    }
}
</style>