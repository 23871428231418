<script setup>
import { defineProps } from "vue";
const props = defineProps({
    changesCount: {
        type: Number,
        default: 0
    }
})
</script>

<template>
    <svg class="stoplight" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 148.38 360.69">
        <g>
            <rect x="4.99" y="4.99" width="138.41" height="350.71" rx="37.9" ry="37.9"
                style="fill: #303030; stroke-width: 0px;" />
            <path
                d="m105.49,9.97c18.15,0,32.91,14.77,32.91,32.91v274.91c0,18.15-14.77,32.91-32.91,32.91h-62.61c-18.15,0-32.91-14.77-32.91-32.91V42.89c0-18.15,14.77-32.91,32.91-32.91h62.61m0-9.97h-62.61C19.3,0,0,19.3,0,42.89v274.91c0,23.59,19.3,42.89,42.89,42.89h62.61c23.59,0,42.89-19.3,42.89-42.89V42.89c0-23.59-19.3-42.89-42.89-42.89h0Z"
                style="fill: #f3f3f5; stroke-width: 0px;" />
        </g>
        <circle cx="74.19" cy="81.21" r="39.85" style="fill: #494949; stroke-width: 0px;" />
        <circle cx="74.19" cy="180.69" r="39.85" style="fill: #494949; stroke-width: 0px;" />
        <circle cx="74.19" cy="280.16" r="39.85" style="fill: #494949; stroke-width: 0px;" />
        <circle :class="[props.changesCount === 1 ? 'active' : 'circle']" cx="74.19" cy="81.21" r="30"
            style="fill: #eb1c24; stroke-width: 0px;" />
        <circle :class="[props.changesCount === 2 ? 'active' : 'circle']" cx="74.19" cy="180.69" r="30"
            style="fill: #fff000; stroke-width: 0px;" />
        <circle :class="[props.changesCount === 3 ? 'active' : 'circle']" cx="74.19" cy="280.16" r="30"
            style="fill: #9fdc1b; stroke-width: 0px;" />
    </svg>
</template>

<style lang="scss">
.stoplight {
    width: 150px;
    margin-bottom: 50%;

    .circle {
        opacity: 0.2;
    }

    .active {
        opacity: 1;
    }
}
</style>