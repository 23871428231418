<script setup>
import { ref, computed, watch, defineProps, onMounted, defineEmits, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import DupacoLogo from "@/components/DupacoLogo"
import AppIcon from '@/components/AppIcon'
import ColorStripe from "@/components/ColorStripe"
import { locationStore } from '@/stores'
import anime from "animejs"
import lottie from 'lottie-web'
import cashStackAnimationData from '../../assets/lottie/cash-stack.json';

const store = locationStore()
const router = useRouter()

const props = defineProps({
	game: { type: Object, default: null },
	isLast: { type: Boolean, default: false }
})

const dupacoTextColor = ref('white')
const dupacoShineColor = ref('mango')
const currentScreenIndex = ref(0)
const currentScreenKey = computed(() => `screen-${currentScreenIndex.value}`);

const currentScreen = computed(() => {
	// Compute the current screen based on the current index
	if (props.game && props.game.home_screen && props.game.home_screen.length > currentScreenIndex.value) {
		return props.game.home_screen[currentScreenIndex.value];
	}
	return null;  // Return null if the index is out of bounds
});

const currentScreenType = computed(() => {
	// Compute a CSS class for the current screen type
	return currentScreen.value ? `screen-${currentScreen.value.type}` : 'default-screen-type';
});

// Watch for changes in the current screen index
watch(currentScreenIndex, () => {
	// Set timer and adjust UI
	if (currentScreenIndex.value == 2) {
		// const video = document.querySelector('.cash-stack video')
		// video.pause()

		setTimeout(() => {
			initCashStack()
		}, currentScreen.value.duration * 1000)
		setScreenTimer(currentScreen.value.duration + 5)

	} else {
		if (currentScreen.value && currentScreen.value.type !== 'home_cta') {
			setScreenTimer(currentScreen.value.duration);
		} else {
			setScreenTimer(10);
			document.querySelector('.piggy-bank').removeAttribute('style')
		}
	}

});
// Timer for screen transitions
let screenTimer = null;

// Function to set a timer for screen transitions
const setScreenTimer = (duration) => {
	clearScreenTimer();
	screenTimer = setTimeout(() => {
		goToNextScreen();
	}, duration * 1000); // Convert duration to milliseconds
};

// Function to clear the screen transition timer
const clearScreenTimer = () => {
	if (screenTimer) {
		clearTimeout(screenTimer);
		screenTimer = null;
	}
};

const initCashStack = () => {
	lottie.loadAnimation({
		container: document.getElementById('cashStack'),
		renderer: 'svg',
		loop: false,
		autoplay: true,
		animationData: cashStackAnimationData // Use the imported data
	});
	anime({
		targets: ".piggy-bank img",
		scale: [1, 1.5],
		duration: 1000,
	})
}

const goToNextScreen = () => {
	if (currentScreenIndex.value < (props.game.home_screen.length - 1)) {
		currentScreenIndex.value++;
	} else {
		clearScreenTimer(); // Clear timer at the end of the screens
		if (!props.isLast) {
			// Animate Sceen Out
			setTimeout(() => {
				const tl = anime.timeline({
					easing: 'easeInQuad',
					complete: () => {
						updateNextScreen();
					}
				})
				tl.add({
					targets: '.color-stripe',
					duration: 250,
					translateX: [0, '100%'],
					opacity: [1, 0]
				})
					.add({
						targets: '.piggy-bank img',
						duration: 250,
						translateY: [0, '20%'],
						opacity: [1, 0]
					}, 50)
					.add({
						targets: '.dupaco-logo',
						translateX: ['-50%', '-50%'],
						translateY: [0, '-50%'],
						opacity: [1, 0],
						duration: 500,
					}, 100)
					.add({
						targets: '.screen-container',
						duration: 500,
						translateY: [0, '-25%'],
						opacity: [1, 0]
					}, 250)
					.add({
						targets: '.background',
						translateY: [0, 0],
						scale: [1, .9],
						borderRadius: [0, '50px'],
						duration: 500,
						easing: 'easeInOutQuad'
					}, 600)
					.add({
						targets: '.background',
						translateY: [0, '-20%'],
						opacity: [1, 0],
						duration: 500,
					}, 1500)

			}, 1000);
		} else {
			updateNextScreen();
		}

	}
};


const onEnter = () => {
	document.querySelector('.background').style.borderRadius = '50px'
	document.querySelector('.background').style.opacity = '0'
	document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'
	const tl = anime.timeline({
		easing: 'easeOutQuad',
		complete: () => {
			setScreenTimer(currentScreen.value.duration)
		}
	})
	tl.add({
		targets: '.background',
		translateY: ['20%', 0],
		opacity: [0, 1],
		duration: 500,
	})
		.add({
			targets: '.background',
			translateY: [0, 0],
			scale: [.9, 1],
			borderRadius: ['50px', 0],
			duration: 500,
			easing: 'easeInOutQuad'
		}, 1000)
		.add({
			targets: '.dupaco-logo',
			translateX: ['-50%', '-50%'],
			translateY: ['100%', 0],
			opacity: [0, 1],
			duration: 500,
		}, 1100)
		.add({
			targets: '.start-content',
			duration: 500,
			translateY: ['50%', 0],
			opacity: [0, 1]
		}, 1150)
		.add({
			targets: '.launch-quiz > div',
			duration: 500,
			translateY: ['80px', 0],
			opacity: [0, 1]
		}, 1150)
		.add({
			targets: '.piggy-bank img',
			duration: 500,
			translateY: ['50%', 0],
			opacity: [0, 1]
		}, 1200)
		.add({
			targets: '.color-stripe',
			duration: 500,
			translateX: ['-100%', 0],
			opacity: [0, 1]
		}, 1200)
}

onMounted(() => {
	onEnter()
})

const emit = defineEmits([
	'updateNextScreen'
])

onUnmounted(() => {
	clearScreenTimer();
})

const updateNextScreen = () => { emit('updateNextScreen', true) }

const goToGame = (slug) => {
	const tl = anime.timeline({
		easing: 'easeInQuad',
		complete: () => {
			router.push({ path: `/${store.data.slug}/game/${slug}` })
		}
	})
	tl.add({
		targets: '.launch-quiz > div',
		scale: [1, .75],
		opacity: [1, 0],
		duration: 350
	})
		.add({
			targets: '.color-stripe',
			duration: 250,
			translateX: [0, '100%'],
			opacity: [1, 0]
		})
		.add({
			targets: '.piggy-bank img',
			duration: 250,
			translateY: [0, '20%'],
			opacity: [1, 0]
		}, 50)
		.add({
			targets: '.dupaco-logo',
			translateX: ['-50%', '-50%'],
			translateY: [0, '-50%'],
			opacity: [1, 0],
			duration: 500,
		}, 100)
		.add({
			targets: '#cashStack',
			duration: 500,
			translateY: [0, '-25%'],
			opacity: [1, 0]
		}, 250)
		.add({
			targets: '.screen-container',
			duration: 500,
			translateY: [0, '-25%'],
			opacity: [1, 0]
		}, 250)
		.add({
			targets: '.background video',
			opacity: [.5, 0],
			duration: 250,
			easing: 'easeInQuad'
		})
		.add({
			targets: '.background',
			translateY: [0, 0],
			scale: [1, .9],
			borderRadius: [0, '50px'],
			duration: 500,
			easing: 'easeInOutQuad'
		}, 600)
	// .add({
	// 	targets: '.background',
	// 	translateY: [0,'-20%'],
	// 	opacity: [1,0],
	// 	duration: 500,
	// }, 1500)

}

</script>

<template>
	<div class="background">
		<video autoplay muted loop playsinline>
			<source src="../../assets/videos/finance-pattern.mp4" type="video/mp4">
		</video>
	</div>
	<div class="dupaco-logo" :class="currentScreenType">
		<DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor" />
	</div>
	<div class="screens">
		<Transition name="fadeup" mode="out-in">
			<div id="cashStack" v-if="currentScreenIndex === 2"></div>
		</Transition>
		<Transition name="fadeup" mode="out-in">
			<div class="screen" :key="currentScreenKey">
				<div class="screen-container" v-if="currentScreen?.type === 'start'">
					<div class="start-content" v-html="props.game?.title"></div>
				</div>
				<div class="screen-container" v-else-if="currentScreen?.type === 'intro'">
					<div class="intro-content" v-html="currentScreen?.content"></div>
				</div>

				<div class="screen-container" v-else>
					<div class="cta-content">
						<div class="box">
							<AppIcon :icon="props.game?.icon" :label="props.game?.title" :screen="currentScreenType" />
							<div class="launch-quiz">
								<button @touchstart="goToGame(game.slug)">LAUNCH QUIZ ▶</button>
							</div>

						</div>
						<div v-if="currentScreen?.type === 'home_cta'" class="copy" v-html="currentScreen?.content"></div>
					</div>
				</div>
			</div>
		</Transition>
		<Transition name="fadeup" mode="out-in">
			<div class="launch-quiz always" v-if="currentScreen?.type !== 'home_cta'">
				<div>
					<button @touchstart="goToGame(game.slug)" class="">LAUNCH QUIZ ▶</button>
					<p>Touch to get started</p>
				</div>
			</div>
		</Transition>

		<div class="piggy-bank">
			<img src="@/assets/img/piggy-bank.svg" />
		</div>

		<ColorStripe />
	</div>
</template>

<style lang="scss">
.start-content {
	color: var(--white);
	font-size: var(--8xl);

	p {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-transform: uppercase;
		line-height: 1.25;

		strong {
			font-family: var(--FormulaExtendedBold);
		}

		i {
			font-size: .7em;
			font-family: var(--FormulaSemiCondensedLightItalic);
		}
	}
}
</style>

<style lang="scss" scoped>
.background {
	background-color: transparent;

	video {
		position: absolute;
		inset: 0;
		height: 100%;
		width: 100%;
		opacity: 0.5;
		background-size: cover;
		mix-blend-mode: multiply;
	}
}

.launch-quiz {

	button {
		background-color: var(--mango);
		font-size: var(--md);
		color: var(--white);
		font-family: var(--FormulaExtendedBold);
		padding: .75em 2em .5em;
		border: none;
		border-radius: .35em;
		transform-origin: 50% 100%;
		animation: pulse 4s infinite reverse cubic-bezier(.55, 0, .45, 1);

		@keyframes pulse {
			0% {
				transform: scale(1);
			}

			50% {
				transform: scale(1.3);
			}

			100% {
				transform: scale(1);
			}
		}
	}

	&.always {
		position: fixed;
		bottom: 40%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 20;
	}

	p {
		position: absolute;
		bottom: -1em;
		left: 50%;
		transform: translate(-50%, 100%);
		font-family: var(--FormulaExtendedBold);
		font-size: var(--sm);
		color: var(--white);
		text-transform: uppercase;
		margin: 0;
		white-space: nowrap;
	}
}

.cta-content {
	color: var(--white);
	font-size: var(--2xl);
	line-height: 1.1;

	.box {
		padding: 12vw 6vw 6vw;
		width: 60%;
		margin: 0 auto;
		background-image: linear-gradient(180deg, rgba(255, 255, 255, .05) 0%, rgba(255, 255, 255, .1) 100%);
		backdrop-filter: blur(3px);
		-webkit-backdrop-filter: blur(3px);
		border-radius: 20px;

		button {
			width: 100%;
			transform-origin: 50% 50%;
			animation: pulse-small 4s infinite reverse cubic-bezier(.55, 0, .45, 1);

			@keyframes pulse-small {
				0% {
					transform: scale(1);
				}

				50% {
					transform: scale(0.9);
				}

				100% {
					transform: scale(1);
				}
			}
		}
	}

	.copy {
		font-family: var(--FormulaMedium);
		font-size: var(--lg);
		line-height: 1.3;
	}
}

.background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: .5s ease-in-out;
		background-color: var(--sapphire);
	}
}

.dupaco-logo {
	position: absolute;
	left: 50%;
	top: 5%;
	transform: translateX(-50%);
	z-index: 1;
	width: 25%;
}

.screens {
	position: fixed;
	top: 5%;
	bottom: 20%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	>.screen {
		width: 90%;
		margin: 0 auto;
	}
}

.screen-container {
	width: 100%;
	max-width: 90%;
	height: 100%;
	margin: 0 auto;
}

.piggy-bank {
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	max-width: 50%;
	transition: 1s ease-in-out;
	width: 100%;
}

#cashStack {
	position: fixed;
	z-index: 10;
	pointer-events: none;
	top: 0;
	right: 0;
	bottom: 10%;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>