<script setup>
import { defineProps, defineEmits, ref, onMounted, watch, onUnmounted } from "vue";
import {usePreviousScreenStore } from '@/stores';

const props = defineProps({
  currentScreen: { type: Object, default: null },
  handleLastGallerySlide: Function,
});
const previousScreenStore = usePreviousScreenStore();

const stories = ref(props.currentScreen?.stories || []);
const activeStoryIndex = ref(0); // Start with the first story
let currentTimeout = ref(null);

const emit = defineEmits(['showGalleryComponent', 'handleDisableTimeoutForScreen']);
const handleDisableTimeoutForScreen = (newValue) => { emit('handleDisableTimeoutForScreen', newValue) }

const showGalleryComponent = (newValue) => {
  emit('showGalleryComponent', newValue);
};

// Function to move to the next story
const nextStory = () => {
  if (activeStoryIndex.value < stories.value.length - 1) {
    activeStoryIndex.value++;
  }
};

const startNextStoryTimeout = () => {
  // Clear the previous timeout
  if (currentTimeout.value) {
    clearTimeout(currentTimeout.value);
  }
  
  if (activeStoryIndex.value < stories.value.length) {
    const currentStory = stories.value[activeStoryIndex.value];
    console.log(currentStory)
    const duration = currentStory.duration || 8; // Default to 8s if no duration is set
    currentTimeout.value = setTimeout(nextStory, duration * 1000);
  }
};

watch(activeStoryIndex, () => {
  if (activeStoryIndex.value === stories.value.length) {
    // If user is on the last screen, start the timer
    handleDisableTimeoutForScreen(false);
  } else {
    startNextStoryTimeout();
  }
});

// Initial setup
onMounted(() => {
  previousScreenStore.setShowBackButton(true)
	setTimeout(() => {
		document.querySelector('.child').classList.add('first')
	}, 100)
  startNextStoryTimeout();
});

onUnmounted(() => {
  previousScreenStore.setShowBackButton(false)
})
</script>

<template>
  <div class="story">
    <div v-for="(story, index) in stories" :key="index"
      :class="{ 'child': true, 'active': index <= activeStoryIndex, 'delay': index === 0}">
      <div class="single" v-if="story.photo">
        <img :src="story.photo" v-if="story.photo" class="full-image" alt="">
        <div class="copy-container" v-if="story.content">
          <div class="copy-contain">
            <div class="copy" v-html="story.content"></div>
            <button v-if="story.cta === '1'" @touchstart="showGalleryComponent('gallery')" class="photo-gallery-cta">Launch Photo Gallery</button>
          </div>
        </div>
      </div>
      <div class="double" v-if="story.photos">
        <div class="images">
          <img v-for="(image, imgIndex) in story.photos" :key="imgIndex" :src="image" :class="imgIndex % 2 === 0 ? 'top-image' : 'bottom-image'">
        </div>
        <div class="copy-container" v-if="story.content">
          <div class="copy-contain">
            <div class="copy" v-html="story.content"></div>
            <button v-if="story.cta === '1'" @touchstart="showGalleryComponent('gallery')"
              class="photo-gallery-cta">Launch Photo Gallery</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.story {
  .child {
    opacity: 0;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    &.active {
      opacity: 1;
      .copy-container {
        background: rgba(0,0,0,0.6);
      }
      img {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      }
      .copy-contain {
        opacity: 1;
        top: 50%;
      }
    }
    &.delay {
		.copy-container {
			background: rgba(0,0,0,0);
			.copy-contain {
				opacity: 0;
				top: 55%;
			}
		}
	}
	&.first {
		.copy-container {
			background: rgba(0,0,0,0.6);
			.copy-contain {
				top: 50%;
				opacity: 1;
			}
		}
	}
  }

  .single,
  .double {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: clip-path 1s;
    &.full-image {
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }
    &.top-image {
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }
    &.bottom-image {
      clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    }
  }

  .images {
    img {
      position: absolute;
      height: 50%;
      width: 100%;
      object-fit: cover;

      &:first-child {
        top: 0;
        left: 0;
      }

      &:last-child {
        top: 50%;
        bottom: 0;
        left: 0;
      }
    }
  }

  .copy-container {
    position: fixed;
    inset: 0;
    height: 100vh;
    width: 100vh;
    transition: opacity 0.3s ease, background 0.3s ease;
    transition-delay: 1.8s;
  }
  .copy-contain {
    position: fixed;
    top: 55%;
    left: 50%;
    width: 70vw;
    transform: translate(-50%, -55%);
    transition: opacity 1s ease, transform 2s, top 1s;
    transition-delay: 2s;
    text-align: left;
    opacity: 0;

    .pill {
      display: inline-block;
      background: var(--mango);
      font-family: var(--robotoCondensed);
      font-size: var(--lg);
      font-weight: 500;
      color: var(--white);
      padding: 0.4em 1em;
      margin-bottom: 1.5em;
      text-transform: uppercase;
      border-radius: 1em;
    }

    .copy {
      font-family: var(--newsgothic);
      color: var(--white);
      font-size: var(--5xl);
      line-height: 1em;

      p {
        margin: 0;
      }
    }
  }
}

.photo-gallery-cta {
  font-family: var(--newsgothic);
  font-size: var(--lg);
  background: var(--mango);
  border: 0;
  margin-top: 7.55%;
  padding: 0.6em 1em 0.7em 1em;
  color: var(--white);
  border-radius: 0.5em;
  text-transform: uppercase;
}
</style>
