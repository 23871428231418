<script setup>
    import { defineProps, computed } from "vue";
    const props = defineProps({
            seconds: String,
            totalMilliseconds: Number,
            initialTime: Number // Adding the initialTime prop
        }
    )

    const progress = computed(() => {
        const initialMilliseconds = props.initialTime * 1000;
        return (props.totalMilliseconds / initialMilliseconds) * 100;
    });

</script>

<template>
    <div class="timer-contain">
        <div class="timer">
            <div class="circle">
                <svg viewBox="0 0 36 36" class="circular-chart">
                    <path class="circle-bg"
                        d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path class="circle-progress"
                        :style="{ strokeDasharray: progress + ', 100' }"
                        d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                </svg>
            </div>
            <span class="time" v-html="props.seconds"></span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.timer-contain {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 7% auto 3% auto;
    .timer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        aspect-ratio: 1/1;
        &::before {
            content: "";
            display: block;
            position: absolute;
            inset: 5%;
            border-radius: 50%;
            border: 3px solid var(--gray250);
        }
        svg {
            position: absolute;
            inset: 0;
            z-index: 1;
        }
        .time {
            display: block;
            position: relative;
            top: 0.1em;
            font-family: var(--FormulaCondensedExtraLight);
            font-size: var(--5xl);
            line-height: 0.9;
        }
    }
}

.circular-chart {
    width: 100%;
    max-width: 20em;
}

.circle-bg {
    fill: none;
}

.circle-progress {
    fill: none;
    stroke: var(--mango); // use your desired color
    stroke-width: 2.8;
    stroke-linecap: round;
    transform-origin: 50% 50%;
    transform: scaleX(-1);
    transition-timing-function: linear;
}
</style>