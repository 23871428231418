<template>
	<svg class="flag-svg" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1875.74 1559.75">
		<path
			d="m295.77,907.53c-10.11,5.06-20.22,10.11-30.34,12.64-10.11,5.06-17.7,10.11-27.81,12.64-7.58,5.06-17.7,7.58-25.28,12.64-7.58,5.06-15.17,7.58-22.75,12.64l45.5,141.57c7.58-5.06,12.64-7.58,22.75-12.64,7.58-5.06,15.17-7.58,25.28-12.64,7.58-5.06,17.7-7.58,27.81-12.64,10.11-5.06,20.22-10.11,27.81-12.64l-42.97-141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m204.76,626.93c-10.11,5.06-20.22,10.11-30.34,12.64-10.11,5.06-17.7,7.58-27.81,12.64-7.58,5.06-17.7,7.58-25.28,12.64-7.58,5.06-15.17,7.58-22.75,12.64l45.5,141.57c7.58-5.06,15.17-7.58,22.75-12.64,7.58-5.06,15.17-7.58,25.28-12.64,7.58-5.06,17.7-7.58,27.81-12.64,10.11-5.06,20.22-10.11,30.34-12.64l-45.5-141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m384.25,1188.14c-10.11,5.06-20.22,10.11-27.81,12.64-10.11,5.06-17.7,10.11-27.81,12.64-7.58,5.06-17.7,7.58-25.28,12.64s-15.17,7.58-20.22,12.64l45.5,139.04c7.58-5.06,12.64-7.58,20.22-12.64,7.58-5.06,15.17-7.58,25.28-12.64,7.58-5.06,17.7-10.11,27.81-12.64,10.11-5.06,17.7-10.11,27.81-15.17l-45.5-136.51"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m829.17,730.58c-12.64,2.53-25.28,5.06-37.92,7.58-12.64,2.53-25.28,5.06-37.92,10.11-12.64,2.53-25.28,7.58-37.92,10.11-12.64,2.53-25.28,7.58-35.39,10.11l45.5,139.04c12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,25.28-7.58,37.92-10.11,12.64-2.53,25.28-5.06,37.92-10.11,12.64-2.53,25.28-5.06,37.92-7.58l-45.5-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m917.65,1011.18c-12.64,2.53-25.28,5.06-37.92,7.58-12.64,2.53-25.28,5.06-37.92,10.11-12.64,2.53-25.28,5.06-37.92,10.11-12.64,2.53-25.28,7.58-35.39,10.11l42.98,139.04c12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,25.28-7.58,37.92-10.11,12.64-2.53,25.28-5.06,37.92-10.11,12.64-2.53,25.28-5.06,37.92-7.58l-42.98-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m637.04,1087.02c-5.06,2.53-12.64,5.06-17.7,5.06-5.06,2.53-10.11,5.06-15.17,5.06-5.06,2.53-10.11,2.53-15.17,5.06-5.06,2.53-10.11,2.53-15.17,5.06-5.06,2.53-10.11,5.06-15.17,5.06-5.06,2.53-10.11,5.06-15.17,5.06-5.06,2.53-10.11,2.53-15.17,5.06-5.06,2.53-10.11,2.53-12.64,5.06l45.5,139.04c5.06-2.53,10.11-2.53,12.64-5.06,5.06-2.53,10.11-2.53,15.17-5.06,5.06-2.53,10.11-5.06,15.17-5.06s10.11-5.06,15.17-5.06c5.06-2.53,10.11-2.53,15.17-5.06,5.06-2.53,10.11-2.53,15.17-5.06,5.06-2.53,10.11-5.06,15.17-5.06,5.06-2.53,10.11-5.06,17.7-5.06l-45.5-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m722.99,907.53c-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-32.86,10.11-10.11,2.53-22.75,7.58-32.86,10.11-10.11,2.53-20.22,7.58-30.34,10.11l45.5,139.04c10.11-2.53,20.22-7.58,30.34-10.11,10.11-2.53,20.22-7.58,32.86-10.11,10.11-2.53,22.75-7.58,32.86-10.11,12.64-2.53,22.75-7.58,35.39-10.11l-45.5-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m457.56,528.34c-5.06,2.53-12.64,5.06-17.7,5.06-5.06,2.53-10.11,5.06-17.7,5.06-5.06,2.53-10.11,2.53-15.17,5.06s-10.11,2.53-15.17,5.06c-5.06,2.53-10.11,5.06-15.17,5.06s-10.11,5.06-15.17,5.06c-5.06,2.53-10.11,2.53-15.17,5.06-5.06,2.53-10.11,2.53-12.64,5.06l45.5,139.04c5.06-2.53,10.11-2.53,12.64-5.06,5.06-2.53,10.11-2.53,15.17-5.06s10.11-2.53,15.17-5.06c5.06-2.53,10.11-5.06,15.17-5.06,5.06-2.53,10.11-2.53,15.17-5.06,5.06-2.53,10.11-2.53,15.17-5.06,5.06-2.53,10.11-2.53,17.7-5.06,5.06-2.53,10.11-5.06,17.7-5.06l-45.5-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m467.67,993.48c-2.53,0-5.06,2.53-7.58,2.53s-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53c-7.58,2.53-17.7,7.58-25.28,10.11-7.58,2.53-17.7,7.58-25.28,10.11-7.58,2.53-15.17,7.58-22.75,10.11-7.58,2.53-15.17,7.58-22.75,10.11l45.5,139.04c7.58-2.53,15.17-7.58,22.75-10.11,7.58-2.53,15.17-7.58,22.75-10.11,7.58-2.53,15.17-7.58,25.28-10.11,7.58-2.53,17.7-7.58,25.28-10.11,2.53,0,5.06-2.53,7.58-2.53s5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53l-45.5-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m877.2,417.11c-10.11,2.53-20.22,5.06-30.34,7.58-10.11,2.53-20.22,5.06-30.34,7.58-10.11,2.53-20.22,5.06-30.34,7.58-10.11,2.53-20.22,5.06-27.81,7.58h-20.22l-42.98-139.04h20.22c10.11-2.53,20.22-5.06,27.81-7.58,10.11-2.53,20.22-5.06,30.34-7.58,10.11-2.53,20.22-5.06,30.34-7.58,10.11-2.53,20.22-5.06,30.34-7.58l42.98,139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m546.04,808.94c-5.06,2.53-12.64,5.06-17.7,5.06s-10.11,5.06-17.7,5.06c-5.06,2.53-10.11,2.53-15.17,5.06-5.06,2.53-10.11,2.53-15.17,5.06-5.06,2.53-10.11,5.06-15.17,5.06-5.06,2.53-10.11,5.06-15.17,5.06-5.06,2.53-10.11,2.53-15.17,5.06-5.06,2.53-10.11,2.53-12.64,5.06l45.5,139.04c5.06-2.53,10.11-2.53,12.64-5.06,5.06-2.53,10.11-2.53,15.17-5.06,5.06-2.53,10.11-5.06,15.17-5.06,5.06-2.53,10.11-5.06,15.17-5.06,5.06-2.53,10.11-2.53,15.17-5.06,5.06-2.53,10.11-2.53,15.17-5.06,5.06-2.53,10.11-5.06,15.17-5.06,5.06-2.53,10.11-5.06,17.7-5.06l-42.97-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m376.66,712.88c-2.53,0-5.06,2.53-7.58,2.53s-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53c-7.58,2.53-17.7,7.58-25.28,10.11-7.58,2.53-17.7,7.58-25.28,10.11-7.58,2.53-15.17,7.58-22.75,10.11-7.58,2.53-15.17,7.58-22.75,10.11l45.5,141.57c7.58-2.53,15.17-7.58,22.75-10.11,7.58-2.53,15.17-7.58,22.75-10.11,7.58-2.53,17.7-7.58,25.28-10.11,7.58-2.53,17.7-7.58,25.28-10.11,2.53,0,5.06-2.53,7.58-2.53s5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53l-45.5-141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m634.52,626.93c-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-32.86,10.11-10.11,2.53-22.75,7.58-32.86,10.11-10.11,2.53-20.22,7.58-30.34,10.11l45.5,139.04c10.11-2.53,20.22-7.58,30.34-10.11,10.11-2.53,20.22-7.58,32.86-10.11,10.11-2.53,22.75-7.58,32.86-10.11,12.64-2.53,22.75-7.58,35.39-10.11l-45.5-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1142.63,1253.86c-10.11,2.53-20.22,5.06-30.34,7.58-10.11,2.53-20.22,5.06-30.34,7.58-10.11,2.53-20.22,5.06-30.34,5.06-10.11,2.53-20.22,5.06-27.81,5.06h-20.22l-42.98-136.51h20.22c10.11-2.53,20.22-5.06,27.81-5.06,10.11-2.53,20.22-5.06,30.34-7.58,10.11-2.53,20.22-5.06,30.34-7.58,10.11-2.53,20.22-5.06,30.34-7.58l42.98,139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m854.45,1322.12c-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-32.86,10.11s-22.75,7.58-32.86,10.11c-10.11,2.53-20.22,7.58-30.34,10.11l-42.98-136.51c10.11-2.53,20.22-7.58,30.34-10.11,10.11-2.53,20.22-7.58,32.86-10.11,10.11-2.53,22.75-7.58,32.86-10.11,12.64-2.53,22.75-7.58,35.39-10.11l42.98,136.51"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m599.12,1408.07c-2.53,0-5.06,2.53-7.58,2.53s-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53c-7.58,2.53-17.7,7.58-25.28,10.11-7.58,2.53-17.7,7.58-25.28,10.11-7.58,2.53-15.17,7.58-22.75,10.11-7.58,2.53-15.17,7.58-22.75,10.11l-42.98-136.51c7.58-2.53,15.17-7.58,22.75-10.11,7.58-2.53,15.17-7.58,22.75-10.11,7.58-2.53,15.17-7.58,25.28-10.11,7.58-2.53,17.7-7.58,25.28-10.11,2.53,0,5.06-2.53,7.58-2.53s5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53l42.98,136.51"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m740.69,449.98c-12.64,2.53-25.28,5.06-37.92,10.11-12.64,2.53-25.28,5.06-37.92,10.11-12.64,2.53-25.28,7.58-37.92,10.11-12.64,2.53-25.28,7.58-35.39,10.11l45.5,139.04c12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,25.28-7.58,37.92-10.11,12.64-2.53,25.28-5.06,37.92-10.11,12.64-2.53,25.28-5.06,37.92-7.58l-45.5-141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m922.7,558.68c-10.11,2.53-20.22,5.06-30.34,7.58-10.11,2.53-20.22,5.06-30.34,7.58-10.11,2.53-20.22,5.06-30.34,7.58-10.11,2.53-20.22,5.06-27.81,5.06h-20.22l45.5,141.57h20.22c10.11-2.53,20.22-5.06,27.81-5.06,10.11-2.53,20.22-5.06,30.34-7.58,10.11-2.53,20.22-5.06,30.34-7.58,10.11-2.53,20.22-5.06,30.34-7.58l-45.5-141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1011.18,839.28c-10.11,2.53-20.22,5.06-30.34,7.58-10.11,2.53-20.22,5.06-30.34,7.58-10.11,2.53-20.22,5.06-30.34,7.58-10.11,2.53-20.22,5.06-27.81,5.06h-20.22l42.98,139.04h20.22c10.11-2.53,20.22-5.06,27.81-5.06,10.11-2.53,20.22-5.06,30.34-7.58,10.11-2.53,20.22-5.06,30.34-7.58,10.11-2.53,20.22-5.06,30.34-7.58l-42.98-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m333.69,573.84c-2.53,0-5.06,2.53-7.58,2.53s-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53c-7.58,2.53-17.7,7.58-25.28,10.11-7.58,2.53-17.7,7.58-25.28,10.11-7.58,2.53-15.17,7.58-25.28,10.11-7.58,2.53-15.17,7.58-22.75,10.11l-45.5-139.04c7.58-2.53,15.17-7.58,22.75-10.11,7.58-2.53,15.17-7.58,25.28-10.11,7.58-2.53,17.7-7.58,25.28-10.11,7.58-2.53,17.7-7.58,25.28-10.11,2.53,0,5.06-2.53,7.58-2.53s5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53l45.5,139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m589.01,487.89c-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-32.86,10.11-10.11,2.53-22.75,7.58-32.86,10.11-10.11,2.53-20.22,7.58-30.34,10.11l-42.98-136.51c10.11-2.53,20.22-7.58,30.34-10.11,10.11-2.53,20.22-7.58,32.86-10.11,10.11-2.53,22.75-7.58,32.86-10.11,12.64-2.53,22.75-7.58,35.39-10.11l42.98,136.51"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m101.12,677.49c-20.22,12.64-42.98,25.28-55.62,37.92L0,576.37c2.53-2.53,5.06-5.06,7.58-7.58,2.53-2.53,7.58-5.06,12.64-10.11,5.06-2.53,10.11-7.58,15.17-10.11,5.06-2.53,12.64-7.58,17.7-10.11l48.03,139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m283.13,1241.22c-7.58,5.06-12.64,7.58-17.7,12.64-5.06,2.53-10.11,7.58-15.17,10.11-5.06,2.53-7.58,7.58-12.64,10.11-2.53,2.53-5.06,5.06-7.58,7.58l-45.5-141.57c2.53-2.53,5.06-5.06,7.58-7.58,2.53-2.53,7.58-5.06,12.64-10.11,5.06-2.53,10.11-7.58,15.17-10.11s12.64-7.58,17.7-12.64l45.5,141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m192.12,960.62c-7.58,5.06-12.64,7.58-17.7,10.11s-10.11,7.58-15.17,10.11-7.58,7.58-12.64,10.11c-2.53,2.53-7.58,5.06-7.58,7.58l-45.5-141.57c2.53-2.53,5.06-5.06,7.58-7.58,2.53-2.53,7.58-5.06,12.64-10.11,5.06-2.53,10.11-7.58,15.17-10.11,5.06-2.53,12.64-7.58,17.7-10.11l45.5,141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m371.61,1516.77c-7.58,5.06-12.64,7.58-17.7,12.64-5.06,5.06-10.11,7.58-15.17,10.11-5.06,2.53-7.58,7.58-12.64,10.11-2.53,2.53-5.06,5.06-7.58,10.11l-42.98-136.51c2.53-2.53,5.06-5.06,7.58-10.11,2.53-2.53,7.58-7.58,12.64-10.11,5.06-2.53,10.11-7.58,15.17-10.11,5.06-2.53,12.64-7.58,17.7-12.64l42.98,136.51"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1294.31,280.6c-7.58,2.53-17.7,7.58-25.28,10.11-10.11,2.53-17.7,7.58-27.81,10.11-10.11,2.53-17.7,7.58-27.81,10.11s-17.7,7.58-27.81,10.11c-2.53,0-5.06,2.53-7.58,2.53s-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53l42.98,141.57c2.53,0,5.06-2.53,7.58-2.53s5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53c10.11-2.53,17.7-7.58,27.81-10.11,10.11-2.53,17.7-7.58,27.81-10.11,10.11-2.53,17.7-7.58,27.81-10.11,10.11-2.53,17.7-7.58,25.28-10.11l-42.98-141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1286.73,753.33c-10.11,5.06-22.75,7.58-32.86,12.64-10.11,5.06-22.75,7.58-32.86,12.64-10.11,5.06-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-35.39,10.11l42.98,141.57c12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,22.75-7.58,32.86-10.11,10.11-5.06,22.75-7.58,32.86-12.64,10.11-5.06,22.75-7.58,32.86-12.64l-40.45-141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1200.78,472.73c-10.11,5.06-22.75,7.58-32.86,12.64-10.11,5.06-22.75,7.58-32.86,12.64-12.64,5.06-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-35.39,10.11l42.98,141.57c12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,22.75-7.58,35.39-10.11,10.11-5.06,22.75-7.58,32.86-12.64s22.75-7.58,32.86-12.64l-42.98-141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1468.74,841.81c-7.58,2.53-17.7,7.58-25.28,10.11-7.58,2.53-17.7,7.58-27.81,10.11s-17.7,7.58-27.81,10.11c-10.11,2.53-17.7,7.58-27.81,10.11-2.53,0-5.06,2.53-7.58,2.53s-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53l42.98,139.04c2.53,0,5.06-2.53,7.58-2.53s5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53c10.11-2.53,17.7-7.58,27.81-10.11,10.11-2.53,17.7-7.58,27.81-10.11,10.11-2.53,17.7-7.58,27.81-10.11,7.58-2.53,17.7-7.58,25.28-10.11l-42.98-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1792.32,692.66c-5.06,2.53-12.64,7.58-20.22,10.11-7.58,2.53-12.64,7.58-20.22,10.11-7.58,2.53-15.17,7.58-22.75,10.11-7.58,5.06-15.17,7.58-22.75,12.64l-42.98-139.04c7.58-5.06,15.17-7.58,22.75-12.64,7.58-5.06,15.17-7.58,22.75-10.11,7.58-2.53,15.17-7.58,20.22-10.11,7.58-2.53,12.64-7.58,20.22-10.11l42.98,139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1706.37,738.16c-7.58,5.06-17.7,7.58-25.28,12.64-7.58,5.06-17.7,7.58-27.81,12.64-10.11,5.06-17.7,7.58-27.81,12.64-10.11,5.06-20.22,7.58-30.34,12.64l42.98,139.04c10.11-5.06,20.22-7.58,30.34-12.64,10.11-5.06,17.7-7.58,27.81-12.64,10.11-5.06,17.7-7.58,27.81-12.64,7.58-5.06,17.7-7.58,25.28-12.64l-42.98-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1466.21,366.55c-10.11,5.06-20.22,7.58-30.34,12.64-10.11,5.06-20.22,7.58-30.34,12.64-10.11,5.06-22.75,7.58-32.86,12.64-10.11,5.06-22.75,7.58-32.86,12.64l42.98,141.57c10.11-5.06,22.75-10.11,32.86-12.64,10.11-5.06,22.75-10.11,32.86-12.64,10.11-5.06,20.22-7.58,30.34-12.64,10.11-5.06,20.22-7.58,30.34-12.64l-42.98-141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1382.79,561.2c-7.58,2.53-17.7,7.58-25.28,10.11-10.11,2.53-17.7,7.58-27.81,10.11-10.11,2.53-17.7,7.58-27.81,10.11-10.11,2.53-17.7,7.58-27.81,10.11-2.53,0-5.06,2.53-7.58,2.53s-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53-5.06,2.53-7.58,2.53l42.98,141.57c2.53,0,5.06-2.53,7.58-2.53s5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53,5.06-2.53,7.58-2.53c10.11-2.53,17.7-7.58,27.81-10.11s17.7-7.58,27.81-10.11c10.11-2.53,17.7-7.58,27.81-10.11,10.11-2.53,17.7-7.58,25.28-10.11l-42.98-141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1531.94,176.96c-7.58,5.06-17.7,7.58-25.28,12.64-7.58,5.06-17.7,7.58-27.81,12.64-10.11,5.06-17.7,7.58-27.81,12.64-10.11,5.06-20.22,7.58-30.34,12.64l42.98,141.57c10.11-5.06,20.22-7.58,30.34-12.64,10.11-5.06,20.22-7.58,27.81-12.64,10.11-5.06,17.7-7.58,27.81-12.64,7.58-5.06,17.7-7.58,25.28-12.64l-42.98-141.57"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1620.42,457.56c-7.58,5.06-17.7,7.58-25.28,12.64-7.58,5.06-17.7,7.58-27.81,12.64-10.11,5.06-17.7,7.58-27.81,12.64-10.11,5.06-20.22,7.58-30.34,12.64l42.98,139.04c10.11-5.06,20.22-7.58,30.34-12.64,10.11-5.06,17.7-7.58,27.81-12.64,10.11-5.06,17.7-7.58,27.81-12.64,7.58-5.06,17.7-7.58,25.28-12.64l-42.98-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1552.16,647.16c-10.11,5.06-20.22,7.58-30.34,12.64-10.11,5.06-20.22,7.58-30.34,12.64-10.11,5.06-20.22,10.11-32.86,12.64-10.11,5.06-22.75,10.11-32.86,12.64l42.98,139.04c10.11-5.06,22.75-10.11,32.86-12.64,10.11-5.06,22.75-10.11,32.86-12.64,10.11-5.06,20.22-10.11,30.34-12.64,10.11-5.06,20.22-7.58,30.34-12.64l-42.98-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1706.37,414.58c-5.06,2.53-12.64,7.58-20.22,10.11-7.58,2.53-12.64,7.58-20.22,10.11-7.58,2.53-15.17,7.58-22.75,10.11-7.58,2.53-15.17,7.58-22.75,12.64l-42.98-139.04c7.58-5.06,15.17-7.58,22.75-12.64,7.58-2.53,15.17-7.58,22.75-10.11,7.58-2.53,15.17-7.58,20.22-10.11,7.58-2.53,12.64-7.58,20.22-10.11l42.98,139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1195.72,937.87c-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,5.06-35.39,10.11l42.98,139.04c12.64-2.53,22.75-5.06,35.39-10.11,12.64-2.53,22.75-5.06,35.39-10.11,12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,22.75-7.58,35.39-10.11l-42.98-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1875.74,968.21c-5.06,2.53-12.64,7.58-20.22,10.11-7.58,2.53-12.64,7.58-20.22,10.11-7.58,2.53-15.17,7.58-22.75,12.64-7.58,5.06-15.17,7.58-22.75,12.64l-42.98-136.51c7.58-5.06,15.17-7.58,22.75-12.64,7.58-5.06,15.17-7.58,22.75-12.64,7.58-2.53,15.17-7.58,20.22-10.11,7.58-2.53,12.64-7.58,20.22-10.11l42.98,136.51"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1681.09,1064.27c-10.11,5.06-20.22,10.11-30.34,12.64-10.11,5.06-20.22,10.11-30.34,12.64-10.11,5.06-20.22,10.11-32.86,12.64-10.11,5.06-22.75,10.11-32.86,12.64l-42.98-136.51c10.11-5.06,22.75-10.11,32.86-12.64,10.11-5.06,22.75-10.11,32.86-12.64,10.11-5.06,20.22-10.11,30.34-12.64,10.11-5.06,20.22-10.11,30.34-12.64l42.98,136.51"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1418.18,1170.44c-10.11,5.06-22.75,7.58-32.86,12.64-10.11,5.06-22.75,7.58-32.86,12.64-10.11,5.06-22.75,7.58-32.86,10.11-10.11,2.53-22.75,7.58-35.39,10.11l-42.98-136.51c12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,22.75-7.58,32.86-10.11,10.11-5.06,22.75-7.58,32.86-12.64,10.11-5.06,22.75-7.58,32.86-12.64,10.11,30.34,20.22,68.26,30.34,96.06,5.06,12.64,7.58,27.81,12.64,40.45"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1423.24,227.51c-10.11,5.06-20.22,7.58-30.34,12.64-10.11,5.06-20.22,7.58-32.86,12.64-10.11,5.06-22.75,7.58-32.86,12.64-10.11,5.06-22.75,7.58-32.86,12.64l-42.98-139.04c10.11-5.06,22.75-7.58,32.86-12.64,10.11-5.06,22.75-7.58,32.86-12.64,10.11-5.06,20.22-7.58,32.86-12.64,10.11-5.06,20.22-7.58,30.34-12.64l42.98,139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1620.42,136.51c-5.06,2.53-12.64,7.58-20.22,10.11-7.58,2.53-12.64,7.58-20.22,10.11-7.58,2.53-15.17,7.58-22.75,10.11-7.58,2.53-15.17,7.58-25.28,10.11l-42.98-136.51c7.58-5.06,15.17-7.58,25.28-10.11,7.58-2.53,15.17-7.58,22.75-10.11,7.58-2.53,15.17-7.58,20.22-10.11,7.58-2.53,12.64-7.58,20.22-10.11l42.98,136.51"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1155.27,331.16c-10.11,5.06-22.75,7.58-32.86,12.64-10.11,5.06-22.75,7.58-32.86,12.64-12.64,5.06-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-35.39,10.11l-42.98-139.04c12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,22.75-7.58,32.86-12.64,10.11-5.06,22.75-7.58,32.86-12.64,10.11,30.34,22.75,68.26,30.34,98.59,5.06,12.64,10.11,27.81,12.64,40.45"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1107.24,657.27c-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,5.06-35.39,10.11l42.98,139.04c12.64-2.53,22.75-5.06,35.39-10.11,12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,22.75-7.58,35.39-10.11l-42.98-139.04"
			style="fill: #fff; stroke-width: 0px;" />
		<path
			d="m1018.76,376.66c-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,7.58-35.39,10.11-12.64,2.53-22.75,5.06-35.39,10.11l45.5,141.57c12.64-2.53,22.75-5.06,35.39-10.11,12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,22.75-7.58,35.39-10.11,12.64-2.53,22.75-7.58,35.39-10.11l-45.5-141.57"
			style="fill: #fff; stroke-width: 0px;" />
	</svg>
</template>

<style lang="scss" scoped>
.flag-svg {
	position: fixed;
	inset: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transform: scale(2.5);
}
</style>