
<script setup>
    import { defineProps } from "vue"

    const props = defineProps({
        score: {
            type: String,
            default: '0'
        }
    })

</script>

<template>
    <div class="score-display">
        <div class="score-label">Total Points</div>
        <div class="score-value">{{ props.score }}</div>
    </div>
</template>

<style lang="scss" scoped>
    .score-display {
		pointer-events: none;
		position: fixed;
		bottom: 15%;
		left: 50%;
		transform: translateX(-50%);
		// transition: .5s ease-in-out;
		font-family: var(--FormulaExtraBold);
		width: 90%;

		.score-label {
			font-size: var(--lg);
			text-transform: uppercase;
		}

		.score-value {
			font-size: var(--6xl);
			margin-top: 0.15em;
		}
	}
</style>