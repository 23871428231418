<script setup>
import { ref, computed, watch, onMounted, defineProps } from "vue";
import { usePreviousScreenStore } from '@/stores';
import DupacoLogo from "@/components/DupacoLogo";
import ColorStripe from "@/components/ColorStripe";
import AppIcon from '@/components/AppIcon';
import MemberCarousel from '@/components/MemberCarousel';
import anime from "animejs";
import StoryDetail from "@/components/StoryDetail.vue";

const previousScreenStore = usePreviousScreenStore();
let currentScreenIndex = ref(0);
const dupacoTextColor = ref('black')
const dupacoShineColor = ref('mango')
const logoState = ref('start'); // State for controlling logo appearance
const horizonState = ref('start'); // State for controlling horizon bar appearance
let screenTimer = null;
const showStoryDetailComponent = ref(false);
const currentSlide = ref(null);
const backwardsAnimation = ref(false);
const dontGoToNextScreen = ref(false);


const props = defineProps({
	game: { type: Object, default: null }
})

const showPreviousScreen = computed(() => previousScreenStore.showPreviousScreen);

const currentScreen = computed(() => {
	if (props.game && props.game.screens && props.game.screens.length > currentScreenIndex.value) {
		return props.game.screens[currentScreenIndex.value];
	}
	return null;
});

const currentScreenType = computed(() => {
	return currentScreen.value ? `screen-${currentScreen.value.type}` : 'default-screen-type';
});

const currentScreenKey = computed(() => `screen-${currentScreenIndex.value}`);

const clearScreenTimer = () => {
	if (screenTimer) {
		clearTimeout(screenTimer);
		screenTimer = null;
	}
};

const GameEnter = () => {
	document.querySelector('.color-stripe').style.opacity = '0'
	document.querySelector('.background').style.borderRadius = '50px'
	document.querySelector('.background').style.opacity = '1'
	document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'

	setTimeout(() => {
		const tl = anime.timeline({
			easing: 'easeOutQuad'
		})
		tl.add({
			targets: '.app-icon',
			opacity: [0, 1],
			duration: 750,
			translateY: ['0%'],
			translateX: ['-50%', '-50%'],
			top: ['10%', '10%'],
			scale: [0.8, 1],
		}, 1000)
			.add({
				targets: '.intro-content-slideshow',
				top: ['25%', '15%'],
				bottom: ['25%', '30%'],
				translateX: ['-50%', '-50%'],
				opacity: [0,1],
				duration: 750,
			}, 1500)
			.add({
				targets: '.dupaco-logo',
				translateY: ['0%', '-10%'],
				translateX: ['-50%', '-50%'],
				opacity: [0,1],
				duration: 250,
			}, 750)
			.add({
				targets: '.background',
				translateY: [0, 0],
				scale: [.9, 1],
				borderRadius: ['50px', 0],
				duration: 200,
				easing: 'easeInOutQuad'
			}, 750)
			.add({
				targets: '.color-stripe',
				duration: 250,
				translateX: ['-100%', 0],
				opacity: [0, 1],
			}, 1300)
	}, 500);

}

const goToNextScreen = () => {
	if (currentScreenIndex.value < props.game.screens.length - 1) {
		currentScreenIndex.value++;
	} else {
		clearScreenTimer(); // Clear timer at the end of the screens
	}
};

// Function to set a timer for screen transitions
const setScreenTimer = (duration) => {
	clearScreenTimer();
	screenTimer = setTimeout(() => {
		goToNextScreen();
	}, duration * 1000); // Convert duration to milliseconds
};


// Goes to the next screen after the intro
if(currentScreenType.value == 'screen-intro') {
	setScreenTimer(currentScreen.value.duration);
}

function adjustLogoForScreen() {
	horizonState.value = 'show';
	logoState.value = 'top';
}

const goToStory = (slide) => {
	currentSlide.value = slide;
	goToNextScreen()
	showStoryDetailComponent.value = true;
}

// Watch for changes in the current screen index
watch(currentScreenIndex, () => {
	// Don't go to the next screen if it's the service carousel
	if (currentScreen.value.type !== 'service-carousel') {
		if(dontGoToNextScreen.value === false) {
			setScreenTimer(currentScreen.value.duration)
		}
	}
	if (currentScreen.value.type === 'service-carousel') {
		dontGoToNextScreen.value = true;
		adjustLogoForScreen()
		if(backwardsAnimation.value === false) {
			const tl = anime.timeline({
				easing: 'easeOutQuad'
			})
			tl.add({
				targets: '.app-icon',
				translateX: ['-50%', '-50%'],
				top: ['5%', '2%'],
				scale: [1, .5],
				opacity: [0, 1],
				duration: 750,
				delay: 750
			})
		} else {
			const tl = anime.timeline({
				easing: 'easeOutQuad'
			})
			tl.add({
				targets: '.app-icon',
				opacity: 0,
				translateX: ['-50%', '-50%'],
				top: ['2%', '2%'],
				scale: [1, .5],
			})
		}
	}
})

watch(showPreviousScreen, () => {
	if(showPreviousScreen.value === true) {
		document.querySelector('.app-icon').style.opacity = 0;
		backwardsAnimation.value = true
		showStoryDetailComponent.value = false;
		currentScreenIndex.value = currentScreenIndex.value - 1;
		previousScreenStore.resetShowPreviousScreen();
	} else {
		backwardsAnimation.value = false
	}
})

onMounted(() => {
	GameEnter()
})

</script>

<template>
	<div class="background" :class="currentScreenType">
		<img src="../../assets//img/slideshow-bg.svg" class="slideshow-bg" alt="">
		<img src="@/assets/img/horizon-bar.svg" :class="['horizon-bar', horizonState, currentScreenType]">
	</div>

	<AppIcon :icon="props.game?.icon" :screen="currentScreenType" />

	<div class="screens">

		<!-- Intro Screen 1 -->
		<Transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'intro'">
				<div class="intro-content-slideshow" v-html="currentScreen?.content"></div>
			</div>
		</Transition>

		<!-- Intro Screen 2 -->
		<Transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" v-if="currentScreen?.type === 'intro-2'">
				<div class="intro-content-2">
					<div class="copy-contain">
						<DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor" />
						<div class="copy" v-html="currentScreen?.content"></div>
					</div>
				</div>
			</div>
		</Transition>

		<!-- Member Carousel Screen -->
		<Transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" :class="currentScreenType" v-if="currentScreen?.type === 'carousel-screen'">
				<div class="carousel-contain">
					<MemberCarousel :currentScreenContent="currentScreen" currentScreenType="carousel-screen" :slug="game.slug"/>
				</div>
			</div>
		</Transition>

		<Transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container service-carousel" v-if="currentScreen?.type === 'service-carousel'">
				<div class="carousel-contain">
					<div v-html="currentScreen.content" class="member-stories-headline"></div>
					<MemberCarousel @goToStory="goToStory" :currentScreenContent="currentScreen" :slug="game.slug"/>
				</div>
			</div>
		</Transition>

		<!-- Story Detail Screen -->
		<Transition name="fadeup" mode="out-in">
			<div v-if="showStoryDetailComponent" :key="currentScreenKey" class="story-detail-screen">
				<StoryDetail :currentSlide="currentSlide" :slug="game.slug" :appIcon="props.game?.icon" />
			</div>
		</Transition>
	</div>

	<div class="dupaco-logo" :class="currentScreenType">
		<DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor" />
	</div>

	<ColorStripe />
</template>

<style lang="scss" scoped>
.Question-enter-active,
.Question-leave-active {
	transition: opacity 1.5s ease;
}

.Question-enter-from,
.Question-leave-to {
	opacity: 0;
	transform: translate3d(0, 10%, 0);
}

.screens {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}

.background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: .5s ease-in-out;
		background-color: var(--white);
	}

	.horizon-bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		opacity: .1;
		transform: translate3d(0, -100%, 0);
		&.show {
			transform: translate3d(0, -10%, 0)
		}
		&.screen-service-carousel {
			opacity: 0.05;
		}
	}
}

.app-icon {
	z-index: 20;
	position: fixed;
	top: 50%;
	left: 50%;
	transform-origin: center top;
	transform: translate3d(-50%, -50%, 0);
	width: 30%;
	opacity: 0;
	&.screen-member-story-detail {
		display: none;
	}
}

.dupaco-logo {
	position: fixed;
	bottom: 5%;
	left: 50%;
	transform: translateX(-50%);
	width: 40%;
	transition: .5s ease-in-out;
	&.screen-intro {
		opacity: 0;
	}
	&.screen-service-carousel, &.screen-member-story-detail {
		bottom: 3%;
		width: 25%;
	}
}

.screen-container {
	position: fixed;
	width: 100%;
	left: 0;
	top: 25%;
	bottom: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
	&.screen-carousel-screen {
		top: 33%;
	}
	&.service-carousel {
		top: 20%;
	}
}

.question-content {
	max-width: 90%;
	margin: 0 auto;
}

.intro-content-slideshow {
	position: absolute;
	top: 25%;
	bottom: 25%;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 80%;
	margin: 0 auto;
	opacity: 0;
}

.member-stories-headline {
	font-family: var(--robotoCondensed);
	font-weight: 300;
	font-size: var(--3xl);
	width: 80vw;
	margin: 0 auto 3vh auto;
	letter-spacing: -0.02em;
}

.carousel-contain {
	height: 100%;
	width: 100%;
	font-family: var(--FormulaMedium);
	color: var(--black);
	.copy-contain {
		padding: 4vh 6vw 2vh 6vw;
		font-size: var(--lg);
		line-height: 1.5em;
		background: var(--white);
		border: 2px solid var(--mango);
		border-radius: 0.4em;
		box-shadow: 0 5px 40px 20px rgba(#e87502, 0.15);
		svg {
			position: static;
			width: 35%;
		}
		p {
			color: var(--black);
			font-family: var(--FormulaMedium);
			line-height: 1.4em;
			font-size: var(--md);
			&:first-child {
				font-size: var(--lg);
			}
		}
		u {
			text-decoration-color: var(	--mango);
			text-decoration-thickness: 0.15em;
		}
	}
}
</style>

<style lang="scss">

.member-stories-headline {
	strong {
		font-weight: 400;
	}
}

.intro-content-slideshow {
	p {
		font-family: var(--robotoCondensed);
		font-weight: 300;
		color: var(--black);
		font-size: var(--5xl);
		line-height: 1.3em;
		strong {
			font-weight: 500;
		}
	}
}

.intro-content-2 {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 50%;
	transform: translate(-50%);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 70%;
	margin: 0 auto;
	font-family: var(--FormulaMedium);
	color: var(--black);
	.copy-contain {
		padding: 4vh 6vw 2vh 6vw;
		font-size: var(--lg);
		line-height: 1.5em;
		background: var(--white);
		border: 2px solid var(--mango);
		border-radius: 0.4em;
		box-shadow: 0 5px 40px 20px rgba(#e87502, 0.15);
		svg {
			position: static;
			width: 35%;
		}
		p {
			color: var(--black);
			font-family: var(--FormulaMedium);
			line-height: 1.6em;
			font-size: var(--sm);
			&:first-child {
				font-size: var(--lg);
			}
		}
		u {
			text-decoration-color: var(	--mango);
			text-decoration-thickness: 0.15em;
			text-underline-offset: 0.2em;
		}
	}
}
</style>
