<script setup>
import { ref, computed, watch, onMounted, defineProps } from "vue";
import { usePreviousScreenStore } from '@/stores';
import DupacoLogo from "@/components/DupacoLogo";
import ColorStripe from "@/components/ColorStripe";
import BrandIntroScreen2 from "@/components/BrandIntroScreen2";
import MissionVisionValuesScreen from "@/components/MissionVisionValuesScreen";
import LogoEvolution from "@/components/LogoEvolution";
import AppIcon from '@/components/AppIcon';
import anime from "animejs";

const previousScreenStore = usePreviousScreenStore();
const currentScreenIndex = ref(0);
const dupacoShineColor = ref('mango')
const dupacoTextColor = ref('black')
const horizonState = ref('start');
const showScreen = ref(null);
const showDupacoLogo = ref(false);
const showCreditUnionLogo = ref(false);
let screenTimer = null;
const backwardsAnimation = ref(false)
const dontGoToNextScreen = ref(false);

const props = defineProps({
	game: { type: Object, default: null }
})

const screen0bg = ref(props.game.screens[0].background)
const screen1bg = ref(props.game.screens[1].background)

const currentScreen = computed(() => {
	if (props.game && props.game.screens && props.game.screens.length > currentScreenIndex.value) {
		return props.game.screens[currentScreenIndex.value];
	}
	return null;
});

const showPreviousScreen = computed(() => previousScreenStore.showPreviousScreen);

const currentScreenType = computed(() => {
	return currentScreen.value ? `screen-${currentScreen.value.type}` : 'default-screen-type';
});

const currentScreenKey = computed(() => `screen-${currentScreenIndex.value}`);

const clearScreenTimer = () => {
	if (screenTimer) {
		clearTimeout(screenTimer);
		screenTimer = null;
	}
};

const GameEnter = () => {
	if(currentScreenIndex.value === 0) {
		document.querySelector('.color-stripe').style.opacity = '0'
		document.querySelector('.background').style.borderRadius = '50px'
		document.querySelector('.background').style.opacity = '1'
		document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'
		setTimeout(() => {
			const tl = anime.timeline({
				easing: 'easeOutQuad'
			})
			tl.add({
					targets: '.background',
					translateY: [0, 0],
					scale: [.9, 1],
					borderRadius: ['50px', 0],
					duration: 200,
					easing: 'easeInOutQuad'
				}, 750)
				.add({
					targets: '.background .bg-image-1',
					duration: 200,
					easing: 'easeOutInQuad',
					opacity: [0, 1],
				})
				.add({
					targets: '.background .bg-image-1',
					duration: 200,
					easing: 'easeInOutQuad',
					opacity: [1, 0.6],
				}, 1000)
				.add({
					targets: '.intro-logo',
					opacity: [0, 1],
					translateY: ['10%', 0],
					duration: 500,
				}, 1500)
				.add({
					targets: '.intro-content .copy',
					opacity: [0, 1],
					translateY: ['10%', 0],
					duration: 500,
				}, 1750)
		}, 500);
	}

}

const goToNextScreen = () => {
	console.log("goToNextScreen")
	if(currentScreenIndex.value === 0) {
		const tl = anime.timeline({
			easing: 'easeOutQuad'
		})
		tl.add({
			targets: '.background',
			translateY: [0, 0],
			borderRadius: [0, 0],
			duration: 500,
			easing: 'easeOutQuad',
		})
			.add({
				targets: '.intro-content img',
				opacity: [1, 0],
				translateY: [ 0, '-10%'],
				duration: 500,
			})
			.add({
				targets: '.intro-content .copy',
				opacity: [1, 0],
				translateY: [ 0, '-10%'],
				duration: 500,
			})
			.add({
				targets: '.background .bg-image-1',
				opacity: [0.6, 0],
				duration: 500,
				complete: () => {
					currentScreenIndex.value++;
					document.querySelectorAll('.bg-image-2').forEach(image => {
						image.style.opacity = 1;
					})
				}
			})
	}  else {
		if(dontGoToNextScreen.value === false) {
			if (currentScreenIndex.value < props.game.screens.length - 1) {
				currentScreenIndex.value++;
			} else {
				clearScreenTimer(); // Clear timer at the end of the screens
			}
		}
	}
};

if(currentScreenType.value === 'screen-intro-2') setScreenTimer(currentScreen.value.duration)

// Watch for changes in the current screen index
watch(currentScreenType, () => {
	if(currentScreenType.value === 'screen-intro-2') {
			const tl = anime.timeline({
				easing: 'easeOutQuad'
			})
			
			tl.add({
				targets: '.dupaco-credit-union-logo',
				translateY: ['-50%', '-50%'],
				translateX: ['-50%', '-50%'],
				top: ['35%', '33%'],
				opacity: [0, 1],
				duration: 500,
				easing: 'easeOutQuad',
			})
			.add({
				targets: '.background .bg-image-2',
				opacity: [1, 0.6],
				duration: 750,
				easing: 'easeInQuad',
			}, 750)
			showCreditUnionLogo.value = true
			setScreenTimer(currentScreen.value.duration);
	}

	if(currentScreenType.value === 'screen-dupaco-brand' || currentScreenType.value === 'screen-logo-evolution') {
		showCreditUnionLogo.value = false
		// document.querySelector('.dupaco-logo').style.top = 'unset';
		showDupacoLogo.value = true
		const tl = anime.timeline({
			easing: 'easeOutQuad'
		})
		tl.add({
			targets: '.horizon-bar',
			translateY: ['-100%', '-10%'],
		})
		.add({
			targets: '.app-icon',
			opacity: [0, 1],
			duration: 500
		})
		.add({
			targets: '.color-stripe',
			duration: 250,
			translateX: ['-100%', 0],
			opacity: [0, 1]
		})
		.add({
			targets: '.app-icon',
			translateX: ['-50%', '-50%'],
			translateY: ['10%', '10%'],
			top: ['0', '0'],
			width: '37%',
			scale: [0.5, 0.5]
		})
	}
})

watch(currentScreenIndex, () => {
	if(currentScreenIndex.value === 1) {
		const tl = anime.timeline({
			easing: 'easeInQuad',
		})
		tl.add({
			targets: '.background .bg-image-2',
			opacity: [1, 0.6],
			duration: 750,
			easing: 'easeInQuad',
		}, 750)
	}

	if(currentScreenType.value === 'screen-intro-2') {
		// dupacoTextColor.value = 'white';
		// If it's the screen with ctas, do not go to the next screen
		if(currentScreen.value.ctas !== '1') {
			setScreenTimer(currentScreen.value.duration);
		}
	} else {
		dupacoTextColor.value = 'black';
	}
})

// Function to set a timer for screen transitions
const setScreenTimer = (duration) => {
	clearScreenTimer();
	screenTimer = setTimeout(() => {
		goToNextScreen();
	}, duration * 1000); // Convert duration to milliseconds
};

if(currentScreenType.value == 'screen-intro') {
	setScreenTimer(currentScreen.value.duration);
}

const goToScreen = (value) => {
	if(value === 'brand-screen') {
		showScreen.value = 'brand-screen';
		currentScreenIndex.value = 4;
	}
	if(value === 'logo-evolution') {
		showScreen.value = 'logo-evolution';
		currentScreenIndex.value = 5;
	}
}

watch(showPreviousScreen, () => {
	if(showPreviousScreen.value === true) {
		if(document.querySelector('.dupaco-brand-screen')) {
			document.querySelector('.dupaco-brand-screen').style.opacity = 0;
		}
		if(document.querySelector('.logo-evolution')) {
			document.querySelector('.logo-evolution').style.opacity = 0;
		}
		backwardsAnimation.value = true;
		dontGoToNextScreen.value = true;
		showScreen.value = null;
		if(currentScreenIndex.value === 5) {
			currentScreenIndex.value = currentScreenIndex.value - 2;
		} else {
			currentScreenIndex.value = currentScreenIndex.value - 1;
		}
		previousScreenStore.resetShowPreviousScreen();
	} else {
		backwardsAnimation.value = false;
	}
})


onMounted(() => {
	GameEnter()
})

</script>

<template>
	<div class="background" :class="currentScreenType">
		<img :src="screen0bg" v-if="currentScreen?.type === 'intro' || currentScreen?.type === 'intro-2'" class="bg-image-1" alt="">
		<img :src="screen1bg" v-if="currentScreen?.type === 'intro' || currentScreen?.type === 'intro-2'" class="bg-image-2" alt="">
		<img src="../../assets/img/slideshow-bg.svg" v-else  class="slideshow-bg" alt="">
		<img src="@/assets/img/horizon-bar.svg" :class="['horizon-bar', currentScreenType, horizonState]">
	</div>

	<AppIcon :icon="props.game?.icon" :screen="currentScreenType" />

	<div class="screens" :class="currentScreenType">

		<!-- Intro Screen 1 -->
		<Transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" :class="currentScreenType" v-if="currentScreen?.type === 'intro'">
				<div class="intro-content">
					<img :src="currentScreen.logo" class="intro-logo" alt="">
					<div class="copy" v-html="currentScreen.content"></div>
				</div>
			</div>
		</Transition>

		<!-- Intro Screen 2 -->
		<Transition name="fadeupdelay" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" :class="currentScreenType" v-if="currentScreen?.type === 'intro-2'">
				<BrandIntroScreen2 :currentScreen="currentScreen" :textColor="dupacoTextColor" @goToScreen="goToScreen" />
			</div>
		</Transition>

		<!-- Brand Screen -->
		<Transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" :class="currentScreenType" v-if="showScreen === 'brand-screen'">
				<MissionVisionValuesScreen :currentScreen="currentScreen" />
			</div>
		</Transition>

		<Transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey" class="screen-container" :class="currentScreenType" v-if="showScreen === 'logo-evolution'">
				<LogoEvolution :currentScreen="currentScreen" />
			</div>
		</Transition>
	</div>

	<div class="dupaco-credit-union-logo" v-show="showCreditUnionLogo">
		<img src="@/assets/img/dupaco-credit-union-white.svg" alt="">
	</div>

	<div :class="[currentScreenType, 'dupaco-logo']" v-show="showDupacoLogo">
		<DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor" />
	</div>

	<ColorStripe />
</template>

<style lang="scss" scoped>
.Question-enter-active,
.Question-leave-active {
	transition: opacity 1.5s ease;
}

.Question-enter-from,
.Question-leave-to {
	opacity: 0;
	transform: translate3d(0, 10%, 0);
}

.screens {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	&.screen-story, &.screen-gallery {
		z-index: 0;
	}
}

.background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: .5s ease-in-out;
		background-color: var(--white);
	}
	.bg-image-1 {
		position: absolute;
		z-index: 1;
		transition: 0.4s;
		opacity: 0;
	}
	.bg-image-2 {
		position: absolute;
		z-index: 1;
		transition: 0.4s;
		opacity: 0;
	}

	
	&.screen-intro-2 {
		&::before {
			background: var(--black);
		}
	}

	&.screen-intro, &.screen-intro-2 {
		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	&.screen-gallery {
		height: auto;
		overflow: visible;
		z-index: 10;
		.horizon-bar {
			opacity: 0.3;
		}
	}

	&.screen-story {
		height: auto;
		overflow: visible;
		z-index: 10;
		.horizon-bar {
			opacity: 0.3;
		}
	}

	.horizon-bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		opacity: .1;
		transform: translate3d(0, -100%, 0);
		&.show {
			transform: translate3d(0, -10%, 0)
		}
		&.screen-logo-evolution, &.screen-dupaco-brand {
			opacity: .05;
		}
	}
}

.app-icon {
	z-index: 20;
	position: fixed;
	top: 50%;
	left: 50%;
	transform-origin: center top;
	transform: translate3d(-50%, -50%, 0);
	width: 30%;
	opacity: 0;
	&.screen-member-story-detail {
		display: none;
	}
	&.screen-intro-2 {
		pointer-events: none;
		visibility: hidden;
	}
}

.dupaco-logo {
	position: fixed;
	bottom: 5%;
	left: 50%;
	transform: translateX(-50%);
	width: 30%;
	transition: .5s ease-in-out;
	&.screen-intro-2 {
		opacity: 0;
		left: 50%;
		bottom: 5%;
		transform: translate(-50%, 0%);
		width: 30%;
	}
}

.screen-container {
	position: fixed;
	width: 100%;
	left: 0;
	top: 25%;
	bottom: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
	&.screen-gallery {
		top: 0;
		bottom: 0;
	}
}

.question-content {
	max-width: 90%;
	margin: 0 auto;
}

.intro-content {
	position: fixed;
	top: -5%;
	left: 50%;
	transform: translate(-50%);
	bottom: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 80%;
	margin: 0 auto;
	font-family: var(--robotoCondensed);
	color: var(--black);
	font-size: var(--3xl);
	line-height: 1.4em;
	img {
		width: 35vw;
		opacity: 0;
		transform: translateY(10%);
	}
	.copy {
		opacity: 0;
		transform: translateY(10%);
	}
}

.intro-content-2 {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translate(-50%);
	bottom: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 80%;
	margin: 0 auto;
	font-family: var(--robotoCondensed);
	font-size: var(--3xl);
	line-height: 1.4em;
	img {
		width: 35vw;
		opacity: 0;
		transform: translateY(10%);
	}
	.copy {
		opacity: 0;
		transform: translateY(10%);
	}
}

.color-stripe {
	z-index: 20;
}

.dupaco-credit-union-logo {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 45vw;
	margin: 0 auto 5% auto;
}

</style>
