<script setup>
import { defineProps } from "vue";
import Squircle from "@/components/Squircle"

const props = defineProps({
    icon: { type: String, default: null },
    label: { type: String, default: null },
    screen: { type: String, default: null },
    game: {type: String, default: null}
});
</script>

<template>
    <div class="app-icon" :class="[props.game, props.screen]">
        <div class="logo">
            <Squircle />
            <img :src="props.icon" />
        </div>
        <div class="app-label" v-html="props.label"></div>
    </div>
</template>

<style lang="scss">

.app-icon {
    &.speed-game {
        .app-label {
            p {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                margin-top: 0.5em;
                strong {
                    flex-basis: 100%;
                    display: block;
                    order: 2;
                    font-size: var(--2xl);
                }
                i {
                    display: inline;
                    order: 1;
                    margin-right: 10px;
                    font-size: var(--md);
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.app-label strong {
    position: relative;
    left: .1em;
}
</style>

<style lang="scss" scoped>
.app-icon {
    .logo {
        max-width: 256px;
        margin: 0 auto;
        position: relative;

        .squircle {
            filter: drop-shadow(0 10px 5px rgba(0,0,0,.15));	
            aspect-ratio: 1/1;
            width: 100%;			
        }
        img {
            width: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        
    }
    .app-label {
        font-family: var(--FormulaSemiCondensedLight);
        color: var(--white);
        font-size: var(--2xl); 
        text-transform: uppercase;

    }

    &.screen-question {
        .app-label { color: var(--black); }
    }


    &.screen-home_cta {
        .logo { max-width: 175px; }
        .app-label { font-size: var(--lg);}
    }

    
}
</style>

