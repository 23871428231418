<script setup>
import { ref, computed, watch, defineProps, onMounted, defineEmits, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { locationStore } from '@/stores'
import DupacoLogo from "@/components/DupacoLogo"
import ColorStripe from "@/components/ColorStripe"
import AppIcon from '@/components/AppIcon'
import anime from "animejs"
import lottie from 'lottie-web'
import burglerAnimationData from '../../assets/lottie/burgler.json';

const store = locationStore()
const router = useRouter()
const props = defineProps({
	game: { type: Object, default: null },
	isLast: { type: Boolean, default: false }
})

const dupacoTextColor = ref('white')
const dupacoShineColor = ref('mango')
const currentScreenIndex = ref(0)
const seconds = ref(10);
const formattedSeconds = ref('00:00')
const initialTime = ref(10);
const currentScreenKey = computed(() => `screen-${currentScreenIndex.value}`);

const currentScreen = computed(() => {
	// Compute the current screen based on the current index
	if (props.game && props.game.home_screen && props.game.home_screen.length > currentScreenIndex.value) {
		return props.game.home_screen[currentScreenIndex.value];
	}
	return null;  // Return null if the index is out of bounds
});

const currentScreenType = computed(() => {
	// Compute a CSS class for the current screen type
	return currentScreen.value ? `screen-${currentScreen.value.type}` : 'default-screen-type';
});

let totalMilliseconds = ref(seconds.value * 1000);  // Convert seconds to milliseconds

const progress = computed(() => {
	const initialMilliseconds = initialTime.value * 1000;
	return (totalMilliseconds.value / initialMilliseconds) * 100;
});

const startSpeedTimer = () => {
	console.log("startSpeedTimer")
	let timer = setInterval(() => {
		if (totalMilliseconds.value <= 0) {
			clearInterval(timer)
		} else {
			// Decrement by 10 milliseconds
			totalMilliseconds.value -= 10;
		}

		let remainingSeconds = Math.floor(totalMilliseconds.value / 1000);
		let remainingMilliseconds = Math.floor((totalMilliseconds.value % 1000) / 10);

		// Format the output
		formattedSeconds.value = `${remainingSeconds.toString()}:${remainingMilliseconds.toString().padStart(2, '0')}s`;
	}, 10);
}

// Watch for changes in the current screen index
watch(currentScreenIndex, () => {
	// Set timer and adjust UI for intro/outro screens
	if (currentScreenIndex.value === 2) {
		spotlightAnimation()
		setScreenTimer(currentScreen.value.duration + 3);
	} else if (currentScreenIndex.value === 3) {
		startSpeedTimer()
		const burgler = document.querySelector('#burgler')
		burgler.style.transition = '1s ease-in'
		document.querySelector("#burgler").style.transform = 'translateX(-50%) translateY(100%)'
		spotlightTransition()
		setScreenTimer(currentScreen.value.duration);

	} else {
		if (currentScreen.value && currentScreen.value.type !== 'home_cta') {
			setScreenTimer(currentScreen.value.duration);
		} else {
			document.querySelector("#burgler").style.transition = '1s ease-out'
			document.querySelector("#burgler").style.transform = 'translateX(-50%) translateY(0)'
			spotlightLeave();
			setScreenTimer(10);
		}
	}

});

// Timer for screen transitions
let screenTimer = null;

// Function to set a timer for screen transitions
const setScreenTimer = (duration) => {
	clearScreenTimer();
	screenTimer = setTimeout(() => {
		goToNextScreen();
	}, duration * 1000); // Convert duration to milliseconds
};

// Function to clear the screen transition timer
const clearScreenTimer = () => {
	if (screenTimer) {
		clearTimeout(screenTimer);
		screenTimer = null;
	}
};

const goToNextScreen = () => {
	if (currentScreenIndex.value < (props.game.home_screen.length - 1)) {
		currentScreenIndex.value++;
	} else {
		clearScreenTimer(); // Clear timer at the end of the screens
		// Animate Sceen Out
		if (!props.isLast) {
			document.querySelector("#burgler").style.transition = 'none'
			setTimeout(() => {
				const tl = anime.timeline({
					easing: 'easeInQuad',
					complete: () => {
						updateNextScreen();
					}
				})
				tl.add({
					targets: '.color-stripe',
					duration: 250,
					translateX: [0, '100%'],
					opacity: [1, 0]
				})
					.add({
						targets: '.dupaco-logo',
						translateX: ['-50%', '-50%'],
						translateY: [0, '-50%'],
						opacity: [1, 0],
						duration: 500,
					}, 100)
					.add({
						targets: '#burgler',
						translateX: ['-50%', '-50%'],
						translateY: [0, '50%'],
						opacity: [1, 0],
						duration: 500,
					}, 100)
					.add({
						targets: '.screen-container',
						duration: 500,
						translateY: [0, '-25%'],
						opacity: [1, 0]
					}, 250)
					.add({
						targets: '.background',
						translateY: [0, 0],
						scale: [1, .9],
						borderRadius: [0, '50px'],
						duration: 500,
						easing: 'easeInOutQuad'
					}, 600)
					.add({
						targets: '.background',
						translateY: [0, '-20%'],
						opacity: [1, 0],
						duration: 500,
					}, 1500)

			}, 1000);
		} else {
			updateNextScreen();
		}
	}
};

const initBurgler = () => {
	const burglerAnimation = lottie.loadAnimation({
		container: document.getElementById('burgler'),
		renderer: 'svg',
		loop: false,
		autoplay: true,
		animationData: burglerAnimationData // Use the imported data
	});

	burglerAnimation.addEventListener('complete', () => {
		setTimeout(() => {
			burglerAnimation.goToAndPlay(6000, false);
		}, 0); //delay before starting the animation again
	});
}

const spotlightAnimation = () => {
	const tl = anime.timeline({
		duration: 1000,
		easing: 'easeInOutQuad'
	})

	tl.add({
		targets: '#spotlight',
		opacity: [0, 1],
		translateX: ['-50%', '-75%'],
		translateY: ['0', '-50%'],
	})
		.add({
			targets: '#spotlight',
			translateX: '60%',
			translateY: '25%',
		})
		.add({
			targets: '#spotlight',
			translateX: '-80%',
			translateY: '80%',
		})
		.add({
			targets: '#spotlight',
			translateX: '-50%',
			translateY: '0',
		})

}

const spotlightTransition = () => {
	anime({
		targets: '#spotlight',
		borderWidth: [0, '24px'],
		scale: .7,
		duration: 500,
		translateY: '33.5%',
		easing: 'easeOutQuad',
	})
	anime({
		targets: '#spotlight .bg',
		opacity: [1, 0],
		duration: 250,
		easing: 'easeInQuad'
	})
	anime({
		targets: '#spotlight .timer',
		opacity: [0, 1],
		translateY: ['50%', 0],
		duration: 250,
		easing: 'easeOutQuad'
	})
	anime({
		targets: '#spotlight .time',
		opacity: [0, 1],
		duration: 250,
		easing: 'easeOutQuad'
	})
}

const spotlightLeave = () => {
	anime({
		targets: '#spotlight',
		opacity: 0,
		translateY: '25%',
		duration: 500,
		easing: 'easeInQuad'
	})
}

const onEnter = () => {
	document.querySelector('.background').style.borderRadius = '50px'
	document.querySelector('.background').style.opacity = '0'
	document.querySelector('.background').style.transform = 'scale(.9) translateY(0)'
	document.querySelector('.start-content strong').style.opacity = '0'
	const tl = anime.timeline({
		easing: 'easeOutQuad',
		complete: () => {
			setScreenTimer(currentScreen.value.duration)
			initBurgler()
		}
	})
	tl.add({
		targets: '.background',
		translateY: ['20%', 0],
		opacity: [0, 1],
		duration: 500,
	})
		.add({
			targets: '.background',
			translateY: [0, 0],
			scale: [.9, 1],
			borderRadius: ['50px', 0],
			duration: 500,
			easing: 'easeInOutQuad'
		}, 1000)
		.add({
			targets: '.dupaco-logo',
			translateX: ['-50%', '-50%'],
			translateY: ['100%', 0],
			opacity: [0, 1],
			duration: 500,
		}, 1100)
		.add({
			targets: '.start-content i:first-child',
			duration: 750,
			opacity: [0, 1],
			translateY: ['85%', 0],
		}, 1150)
		.add({
			targets: '.start-content i:last-child',
			duration: 750,
			opacity: [0, 1],
			translateY: ['-85%', 0],
			complete: () => {
				anime({
					targets: '.start-content strong',
					duration: 750,
					easing: 'easeOutQuad',
					opacity: [0, 1],
				})
			}
		}, 1150)
		.add({
			targets: '.launch-quiz > div',
			duration: 500,
			translateY: ['80px', 0],
			opacity: [0, 1]
		}, 1150)
		.add({
			targets: '.color-stripe',
			duration: 500,
			translateX: ['-100%', 0],
			opacity: [0, 1]
		}, 1200)
}

onMounted(() => {
	onEnter()
})

const emit = defineEmits([
	'updateNextScreen'
])

const updateNextScreen = () => { emit('updateNextScreen', true) }

const goToGame = (slug) => {
	document.querySelector("#burgler").style.transition = 'none'
	const tl = anime.timeline({
		easing: 'easeInQuad',
		complete: () => {
			router.push({ path: `/${store.data.slug}/game/${slug}` })
		}
	})
	tl.add({
		targets: '.launch-quiz > div',
		scale: [1, .75],
		opacity: [1, 0],
		duration: 350
	})
		.add({
			targets: '.color-stripe',
			duration: 250,
			translateX: [0, '100%'],
			opacity: [1, 0]
		})
		.add({
			targets: '#burgler',
			duration: 250,
			translateY: [0, '20%'],
			translateX: ['-50%', '-50%'],
			opacity: [1, 0]
		}, 0)
		.add({
			targets: '.dupaco-logo',
			translateX: ['-50%', '-50%'],
			translateY: [0, '-50%'],
			opacity: [1, 0],
			duration: 500,
		}, 100)
		.add({
			targets: '.screen-container',
			duration: 500,
			translateY: [0, '-25%'],
			opacity: [1, 0]
		}, 450)
		.add({
			targets: '.background video',
			opacity: [.5, 0],
			duration: 250,
			easing: 'easeInQuad'
		})
		.add({
			targets: '.background',
			translateY: [0, 0],
			scale: [1, .9],
			borderRadius: [0, '50px'],
			duration: 500,
			easing: 'easeInOutQuad'
		}, 800)

}

onUnmounted(() => {
	clearScreenTimer();
})

</script>

<template>
	<div class="background">
		<video autoplay muted loop playsinline>
			<source src="../../assets/videos/finance-pattern.mp4" type="video/mp4">
		</video>
	</div>
	<div class="dupaco-logo" :class="currentScreenType">
		<DupacoLogo :textColor="dupacoTextColor" :shineColor="dupacoShineColor" />
	</div>
	<div class="screens">
		<Transition name="fadeup" mode="out-in">
			<div :key="currentScreenKey">
				<div class="screen-container" v-if="currentScreen?.type === 'intro'">
					<div class="intro-content" v-html="currentScreen?.content"></div>
				</div>
				<div class="screen-container" v-else-if="currentScreen?.type === 'start'">
					<div class="start-content" v-html="props.game?.title"></div>
				</div>

				<div class="screen-container" v-else>
					<div class="cta-content">
						<div class="box">
							<AppIcon :icon="props.game?.icon" :label="props.game?.title" :screen="currentScreenType"
								:game="'speed-game'" />
							<button @touchstart="goToGame(game.slug)" class="launch-quiz">LAUNCH QUIZ ▶</button>
						</div>
						<div v-if="currentScreen?.type === 'home_cta'" class="copy" v-html="currentScreen?.content"></div>
					</div>
				</div>
			</div>
		</Transition>

		<Transition name="fadeup" mode="out-in">
			<div class="launch-quiz always" v-if="currentScreen?.type !== 'home_cta'">
				<div>
					<button @touchstart="goToGame(game.slug)">LAUNCH QUIZ ▶</button>
					<p>Touch to get started</p>
				</div>
			</div>
		</Transition>

		<ColorStripe />
	</div>

	<div id="burgler"></div>
	<div id="spotlight">
		<div class="bg"></div>
		<div class="timer">
			<div class="circle">
				<svg viewBox="0 0 36 36" class="circular-chart">
					<path class="circle-bg" d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831" />
					<path class="circle-progress" :style="{ strokeDasharray: progress + ', 100' }" d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831" />
				</svg>
			</div>
			<div class="time" v-html="formattedSeconds"></div>
		</div>
	</div>
</template>

<style lang="scss">
.circle-progress {
	fill: none;
	stroke: var(--mango); // use your desired color
	stroke-width: 2.8;
	stroke-linecap: round;
	transform-origin: 50% 50%;
	transform: scaleX(-1);
	transition-timing-function: linear;
}

.start-content {
	color: var(--white);
	font-size: var(--8xl);

	p {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-transform: uppercase;
		line-height: 1.25;

		strong {
			position: relative;
			top: 0.05em;
			font-family: var(--FormulaExtendedBold);
			line-height: 0.9;
		}

		i {
			font-size: .7em;
			font-family: var(--FormulaSemiCondensedLightItalic);
		}
	}
}
</style>

<style lang="scss" scoped>
#spotlight {
	position: fixed;
	width: 50vw;
	aspect-ratio: 1/1;
	border-radius: 50%;
	bottom: 10%;
	left: 50%;
	opacity: 0;
	transform: translate3d(-50%, 0, 0);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	pointer-events: none;
	border: 0;

	.bg {
		position: absolute;
		inset: 0;
		background-color: var(--yellow);
		opacity: .2;
		transition: .25s ease-in;
	}

	.timer {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		opacity: 0;
		transform: translate3d(0, -25%, 0);
		transition: .5s ease-out;

		&::before {
			content: "";
			display: block;
			position: absolute;
			inset: 5%;
			border-radius: 50%;
			border: 3px solid var(--gray250);
			opacity: 0.2;
		}

		svg {
			position: absolute;
			inset: 0;
			z-index: 1;
		}

		.time {
			display: block;
			position: relative;
			top: 0.3em;
			font-family: var(--FormulaCondensedExtraLight);
			font-size: var(--6xl);
			line-height: 0.9;
			color: var(--white);
			opacity: 0;
			transform: translate3d(0, -25%, 0);
		}
	}

	.circular-chart {
		width: 100%;
		height: 100%;
	}

	.circle-bg {
		fill: none;
	}

	.circle-progress {
		fill: none;
		stroke: var(--mango); // use your desired color
		stroke-width: 2.8;
		stroke-linecap: round;
		transform-origin: 50% 50%;
		transform: scaleX(-1);
		transition-timing-function: linear;
	}
}

.launch-quiz {
	position: relative;
	z-index: 1000;

	button {
		background-color: var(--mango);
		font-size: var(--md);
		color: var(--white);
		font-family: var(--FormulaExtendedBold);
		padding: .75em 2em .5em;
		border: none;
		border-radius: .35em;
		transform-origin: 50% 100%;
		animation: pulse 4s infinite reverse cubic-bezier(.55, 0, .45, 1);

		@keyframes pulse {
			0% {
				transform: scale(1);
			}

			50% {
				transform: scale(1.3);
			}

			100% {
				transform: scale(1);
			}
		}
	}

	p {
		position: absolute;
		bottom: -1em;
		left: 50%;
		transform: translate(-50%, 100%);
		font-family: var(--FormulaExtendedBold);
		font-size: var(--sm);
		color: var(--white);
		text-transform: uppercase;
		margin: 0;
	}

	&.always {
		position: fixed;
		bottom: 40%;
		left: 50%;
		transform: translateX(-50%);
	}
}

button.launch-quiz {
	background-color: var(--mango);
	font-size: var(--md);
	color: var(--white);
	font-family: var(--FormulaExtendedBold);
	padding: .75em 2em .5em;
	border: none;
	border-radius: .35em;
}

.cta-content {
	color: var(--white);
	font-size: var(--2xl);
	line-height: 1.1;

	.box {
		padding: 12vw 6vw 6vw;
		width: 60%;
		margin: 0 auto;
		background-image: linear-gradient(180deg, rgba(255, 255, 255, .05) 0%, rgba(255, 255, 255, .1) 100%);
		backdrop-filter: blur(3px);
		-webkit-backdrop-filter: blur(3px);
		border-radius: 20px;


		button {
			width: 100%;
			transform-origin: 50% 50%;
			animation: pulse-small 4s infinite reverse cubic-bezier(.55, 0, .45, 1);

			@keyframes pulse-small {
				0% {
					transform: scale(1);
				}

				50% {
					transform: scale(0.8);
				}

				100% {
					transform: scale(1);
				}
			}
		}
	}

	.copy {
		font-family: var(--FormulaMedium);
		font-size: var(--lg);
		line-height: 1.3;
	}
}

.background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: .5s ease-in-out;
		background-color: var(--warmpurple);
	}
}

video {
	position: absolute;
	inset: 0;
	height: 100%;
	width: 100%;
	opacity: 0.5;
	background-size: cover;
	mix-blend-mode: multiply;
}

.dupaco-logo {
	position: absolute;
	left: 50%;
	top: 5%;
	transform: translateX(-50%);
	z-index: 1;
	width: 25%;
}

.screens {
	position: fixed;
	top: 5%;
	bottom: 20%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	>div {
		width: 100%;
	}
}

.screen-container {
	width: 100%;
	max-width: 90%;
	margin: 0 auto;
}

#burgler {
	position: fixed;
	bottom: -10px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	pointer-events: none;
}</style>